import { configureStore } from '@reduxjs/toolkit';
import promptReducer from './prompt/reducer';
import uiReducer from './ui/reducer';
import _createReducer from './create/reducer';
import stylesReducer from './styles/reducer';
import userReducer from './user/reducer';
import trainingReducer from './training/reducer';
import cartReducer from './cart/reducer';
import imageSelectionReducer from './imageSelection/reducer';
//
export const store = configureStore({
  reducer: {
    prompts: promptReducer,
    ui: uiReducer,
    create: _createReducer,
    styles: stylesReducer,
    user: userReducer,
    training: trainingReducer,
    cart: cartReducer,
    imageSelection: imageSelectionReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
