import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import icons from './icons.json';
import createElement from './utils/createElement';
import { iconSize } from './utils/styledSystemProps';
import Svg from '../Svg/Svg';
import defaultProps from './defaultProps.json';
import { IconSize } from './utils/types';

const Icon = styled(Svg)<{ iconSize: IconSize; name: keyof typeof icons }>`
  ${iconSize};
`;

const Wrapper = <T extends ComponentProps<typeof Svg>>({
  name,
  iconSize,
  ...props
}: { name: keyof typeof icons; iconSize: IconSize } & T) => {
  const icon: any = icons[name];
  const { children, viewBox } = icon;

  return (
    <Icon
      viewBox={viewBox}
      iconSize={iconSize}
      {...props}
      {...(defaultProps.hasOwnProperty(name) && (defaultProps as any)[name])}
    >
      {icon && createElement(children)}
    </Icon>
  );
};

Wrapper.defaultProps = {
  iconSize: 'large'
};

Icon.displayName = 'Icon';

export default Wrapper;
