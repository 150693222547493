import { CustomModel, ModelSettings } from '@store/create/types';

export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const STABLE_DIFFUSION_IMG =
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/39e96a77-6d2a-4874-bc5f-e9ce397dd100/public';
export const DALL_E_IMG =
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/349ef7ad-8804-45f0-bf0d-89e8b9861e00/public';
export const MIDJOURNEY_IMG =
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/259de1d7-7e95-4c26-fe9a-d288e4280a00/public';
export const PH_CHROME_IMG =
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ff12d8a4-80d0-4744-4eaf-1e975ea07d00/public';
export const DISCORD_SERVER_URL = 'https://discord.gg/f99kUw9R59';
export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_KEY;
export const NOTIFICATION_EVENT = 'notification';
export const BASE_URL = process.env.HOST;
export const CUSTOM_SD_MODELS = [
  'None',
  // 'Luna Diffusion',
  'PhChroma3',
  'PhChroma2',
  'PhChroma',
  'Foto Assisted',
  'Nitro Diffusion',
  'Redshift Diffusion',
  'Mo Di Diffusion',
  'Arcane Diffusion v3',
  'Vivid Watercolors',
  'Analog Diffusion',
  'Vectra v0.4',
  'Vectra v0.3',
  'Vectra v0.2',
  'Vectra v0.1',
  'Megamodel',
  'Polydra v0.1',
  'Redshift 768',
  'Vectra v0.4',
  'Pixel Art'
];

export const CUSTOM_SD_MODEL_SETTINGS: { [key in CustomModel]: ModelSettings } = {
  // 'Luna Diffusion': {
  //   checkpoint: 'luna_diffusion_2-2.ckpt',
  //   stableDiffusionVersion: '1.5'
  // },
  'Vectra v0.4': {
    checkpoint: 'Vectra0_4-SD2.ckpt',
    stableDiffusionVersion: '2.1',
    sizeOptions: {
      output: 'large'
    }
  },
  PhChroma3: {
    checkpoint: 'PHchroma3.ckpt',
    stableDiffusionVersion: '1.5'
  },
  PhChroma2: {
    checkpoint: 'PHchroma2.ckpt',
    stableDiffusionVersion: '1.5'
  },
  PhChroma: {
    checkpoint: 'mega2.ckpt',
    stableDiffusionVersion: '1.5'
  },
  Megamodel: {
    checkpoint: 'Megamodel.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Vectra v0.3': {
    checkpoint: 'Vectra-0_3.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Foto Assisted': {
    checkpoint: 'MFAD-foto-assisted-diffusion.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Nitro Diffusion': {
    checkpoint: 'nitroDiffusion.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Redshift Diffusion': {
    checkpoint: 'redshiftDiffusion.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Mo Di Diffusion': {
    checkpoint: 'moDiDiffusion.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Arcane Diffusion v3': {
    checkpoint: 'arcaneDiffusionV3.ckpt',
    stableDiffusionVersion: '1.5',
    sampler: 'DPM++ 2M Karras'
  },
  'Vivid Watercolors': {
    checkpoint: 'vividWatercolors.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Analog Diffusion': {
    checkpoint: 'analogDiffusion.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Vectra v0.1': {
    checkpoint: 'Vectra-0_1.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Vectra v0.2': {
    checkpoint: 'Vectra-0_2.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Polydra v0.1': {
    checkpoint: 'tribemodel.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Pixel Art': {
    checkpoint: 'pixelArt_v1.ckpt',
    stableDiffusionVersion: '1.5'
  },
  'Redshift 768': {
    checkpoint: 'redshift-768.ckpt',
    stableDiffusionVersion: '2.1',
    sizeOptions: {
      output: 'large'
    }
  },
  None: {}
};

export const DEFAULT_NEGATIVE_STYLES = [
  'ugly',
  'tiling',
  'poorly drawn hands',
  'poorly drawn feet',
  'poorly drawn face',
  'out of frame',
  'mutation',
  'mutated',
  'extra limbs',
  'extra legs',
  'extra arms',
  'disfigured',
  'deformed',
  'cross-eye',
  'body out of frame',
  'blurry',
  'bad art',
  'bad anatomy',
  'blurred',
  'watermark',
  'grainy',
  'duplicate'
];
export const ESSENTIAL_PRICE_ID = process.env.ESSENTIAL_PRICE_ID || '';
export const PRO_PRICE_ID = process.env.PRO_PRICE_ID || '';
export const ESSENTIAL_2_PRICE_ID = process.env.ESSENTIAL_2_PRICE_ID || '';
export const PRO_2_PRICE_ID = process.env.PRO_2_PRICE_ID || '';
export const PRO_PLUS_PRICE_ID = process.env.PRO_PLUS_PRICE_ID || '';

export const CUSTOMER_PORTAL_URL = 'https://billing.stripe.com/p/login/dR6g1E7WPa8tgy4fYY';
export const THEME_CATEGORIES = [
  'Graphic Design',
  '3D Art',
  'Illustration',
  'Fine Arts',
  'Photography',
  'Photography - Indoor',
  'Photography - Outdoor',
  'Photography - Black & White',
  'Photography - Experiment',
  'Photography - Long Negative',
  'Interior Design',
  'Icon Design',
  'Street Art',
  'UI / UX',
  'Typography',
  'Logo',
  'Industrial Design',
  'Fashion',
  'Modeling',
  'Film',
  '3d',
  'Fashion Design',
  'Infographic',
  'Poster',
  'Automotive Design',
  'Textile Design',
  'Sketching',
  'Character Design',
  'Toy Design',
  'Other',
  'Pixel Art',
  'Texture',
  'Mockup',
  'Game Assets',
  'Patterns'
];

export const LoRas = ['appicon', 'foodphoto', 'cognacinterior'];

export const CONTROLNET_HELPER_TEXTS = {
  none: '',
  canny:
    'Canny mode: Best for images with well-defined edges, such as architectural photos, high-contrast images, or geometric patterns.',
  depth:
    'Depth mode: Ideal for landscapes, cityscapes, or images with varying levels of depth and distance, where depth information can be emphasized.',
  hed: 'HED mode takes the soft edges from your uploaded image and displays them as white lines on a black background.',
  normal:
    'Normal mode: Works well for textured surfaces, patterns, or images where enhanced depth and realism are desired through surface angles and lighting information.',
  mlsd: 'MLSD mode: Best suited for geometric patterns, buildings, or images with many straight lines and intersections, as it highlights straight lines in the image.',
  scribble:
    'Scribble mode: Perfect for illustrations, cartoons, or images with clearly defined outlines and shapes, as it displays hand-drawn white outlines on a black background.',
  seg: 'Seg mode: Ideal for cluttered scenes, images with multiple objects, or photos with distinct areas and elements, as it categorizes objects and areas with different colors.',
  openpose:
    'Openpose mode: Great for group photos, sports events, or images containing people in various poses and movements, as it highlights human body keypoints and connections.',
  lineart: 'Lineart mode',
  ip2p: 'Instruction pixel 2 pixel: Best for telling the model what to do to your original image: ex. Make it snowy',
  tile: 'Tile mode'
};

/*  SENDGRID TEMPLATES - Replaced by Postmark below
export const TEMPLATE_ID_NEW_COMMENT_CREATION = 'd-4d560fff605e4976ab161776146d0a9a';
export const TEMPLATE_ID_NEW_FOLLOWER = 'd-8f124060342740d7917e0eb4c3408d55';
export const TEMPLATE_ID_COMMENT_MENTION = 'd-9f39f31c4f284cde87f49574921393f0';
export const TEMPLATE_ID_PROMPT_LIKE = 'd-e8ac5d2988f941a59df364055488a2a8';
export const TEMPLATE_ID_PACK_READY = 'd-04778866c5cb4586874c3b08131d6747';
*/

// POSTMARK
export const TEMPLATE_ID_NEW_COMMENT_CREATION = 34448786;
export const TEMPLATE_ID_NEW_FOLLOWER = 34448787;
export const TEMPLATE_ID_COMMENT_MENTION = 34448784;
export const TEMPLATE_ID_PROMPT_LIKE = 34448785;
export const TEMPLATE_ID_PACK_READY = 34448820;
export const TEMPLATE_ID_PURCHASE_CONFIRMATION = 37243973;
// Data Expiration: 7 Day Alert
export const TEMPLATE_ID_DATA_EXPIRATION_7_DAY = 37267075;
// Data Expiration: 24 Hour Alert
export const TEMPLATE_ID_DATA_EXPIRATION_24_HOUR_ALERT = 37267213;
export const TEMPLATE_ID_RECEIVED_CREDITS = 37257252;
export const TEMPLATE_ID_PICSTUDIO_DRIVE_SUBSCRIBE_SUCCESS = 37271310;
// PicStudio Drive Renewal Confirmation
export const TEMPLATE_ID_PICSTUDIO_DRIVE_RENEWAL_CONFIRMATION = 37534967;
// Signup Discount
export const TEMPLATE_ID_SIGNUP_DISCOUNT = 37931443;
export const TRAINING_URL = 'https://www.picstudio.ai/train';

export const POSTMARK_LAYOUT_MODEL = {
  product_name: 'PicStudio.AI',
  company_name: 'PicStudio.AI',
  company_address: '',
  action_url: 'https://www.picstudio.ai', // By default we use the home page
  support_url: 'https://www.picstudio.ai/support',
  support_email: 'supportteam@picstudio.ai',
  help_url: 'https://www.picstudio.ai/faq',
  action_text: '',
  customer_name: '',
  customer_email: '',
  order_id: '',
  purchase_date: '',
  product_name_purchased: '', // 'Starter Kit' or 'Pro Bundle'
  num_images_purchased: '',
  num_packs_purchased: '',
  // download_url: '', we don't have this, but we may add it later
  order_total: '',
  do_over_offer: '$14.99',
  discount_offer: '',
  gift_recipient_name: '',
  gift_sender_name: '',
  photo_pack_name: '',
  photo_pack_photo_count: '',
  referral_commission: '',
  referral_signup_url: '',
  public_packs_url: 'https://www.picstudio.ai/packs/browse',
  picstudio_drive_url: 'https://www.picstudio.ai/drive',
  expiration_alert_url: 'https://www.picstudio.ai/expiration-alert'
};

export const STARTER_PRICE_ID = process.env.NEXT_PUBLIC_STARTER_PRICE_ID;
export const PRO_BUNDLE_PRICE_ID = process.env.NEXT_PUBLIC_PRO_BUNDLE_PRICE_ID;
export const PRO_PLUS_BUNDLE_PRICE_ID = process.env.NEXT_PUBLIC_PRO_PLUS_BUNDLE_PRICE_ID;
export const SINGLE_PACK_PRICE_ID = process.env.NEXT_PUBLIC_SINGLE_PACK_PRICE_ID;
export const DO_OVER_PRICE_ID = process.env.NEXT_PUBLIC_DO_OVER_PRICE_ID;
export const THREE_PHOTO_PACK_BUNDLE_PRICE_ID = process.env.THREE_PHOTO_PACK_BUNDLE_PRICE_ID;
export const FIVE_PHOTO_PACK_BUNDLE_PRICE_ID = process.env.FIVE_PHOTO_PACK_BUNDLE_PRICE_ID;
export const TEN_PHOTO_PACK_BUNDLE_PRICE_ID = process.env.TEN_PHOTO_PACK_BUNDLE_PRICE_ID;
export const OLD_STARTER_PRICE_ID = process.env.OLD_STARTER_PRICE_ID;
export const OLD_PRO_BUNDLE_PRICE_ID = process.env.OLD_PRO_BUNDLE_PRICE_ID;
export const OLD_SINGLE_PACK_PRICE_ID = process.env.OLD_SINGLE_PACK_PRICE_ID;

export const FRIENDS_PRICE_ID = process.env.FRIENDS_PRICE_ID;
export const PICSTUDIO_DRIVE_PRICE_ID = process.env.PICSTUDIO_DRIVE_PRICE_ID;

export const PRODUCTS = ['Starter pack', 'Pro bundle', 'Single pack'];

export const PRICE_ID_REVENUE: { [key: string]: number } = {
  price_1NcWgFKEpxRfh3uqPsRqrRzV: 19,
  price_1NcWgLKEpxRfh3uqP9pMbvPn: 29,
  price_1NcWgQKEpxRfh3uq9akDabRb: 4.68,
  price_1Nxw1FKEpxRfh3uqC29Z1Qni: 19.99,
  price_1Nxw7RKEpxRfh3uqDDZsfRom: 29.99,
  price_1Nxw88KEpxRfh3uqoTIY3eVi: 4.99,

  // new prices
  price_1PqzkrKEpxRfh3uqiC4Ug3ZN: 29.99, // starter
  price_1PqzvEKEpxRfh3uq5X3rAjvZ: 39.99, // pro bundle
  price_1Pr004KEpxRfh3uqmA0mREng: 9.99, // single pack
  [STARTER_PRICE_ID]: 29.99, // starter
  [PRO_BUNDLE_PRICE_ID]: 39.99, // pro bundle
  [SINGLE_PACK_PRICE_ID]: 9.99, // single pack
  [DO_OVER_PRICE_ID]: 14.99, // do over
  [PRO_PLUS_BUNDLE_PRICE_ID]: 59.99 // pro plus bundle
};

export const PRODUCTS_NAMES: { [key: string]: string } = {
  [STARTER_PRICE_ID]: 'Starter pack',
  [PRO_BUNDLE_PRICE_ID]: 'Pro bundle',
  [SINGLE_PACK_PRICE_ID]: 'Single pack',
  [DO_OVER_PRICE_ID]: 'Do over'
};

export const PRODUCTS_CREDITS = {
  [STARTER_PRICE_ID]: {
    models: 1,
    photoPacks: 1
  },
  [PRO_BUNDLE_PRICE_ID]: {
    models: 1,
    photoPacks: 3
  }
};

export const WEBHOOKS_BASE_URL = process.env.WEBHOOKS_BASE_URL;

export const SUBSCRIPTION_PLAN = {
  free: 'free',
  picstudio_drive: 'picstudio_drive'
};

export const MODEL_EXPIRATION_DAYS = 14;
export const MODEL_EXPIRATION_ALERT_DAYS = 7;

export const MODEL_VERSIONS = {
  '1.5': '1.5',
  'flux.1-dev': 'flux.1-dev'
};

export const GLASSES_VALUES = {
  MODEL_GLASSES: 'MODEL_GLASSES',
  NONE: 'None',
  RANDOM: 'Random'
} as const;
