export const FLUX_LORA = {
  '5': {
    inputs: {
      width: ['75', 0],
      height: ['77', 0],
      batch_size: 1
    },
    class_type: 'EmptyLatentImage',
    _meta: {
      title: 'Empty Latent Image'
    }
  },
  '6': {
    inputs: {
      text: 'a photo of TOK man in blue shirt',
      clip: ['11', 0]
    },
    class_type: 'CLIPTextEncodePositivePicstudio',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '8': {
    inputs: {
      samples: ['13', 0],
      vae: ['10', 0]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '9': {
    inputs: {
      filename_prefix: 'MarkuryFLUX',
      images: ['8', 0]
    },
    class_type: 'SaveImage',
    _meta: {
      title: 'Save Image'
    }
  },
  '10': {
    inputs: {
      vae_name: 'ae.safetensors'
    },
    class_type: 'VAELoader',
    _meta: {
      title: 'Load VAE'
    }
  },
  '11': {
    inputs: {
      clip_name1: 't5xxl_fp16.safetensors',
      clip_name2: 'clip_l.safetensors',
      type: 'flux'
    },
    class_type: 'DualCLIPLoader',
    _meta: {
      title: 'DualCLIPLoader'
    }
  },
  '12': {
    inputs: {
      unet_name: 'flux1-dev.safetensors',
      weight_dtype: 'fp8_e4m3fn'
    },
    class_type: 'UNETLoader',
    _meta: {
      title: 'Load Diffusion Model'
    }
  },
  '13': {
    inputs: {
      noise: ['25', 0],
      guider: ['22', 0],
      sampler: ['16', 0],
      sigmas: ['17', 0],
      latent_image: ['5', 0]
    },
    class_type: 'SamplerCustomAdvanced',
    _meta: {
      title: 'SamplerCustomAdvanced'
    }
  },
  '16': {
    inputs: {
      sampler_name: 'euler'
    },
    class_type: 'KSamplerSelect',
    _meta: {
      title: 'KSamplerSelect'
    }
  },
  '17': {
    inputs: {
      scheduler: 'simple',
      steps: 25,
      denoise: 1,
      model: ['61', 0]
    },
    class_type: 'BasicScheduler',
    _meta: {
      title: 'BasicScheduler'
    }
  },
  '22': {
    inputs: {
      model: ['61', 0],
      conditioning: ['60', 0]
    },
    class_type: 'BasicGuider',
    _meta: {
      title: 'BasicGuider'
    }
  },
  '25': {
    inputs: {
      noise_seed: 967672802500704
    },
    class_type: 'RandomNoise',
    _meta: {
      title: 'RandomNoise'
    }
  },
  '60': {
    inputs: {
      guidance: 3.5,
      conditioning: ['6', 0]
    },
    class_type: 'FluxGuidance',
    _meta: {
      title: 'FluxGuidance'
    }
  },
  '61': {
    inputs: {
      max_shift: 1.1500000000000001,
      base_shift: 0.5,
      width: ['75', 0],
      height: ['77', 0],
      model: ['74', 0]
    },
    class_type: 'ModelSamplingFlux',
    _meta: {
      title: 'ModelSamplingFlux'
    }
  },
  '74': {
    inputs: {
      lora_id: '11234q3h10001b57y323416xx',
      lora_name: 'my_first_flux_lora_v1',
      strength_model: 1,
      model: ['12', 0]
    },
    class_type: 'LoraLoaderModelOnlyFromVolumePicstudio',
    _meta: {
      title: 'LoraLoaderModelOnlyFromVolumePicstudio'
    }
  },
  '75': {
    inputs: {
      input_id: 'width',
      default_value: 1024
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '77': {
    inputs: {
      input_id: 'height',
      default_value: 1024
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  }
};
