import { Svg } from '../../_design_system';
import { LayoutProps, ColorProps, SpaceProps } from 'styled-system';

const ActivityIndicator = <T extends LayoutProps | ColorProps | SpaceProps>({
  height = 24,
  width = 24,
  ...props
}: { height?: string | number; width?: string | number } & T) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    stroke="currentColor"
    {...props}
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="50" cy="50" fill="none" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </Svg>
);

export default ActivityIndicator;
