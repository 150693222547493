export const UltimateUpscale = {
  "1": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d882360c-6af0-4221-ab19-3870a7df4300/512?width=512&height=768&fit=cover&background=black"
    },
    "class_type": "LoadPoseImageFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadPoseImageFromUrlOrPathPicstudio"
    }
  },
  "4": {
    "inputs": {
      "seed": 677106390,
      "steps": 40,
      "cfg": 6,
      "sampler_name": "dpmpp_sde",
      "scheduler": "karras",
      "denoise": 1,
      "model": [
        "10",
        0
      ],
      "positive": [
        "_ApplyControlNetStacker",
        0
      ],
      "negative": [
        "_ApplyControlNetStacker",
        1
      ],
      "latent_image": [
        "14",
        0
      ]
    },
    "class_type": "KSamplerPicstudio",
    "_meta": {
      "title": "KSamplerPicstudio"
    }
  },
  "7": {
    "inputs": {
      "text": "asian, multiple heads, 2 heads, elongated body, double image, 2 faces, multiple people, double head, topless, (nsfw)+, naked, nude, porn, open mouth, teeth, (sunglasses), black and white, monochrome, greyscale, grayscale, bw, CyberRealistic_Negative, CyberRealistic_Negative_v3, badhandv4, negative_hand-neg, BadDream, portrait, close up, sunglasses, sunglasses, bindi, pottu, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, illustration, cartoon, text, cropped, out of frame, worst quality, low quality, jpeg artifacts, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, cellulite, pubic hair, armpit hair, body hair, nipples",
      "clip": [
        "10",
        1
      ]
    },
    "class_type": "CLIPTextEncodeNegativePicstudio",
    "_meta": {
      "title": "CLIPTextEncodeNegativePicstudio"
    }
  },
  "10": {
    "inputs": {
      "ckpt_name": "addison-cyber-df6e22fd-2a11-4bff-a547-60bf92bf18f9.safetensors"
    },
    "class_type": "CheckpointLoaderSimplePicstudio",
    "_meta": {
      "title": "CheckpointLoaderSimplePicstudio"
    }
  },
  "11": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5e20b188-2883-4772-5f56-2bca92a05400/512"
    },
    "class_type": "LoadFaceSwapImageFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadFaceSwapImageFromUrlOrPathPicstudio"
    }
  },
  "12": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b7fa0422-4c16-474f-8675-8a57f7861f00/512"
    },
    "class_type": "LoadReferenceFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadReferenceFromUrlOrPathPicstudio"
    }
  },
  "14": {
    "inputs": {
      "pixels": [
        "15",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEEncode",
    "_meta": {
      "title": "VAE Encode"
    }
  },
  "15": {
    "inputs": {
      "upscale_method": "nearest-exact",
      "width": 512,
      "height": 512,
      "crop": "disabled",
      "image": [
        "12",
        0
      ]
    },
    "class_type": "ReferenceImageScalePicStudio",
    "_meta": {
      "title": "ReferenceImageScalePicStudio"
    }
  },
  "18": {
    "inputs": {
      "gray_scale": false,
      "grain_type": "Fine",
      "grain_sat": 0.5,
      "grain_power": 0.7000000000000001,
      "shadows": 0.1,
      "highs": 0.1,
      "scale": 1,
      "sharpen": 1,
      "src_gamma": 1,
      "seed": 399,
      "image": [
        "28",
        0
      ]
    },
    "class_type": "ProPostFilmGrain",
    "_meta": {
      "title": "ProPostFilmGrain"
    }
  },
  "22": {
    "inputs": {
      "samples": [
        "4",
        0
      ]
    },
    "class_type": "gcLatentTunnelPicstudio",
    "_meta": {
      "title": "gcLatentTunnelPicstudio"
    }
  },
  "24": {
    "inputs": {
      "filename_prefix": "677106390_127248069",
      "images": [
        "18",
        0
      ]
    },
    "class_type": "SaveImage",
    "_meta": {
      "title": "Save Image"
    }
  },
  "25": {
    "inputs": {
      "filename_prefix": "677106390_452674396",
      "images": [
        "_VAEDecode_1",
        0
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "26": {
    "inputs": {
      "filename_prefix": "677106390_241708119",
      "images": [
        "_ReActorFaceSwap",
        0
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "27": {
    "inputs": {
      "filename_prefix": "677106390_187493922",
      "images": [
        "28",
        0
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "28": {
    "inputs": {
      "upscale_by": 2,
      "seed": 168786047503901,
      "steps": 20,
      "cfg": 3,
      "sampler_name": "dpmpp_sde",
      "scheduler": "karras",
      "denoise": 0.4,
      "mode_type": "Linear",
      "tile_width": 512,
      "tile_height": 768,
      "mask_blur": 8,
      "tile_padding": 32,
      "seam_fix_mode": "None",
      "seam_fix_denoise": 1,
      "seam_fix_width": 64,
      "seam_fix_mask_blur": 8,
      "seam_fix_padding": 16,
      "force_uniform_tiles": true,
      "tiled_decode": false,
      "image": [
        "_ReActorFaceSwap",
        0
      ],
      "model": [
        "10",
        0
      ],
      "positive": [
        "_ApplyControlNetStacker",
        0
      ],
      "negative": [
        "_ApplyControlNetStacker",
        1
      ],
      "vae": [
        "10",
        2
      ],
      "upscale_model": [
        "_UpscaleModelLoader",
        0
      ]
    },
    "class_type": "UltimateSDUpscale",
    "_meta": {
      "title": "Ultimate SD Upscale"
    }
  },
  "29": {
    "inputs": {
      "text": "",
      "clip": [
        "10",
        1
      ]
    },
    "class_type": "CLIPTextEncodePositivePicstudio",
    "_meta": {
      "title": "CLIPTextEncodePositivePicstudio"
    }
  },
  "_ControlNetLoader": {
    "inputs": {
      "control_net_name": "control_v11p_sd15_openpose_fp16.safetensors"
    },
    "class_type": "ControlNetLoader",
    "_meta": {
      "title": "Load ControlNet Model"
    }
  },
  "_UpscaleModelLoader": {
    "inputs": {
      "model_name": "8xNMKDFaces160000G_v10.pt"
    },
    "class_type": "UpscaleModelLoader",
    "_meta": {
      "title": "Load Upscale Model"
    }
  },
  "_PrepImageForClipVision": {
    "inputs": {
      "interpolation": "LANCZOS",
      "crop_position": "top",
      "sharpening": 0,
      "image": [
        "11",
        0
      ]
    },
    "class_type": "PrepImageForClipVision",
    "_meta": {
      "title": "Prep Training Face For Model"
    }
  },
  "_ControlNetStacker": {
    "inputs": {
      "strength": 0.65,
      "start_percent": 0,
      "end_percent": 0.8,
      "control_net": [
        "_ControlNetLoader",
        0
      ],
      "image": [
        "1",
        0
      ]
    },
    "class_type": "Control Net Stacker",
    "_meta": {
      "title": "Control Net Stacker"
    }
  },
  "_ApplyControlNetStacker": {
    "inputs": {
      "positive": [
        "29",
        0
      ],
      "negative": [
        "7",
        0
      ],
      "cnet_stack": [
        "_ControlNetStacker",
        0
      ]
    },
    "class_type": "Apply ControlNet Stack",
    "_meta": {
      "title": "Apply ControlNet Stack"
    }
  },
  "_VAEDecode_1": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "_VAEDecode_5": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "_ReActorFaceSwap": {
    "inputs": {
      "enabled": true,
      "swap_model": "inswapper_128.onnx",
      "facedetection": "retinaface_resnet50",
      "face_restore_model": "GFPGANv1.4.pth",
      "face_restore_visibility": 1,
      "codeformer_weight": 0.5,
      "detect_gender_input": "no",
      "detect_gender_source": "no",
      "input_faces_index": "0",
      "source_faces_index": "0",
      "console_log_level": 1,
      "input_image": [
        "_VAEDecode_1",
        0
      ],
      "source_image": [
        "11",
        0
      ]
    },
    "class_type": "ReActorFaceSwap",
    "_meta": {
      "title": "ReActor 🌌 Fast Face Swap"
    }
  }
};
