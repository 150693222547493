import styled from 'styled-components';
import { system } from 'styled-system';
import { color, ColorProps, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system';

type WhiteSpaceProps = {
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
};

const Text = styled.span<ColorProps & SpaceProps & LayoutProps & TypographyProps & WhiteSpaceProps>`
  ${color}
  ${layout} 
  ${space}
  ${typography}

  ${system({
    whiteSpace: true
  })}
`;

Text.defaultProps = {
  color: 'black'
};

export default Text;
