export enum AnalyticsEvents {
  'albumView' = 'album_view',
  'albumImageModalView' = 'album_image_modal_view',
  'browsePacksView' = 'browse_packs_view',
  'checkoutComplete' = 'checkout_complete',
  'checkoutStart' = 'checkout_start',
  'claimedFreePack' = 'claimed_free_pack',
  'clickPublishThemeButton' = 'click_publish_theme_button',
  'dashboardView' = 'dashboard_view',
  'faqView' = 'faq_view',
  'modelView' = 'model_view',
  'marketingView' = 'marketing_view',
  'packPreviewView' = 'pack_preview_view',
  'packsShopView' = 'packs_shop_view',
  'photoDownload' = 'photo_download',
  'pricingPageButtonClicked' = 'pricing_page_button_clicked',
  'pricingPageViewed' = 'pricing_page_viewed',
  'profileSettingsView' = 'profile_settings_view',
  'reviewsView' = 'reviews_view',
  'rateAlbum' = 'rate_album',
  'referView' = 'refer_view',
  'signIn' = 'sign_in',
  'subscriptionCancelled' = 'subscription_cancelled',
  'subscriptionCreated' = 'subscription_created',
  'trainGuidelinesViewed' = 'train_guidelines_viewed',
  'trainThemeSelected' = 'train_theme_selected'
}
