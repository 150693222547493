import { createSlice } from '@reduxjs/toolkit';

export interface ImageSelectionState {
  // Key is query + provider
  isSelectionActive: boolean;
  selectedImages: string[];
}

const initialState: ImageSelectionState = {
  isSelectionActive: false,
  selectedImages: []
};

export const imageSelectionSlice = createSlice({
  name: 'imageSelection',
  initialState,
  reducers: {
    setIsSelectionActive: (state, action) => {
      state.isSelectionActive = action.payload;
    },
    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    }
  }
});

export const { setIsSelectionActive: setIsSelectionActiveAction, setSelectedImages: setSelectedImagesAction } =
  imageSelectionSlice.actions;

export default imageSelectionSlice.reducer;
