import React from 'react';
import styled from 'styled-components';
import {
  gridGap,
  GridGapProps,
  gridAutoFlow,
  gridTemplateColumns,
  gridTemplateAreas,
  gridAutoColumns,
  gridAutoRows,
  alignItems,
  justifyItems,
  justifyContent,
  GridAutoFlowProps,
  GridTemplateColumnsProps,
  GridTemplateAreasProps,
  GridAutoColumnsProps,
  GridAutoRowsProps,
  AlignItemsProps,
  JustifyItemsProps,
  JustifyContentProps
} from 'styled-system';

import { Box } from '..';
import { GridItem as Item } from '../GridItem/GridItem';

export type GridProps = React.ComponentProps<typeof Box> &
  GridGapProps &
  GridAutoFlowProps &
  GridAutoFlowProps &
  GridTemplateColumnsProps &
  GridTemplateAreasProps &
  GridAutoColumnsProps &
  GridAutoRowsProps &
  AlignItemsProps &
  JustifyItemsProps &
  JustifyContentProps;

const GridComponent = styled(Box)<GridProps>`
  ${gridGap};
  ${gridAutoFlow};
  ${gridTemplateColumns};
  ${gridTemplateAreas};
  ${gridAutoColumns};
  ${gridAutoRows};
  ${alignItems};
  ${justifyContent};
  ${justifyItems};
`;

GridComponent.defaultProps = {
  display: 'grid'
};

export const Grid = Object.assign(GridComponent, {
  Item
});
