import { FC, ReactNode } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import DropZoneContainer from './DropZoneContainer';

export type DropZoneState = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
};

export interface DropZoneProps {
  maxFiles: number;
  container?: FC<any>;
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  disabled?: boolean;
  children: ReactNode;
  accept?: string;
}

const DropZone = ({
  maxFiles,
  container: Container = DropZoneContainer,
  onDrop,
  children,
  accept = 'image/*'
}: DropZoneProps) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: maxFiles,
    accept: { [accept]: [] },
    onDrop: onDrop
  });

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      {children}
    </Container>
  );
};

export default DropZone;
