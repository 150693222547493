import { GLASSES_VALUES } from "../constants";

export const getGlassesDescription = (glasses: boolean, glassesModifier?: string): string => {
  if (!glasses && !glassesModifier) return '';
  if (glassesModifier === GLASSES_VALUES.RANDOM) return 'wearing {glasses}';
  if (glassesModifier === GLASSES_VALUES.MODEL_GLASSES) return 'wearing glasses';
  if (glassesModifier === GLASSES_VALUES.NONE) return '';
  if (glassesModifier) return `wearing ${glassesModifier}`;
  if (glasses) return `wearing glasses`;
  return '';
};
