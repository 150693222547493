import { getBaseAPIHost } from '@/_data/getBaseAPIHost';
import { queryClient } from '@/_data/queryClient';
import { PromptPageResponse } from '@pages/api/prompt/page/[id]';
import { PromptAsset } from '@store/prompt/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export type usePromptPageResult = NonNullable<PromptPageResponse>;
type Options = UseQueryOptions<usePromptPageResult, Error>;

export const usePromptPageQueryKey = ['promptPage'];

export const usePromptPage = (promptId: string, options?: Partial<Options>) => {
  const { data: prompt, ...rest } = useQuery({
    queryKey: [...usePromptPageQueryKey, promptId],
    queryFn: () => getPromptPage(promptId),
    ...options,
    staleTime: Infinity
  });

  const fullPrompt = { ...prompt, liked: prompt?.likes ? prompt.likes?.length > 0 : false } as typeof prompt & {
    liked: boolean;
  };

  return { prompt: fullPrompt, ...rest };
};

export const deletePromptPageAsset = (promptId: string, assetId: string) => {
  //get the existing data for this prompt
  const existingPrompt = queryClient.getQueryData<usePromptPageResult>([...usePromptPageQueryKey, promptId]);

  //if it exists, update it
  if (existingPrompt) {
    queryClient.setQueryData([...usePromptPageQueryKey, promptId], {
      ...existingPrompt,
      assets: existingPrompt.assets.filter(asset => asset.id !== assetId)
    });
  }
};

export const unDeletePromptPageAsset = (promptId: string, asset: PromptAsset) => {
  //get the existing data for this prompt
  const existingPrompt = queryClient.getQueryData<usePromptPageResult>([...usePromptPageQueryKey, promptId]);

  //if it exists, update it
  if (existingPrompt) {
    queryClient.setQueryData([...usePromptPageQueryKey, promptId], {
      ...existingPrompt,
      assets: [...existingPrompt.assets, asset]
    });
  }
};

export const updatePromptPage = (updatedPrompt: Partial<usePromptPageResult>) => {
  //get the existing data for this prompt
  const existingPrompt = queryClient.getQueryData<usePromptPageResult>([...usePromptPageQueryKey, updatedPrompt.id]);

  //if it exists, update it
  if (existingPrompt) {
    queryClient.setQueryData([...usePromptPageQueryKey, updatedPrompt.id], {
      ...existingPrompt,
      ...updatedPrompt
    });
  }
};

export async function getPromptPage(
  promptId: string,
  additionalHeaders?: Record<string, string>
): Promise<usePromptPageResult> {
  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const url = `${getBaseAPIHost()}/prompt/page/${promptId}`;

  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      ...headers,
      ...additionalHeaders
    }
  });

  return response.json();
}
