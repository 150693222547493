import styled from 'styled-components';
import { offset } from './utils/offset';
import { layout, LayoutProps, space, SpaceProps, flexbox, FlexboxProps } from 'styled-system';

const Column = styled.div<
  LayoutProps & SpaceProps & FlexboxProps & { offset?: string | number | (string | number | null)[] }
>`
  ${layout};
  ${space};
  ${offset};
  ${flexbox};

  min-height: 1px;
`;

Column.defaultProps = {
  pl: 3,
  pr: 3
};

Column.displayName = 'Column';

export default Column;
