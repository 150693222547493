export const BodyTypes = ['Skinny', 'Slim', 'Average', 'Athletic', 'Muscular', 'Curvy', 'Overweight', 'Obese', 'None'];

type Gender = 'Male' | 'Female';

/**
 * Descriptions for different body types based on gender.
 * These descriptions are used to replace the {body type} placeholder in prompts.
 * 
 * @example
 * bodyTypeDescriptions['Male']['Athletic'] // Returns: "fit and toned physique with visible muscle definition"
 * bodyTypeDescriptions['Female']['Curvy'] // Returns: "accentuated waist-to-hip ratio with fuller bust and hips"
 * from PhotoAi.com if(gender=f){hourglass figure} if(gender=m){fit body}
 */
const bodyTypeDescriptions: Record<Gender, Record<string, string>> = {
  Male: {
    Skinny: 'very thin and lean physique with minimal body fat',
    Slim: 'slender build with little excess body fat',
    Average: 'typical build, neither particularly thin nor overweight',
    Athletic: 'fit and toned physique with visible muscle definition',
    Muscular: 'well-developed muscles and low body fat percentage',
    Curvy: 'fuller figure with accentuated body curves',
    Overweight: 'carrying extra weight, particularly around the midsection',
    Obese: 'significantly overweight with a round body shape',
    None: ''
  },
  Female: {
    Skinny: 'very thin frame with minimal curves',
    Slim: 'slender figure with subtle curves',
    Average: 'balanced proportions, neither particularly thin nor curvy',
    Athletic: 'toned and fit physique with subtle muscle definition',
    Muscular: 'well-defined muscles while maintaining a feminine shape',
    Curvy: 'accentuated waist-to-hip ratio with fuller bust and hips',
    Overweight: 'carrying extra weight, particularly around hips and waist',
    Obese: 'significantly overweight with a round body shape',
    None: ''
  }
};

/**
 * Returns a description for the given body type and gender.
 * If the body type is 'None' or not provided, an empty string is returned.
 *
 * @param bodyType - The body type to use for the description
 * @param gender - The gender to use for the description ('Male' or 'Female')
 * @returns The description for the given body type and gender, or an empty string
 *
 * @example
 * const description = parseBodyType("Athletic", "Female");
 * // Result: "toned and fit physique with subtle muscle definition"
 *
 * @example
 * const description = parseBodyType("None", "Male");
 * // Result: ""
 */
export const parseBodyType = (bodyType: string, gender: Gender): string => {
  if (bodyType === 'None' || !bodyType) {
    return '';
  }

  return bodyTypeDescriptions[gender][bodyType] || '';
};