import { useRouter } from 'next/router';
import * as React from 'react';

export const useQueryParam = (queryKey: string, _default?: any) => {
  const router = useRouter();
  return React.useMemo(() => {
    if (router.query[queryKey]) {
      return String(router.query[queryKey]);
    }

    const queryParamsStr = router.asPath.split('?').slice(1).join('');
    const extractedQueryParam = new URLSearchParams(queryParamsStr).get(queryKey);

    if (extractedQueryParam) {
      return extractedQueryParam;
    }

    return _default;
  }, [router.asPath, router.query, queryKey, _default]);
};
