import { Variant } from '../types/types';

const getColor = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return 'icon.contrast';
    case 'secondary':
      return 'icon.primary';
    case 'tertiary':
      return 'icon.primary';
    case 'accent':
      return 'icon.primary';
  }
};

export default getColor;
