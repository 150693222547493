import { Identify, identify, reset } from '@amplitude/marketing-analytics-browser';
import { useSession as useNextSession, signIn, signOut } from 'next-auth/react';

import * as React from 'react';
// import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { PLANS } from '@/_data/plans';
import { PlanType } from '@pages/api/stripe/getCheckoutSession';

// const isSafarionIphone = typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// let beamsClient: PusherPushNotifications.Client | undefined;
// try {
//   beamsClient =
//     typeof window !== 'undefined' && !isSafarionIphone
//       ? new PusherPushNotifications.Client({
//           instanceId: process.env.NEXT_PUBLIC_PUSHER_INSTANCE_ID || ''
//         })
//       : undefined;
// } catch (error) {
//   console.error(error);
// }

export const useSession = () => {
  const nextSession = useNextSession();

  const status = React.useMemo(() => {
    return nextSession?.status || 'loading';
  }, [nextSession?.status]);

  const session = React.useMemo(() => {
    return nextSession?.data || null;
  }, [nextSession?.data]);

  const isLoading = React.useMemo(() => {
    return status === 'loading';
  }, [status]);

  const isLoggedIn = React.useMemo(() => {
    return status === 'authenticated' && !!session?.user;
  }, [status, session?.user]);

  const user = React.useMemo(() => {
    const user = session?.user;

    return user;
  }, [session?.user]);

  const identifyUser = React.useCallback(() => {
    if (user) {
      const event = new Identify();
      event.setOnce('email', user.email);
      event.set('name', user.name);
      event.set('username', user.username);
      event.setOnce('isAdmin', user.isAdmin);
      event.set('subscriptionPlan', user.subscriptionPlan);
      identify(event, { user_id: user.email });
    }
  }, [user]);

  const hasDallEAccess = true;

  const logOut = React.useCallback(() => {
    reset();
    signOut();
    // beamsClient?.stop().catch(console.error);
  }, []);

  const subscriptionPlan = React.useMemo(() => {
    const planType = (user?.subscriptionPlan || 'free') as PlanType;
    return PLANS[planType] || PLANS.free;
  }, [user?.subscriptionPlan]);

  const refreshSession = React.useCallback(() => {
    if (session?.user?.id) {
      nextSession.update({
        userId: session?.user?.id
      });
    }
  }, [nextSession, session?.user?.id]);
  const claimedSharePack = React.useMemo(() => {
    return !!((session?.user as Record<string, any>)?.meta as Record<string, any>)?.claimedSharePack;
  }, [session?.user]);

  const nextPackFree = React.useMemo(() => {
    return (
      !!((session?.user as Record<string, any>)?.meta as Record<string, any>)?.claimedSharePack &&
      !((session?.user as Record<string, any>)?.meta as Record<string, any>)?.usedSharePack
    );
  }, [session?.user]);

  return {
    session,
    isLoading,
    isLoggedIn,
    user,
    hasDallEAccess,
    signIn,
    logOut,
    subscriptionPlan,
    identifyUser,
    refreshSession,
    claimedSharePack,
    nextPackFree
  };
};
