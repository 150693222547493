export const Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel = {
  "1": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/570fed27-e432-4951-0ed6-96379afdeb00/width=512,height=512,fit=cover,background=black"
    },
    "class_type": "LoadPoseImageFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadPoseImageFromUrlOrPathPicstudio"
    }
  },
  "4": {
    "inputs": {
      "seed": 158437665,
      "steps": 30,
      "cfg": 6,
      "sampler_name": "dpmpp_sde",
      "scheduler": "karras",
      "denoise": 1,
      "model": [
        "_IPAdapter",
        0
      ],
      "positive": [
        "_ApplyControlNetStacker",
        0
      ],
      "negative": [
        "_ApplyControlNetStacker",
        1
      ],
      "latent_image": [
        "14",
        0
      ]
    },
    "class_type": "KSamplerPicstudio",
    "_meta": {
      "title": "KSamplerPicstudio"
    }
  },
  "7": {
    "inputs": {
      "text": "asian, multiple heads, 2 heads, elongated body, double image, 2 faces, multiple people, double head, topless, (nsfw)+, naked, nude, porn, open mouth, teeth, (sunglasses), black and white, monochrome, greyscale, grayscale, bw, CyberRealistic_Negative, CyberRealistic_Negative_v3, badhandv4, negative-hand, baddream, portrait, close up, sunglasses, sunglasses, bindi, pottu, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, illustration, cartoon, text, cropped, out of frame, worst quality, low quality, jpeg artifacts, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, cellulite, pubic hair, armpit hair, body hair, nipples",
      "clip": [
        "10",
        1
      ]
    },
    "class_type": "CLIPTextEncodeNegativePicstudio",
    "_meta": {
      "title": "CLIPTextEncodeNegativePicstudio"
    }
  },
  "10": {
    "inputs": {
      "ckpt_name": "addison-cyber-df6e22fd-2a11-4bff-a547-60bf92bf18f9.safetensors"
    },
    "class_type": "CheckpointLoaderSimplePicstudio",
    "_meta": {
      "title": "CheckpointLoaderSimplePicstudio"
    }
  },
  "11": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/bba34536-c988-4f56-91c6-e1280ee20000/512"
    },
    "class_type": "LoadFaceSwapImageFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadFaceSwapImageFromUrlOrPathPicstudio"
    }
  },
  "12": {
    "inputs": {
      "url_or_path": "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b7fa0422-4c16-474f-8675-8a57f7861f00/512"
    },
    "class_type": "LoadReferenceFromUrlOrPathPicstudio",
    "_meta": {
      "title": "LoadReferenceFromUrlOrPathPicstudio"
    }
  },
  "13": {
    "inputs": {
      "upscale_method": "bilinear",
      "width": 1024,
      "height": 1024,
      "crop": "disabled",
      "image": [
        "_ImageUpscaleWithModel",
        0
      ]
    },
    "class_type": "ImageScalePicstudio",
    "_meta": {
      "title": "ImageScalePicstudio"
    }
  },
  "14": {
    "inputs": {
      "pixels": [
        "15",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEEncode",
    "_meta": {
      "title": "VAE Encode"
    }
  },
  "15": {
    "inputs": {
      "upscale_method": "nearest-exact",
      "width": 512,
      "height": 512,
      "crop": "disabled",
      "image": [
        "12",
        0
      ]
    },
    "class_type": "ReferenceImageScalePicStudio",
    "_meta": {
      "title": "ReferenceImageScalePicStudio"
    }
  },
  "16": {
    "inputs": {
      "prompt": "headshot, analog style modelshoot style Medium portrait of confident  38 yo (cjw+ man)+ wearing glasses, __cyberdelia/poses/cyber_facial_expression__, __cyberdelia/male/cyber_dailyclothing_male__, __cyberdelia/photo/cyber_framing__, under __cyberdelia/photo/cyber_lighting__, __cyberdelia/photo/cyber_angle__, shot on a __cyberdelia/photo/cyber_camera_properties__\n\nmasterpiece, cinematic light, cinematic lighting, ultrarealistic, photorealistic, 8k, raw photo, realistic, sharp focus on eyes, symmetrical eyes, intact eyes, hyperrealistic, highest quality, best quality, highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow, dramatic lighting, highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition, strong bokeh, award winning photo,",
      "seed": 0
    },
    "class_type": "Wildcard Processor",
    "_meta": {
      "title": "Wildcard Processor (Mikey)"
    }
  },
  "17": {
    "inputs": {
      "text": [
        "16",
        0
      ],
      "clip": [
        "10",
        1
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "_ControlNetLoader": {
    "inputs": {
      "control_net_name": "control_v11p_sd15_openpose_fp16.safetensors"
    },
    "class_type": "ControlNetLoader",
    "_meta": {
      "title": "Load ControlNet Model"
    }
  },
  "_UpscaleModelLoader": {
    "inputs": {
      "model_name": "8xNMKDFaces160000G_v10.pt"
    },
    "class_type": "UpscaleModelLoader",
    "_meta": {
      "title": "Load Upscale Model"
    }
  },
  "_PrepImageForClipVision": {
    "inputs": {
      "interpolation": "LANCZOS",
      "crop_position": "top",
      "sharpening": 0,
      "image": [
        "11",
        0
      ]
    },
    "class_type": "PrepImageForClipVision",
    "_meta": {
      "title": "Prep Training Face For Model"
    }
  },
  "_ControlNetStacker": {
    "inputs": {
      "strength": 0.75,
      "start_percent": 0,
      "end_percent": 0.8,
      "control_net": [
        "_ControlNetLoader",
        0
      ],
      "image": [
        "1",
        0
      ]
    },
    "class_type": "Control Net Stacker",
    "_meta": {
      "title": "Control Net Stacker"
    }
  },
  "_IPAdapterUnifiedLoaderFaceID": {
    "inputs": {
      "preset": "FACEID PORTRAIT (style transfer)",
      "lora_strength": 1,
      "provider": "CUDA",
      "model": [
        "10",
        0
      ]
    },
    "class_type": "IPAdapterUnifiedLoaderFaceID",
    "_meta": {
      "title": "Load FaceSwap Model"
    }
  },
  "_IPAdapter": {
    "inputs": {
      "weight": 1,
      "start_at": 0,
      "end_at": 1,
      "weight_type": "prompt is more important",
      "model": [
        "_IPAdapterUnifiedLoaderFaceID",
        0
      ],
      "ipadapter": [
        "_IPAdapterUnifiedLoaderFaceID",
        1
      ],
      "image": [
        "_PrepImageForClipVision",
        0
      ]
    },
    "class_type": "IPAdapter",
    "_meta": {
      "title": "Apply FaceSwap"
    }
  },
  "_ApplyControlNetStacker": {
    "inputs": {
      "positive": [
        "17",
        0
      ],
      "negative": [
        "7",
        0
      ],
      "cnet_stack": [
        "_ControlNetStacker",
        0
      ]
    },
    "class_type": "Apply ControlNet Stack",
    "_meta": {
      "title": "Apply ControlNet Stack"
    }
  },
  "_VAEDecode_1": {
    "inputs": {
      "samples": [
        "4",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "_VAEDecode_5": {
    "inputs": {
      "samples": [
        "4",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "_ReActorFaceSwap": {
    "inputs": {
      "enabled": true,
      "swap_model": "inswapper_128.onnx",
      "facedetection": "retinaface_resnet50",
      "face_restore_model": "GFPGANv1.4.pth",
      "face_restore_visibility": 1,
      "codeformer_weight": 0.5,
      "detect_gender_input": "no",
      "detect_gender_source": "no",
      "input_faces_index": "0",
      "source_faces_index": "0",
      "console_log_level": 1,
      "input_image": [
        "_VAEDecode_1",
        0
      ]
    },
    "class_type": "ReActorFaceSwap",
    "_meta": {
      "title": "ReActor 🌌 Fast Face Swap"
    }
  },
  "_SaveImageWebsocketFirstPass": {
    "inputs": {
      "images": [
        "_VAEDecode_1",
        0
      ]
    },
    "class_type": "SaveImageWebsocket",
    "_meta": {
      "title": "SaveImageWebsocket"
    }
  },
  "_SaveImageWebsocketSecondPass": {
    "inputs": {
      "images": [
        "_ReActorFaceSwap",
        0
      ]
    },
    "class_type": "SaveImageWebsocket",
    "_meta": {
      "title": "SaveImageWebsocket"
    }
  },
  "_ImageUpscaleWithModel": {
    "inputs": {
      "upscale_model": [
        "_UpscaleModelLoader",
        0
      ],
      "image": [
        "_ReActorFaceSwap",
        0
      ]
    },
    "class_type": "ImageUpscaleWithModel",
    "_meta": {
      "title": "Upscale Image"
    }
  },
  "_VAEEncode_3": {
    "inputs": {
      "pixels": [
        "13",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEEncode",
    "_meta": {
      "title": "VAE Encode"
    }
  },
  "_KSampler": {
    "inputs": {
      "seed": 563376393288590,
      "steps": 14,
      "cfg": 3,
      "sampler_name": "uni_pc_bh2",
      "scheduler": "normal",
      "denoise": 0.25,
      "model": [
        "_IPAdapter",
        0
      ],
      "positive": [
        "_ApplyControlNetStacker",
        0
      ],
      "negative": [
        "_ApplyControlNetStacker",
        1
      ],
      "latent_image": [
        "_VAEEncode_3",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "_VAEDecode": {
    "inputs": {
      "samples": [
        "_KSampler",
        0
      ],
      "vae": [
        "10",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "_SaveImageWebsocket": {
    "inputs": {
      "images": [
        "_VAEDecode",
        0
      ]
    },
    "class_type": "SaveImageWebsocket",
    "_meta": {
      "title": "SaveImageWebsocket"
    }
  }
};
