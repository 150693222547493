export const FLUX_REALISM_LORA_FACE_DETAILER_UPSCALE = {
  '5': {
    inputs: {
      width: ['135', 0],
      height: ['136', 0],
      batch_size: 1
    },
    class_type: 'EmptyLatentImage',
    _meta: {
      title: 'Empty Latent Image'
    }
  },
  '6': {
    inputs: {
      text: ['139', 0],
      clip: ['11', 0]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '10': {
    inputs: {
      vae_name: 'ae.safetensors'
    },
    class_type: 'VAELoader',
    _meta: {
      title: 'Load VAE'
    }
  },
  '11': {
    inputs: {
      clip_name1: 't5xxl_fp16.safetensors',
      clip_name2: 'clip_l.safetensors',
      type: 'flux'
    },
    class_type: 'DualCLIPLoader',
    _meta: {
      title: 'DualCLIPLoader'
    }
  },
  '12': {
    inputs: {
      unet_name: 'flux1-dev.safetensors',
      weight_dtype: 'fp8_e4m3fn'
    },
    class_type: 'UNETLoader',
    _meta: {
      title: 'Load Diffusion Model'
    }
  },
  '61': {
    inputs: {
      max_shift: 1.1500000000000001,
      base_shift: 0.5,
      width: ['135', 0],
      height: ['136', 0],
      model: ['148', 0]
    },
    class_type: 'ModelSamplingFlux',
    _meta: {
      title: 'ModelSamplingFlux'
    }
  },
  '74': {
    inputs: {
      lora_id: 'clzyx4iwl001hneuh4nm27amu',
      lora_name: 'klee-ea45b036-241c-4c6a-8c7f-1a3a179d5038.safetensors',
      strength_model: 0.8,
      model: ['12', 0]
    },
    class_type: 'LoraLoaderModelOnlyFromVolumePicstudio',
    _meta: {
      title: 'LoraLoaderModelOnlyFromVolumePicstudio'
    }
  },
  '102': {
    inputs: {
      text: '',
      clip: ['11', 0]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '105': {
    inputs: {
      guide_size: 512,
      guide_size_for: true,
      max_size: 1024,
      seed: 326944313186013,
      steps: 20,
      cfg: 1,
      sampler_name: 'euler',
      scheduler: 'normal',
      denoise: 0.5,
      feather: 5,
      noise_mask: true,
      force_inpaint: true,
      bbox_threshold: 0.5,
      bbox_dilation: 10,
      bbox_crop_factor: 3,
      sam_detection_hint: 'center-1',
      sam_dilation: 0,
      sam_threshold: 0.93,
      sam_bbox_expansion: 0,
      sam_mask_hint_threshold: 0.7000000000000001,
      sam_mask_hint_use_negative: 'False',
      drop_size: 10,
      wildcard: '',
      cycle: 1,
      inpaint_model: false,
      noise_mask_feather: 20,
      image: ['119', 0],
      model: ['108', 1],
      clip: ['108', 2],
      vae: ['108', 3],
      positive: ['108', 4],
      negative: ['108', 5],
      bbox_detector: ['112', 0]
    },
    class_type: 'FaceDetailer',
    _meta: {
      title: 'FaceDetailer'
    }
  },
  '108': {
    inputs: {
      model: ['61', 0],
      clip: ['11', 0],
      vae: ['10', 0],
      positive: ['146', 0],
      negative: ['118', 0]
    },
    class_type: 'Bus Node',
    _meta: {
      title: 'Bus Node'
    }
  },
  '112': {
    inputs: {
      model_name: 'bbox/face_yolov8m.pt'
    },
    class_type: 'UltralyticsDetectorProvider',
    _meta: {
      title: 'UltralyticsDetectorProvider'
    }
  },
  '113': {
    inputs: {
      mask: ['105', 3]
    },
    class_type: 'MaskToImage',
    _meta: {
      title: 'Convert Mask to Image'
    }
  },
  '117': {
    inputs: {
      seed: ['137', 0],
      steps: 25,
      cfg: 1,
      sampler_name: 'euler',
      scheduler: 'simple',
      denoise: 1,
      model: ['108', 1],
      positive: ['108', 4],
      negative: ['108', 5],
      latent_image: ['5', 0]
    },
    class_type: 'KSampler',
    _meta: {
      title: 'KSampler'
    }
  },
  '118': {
    inputs: {
      conditioning: ['102', 0]
    },
    class_type: 'ConditioningZeroOut',
    _meta: {
      title: 'ConditioningZeroOut'
    }
  },
  '119': {
    inputs: {
      samples: ['117', 0],
      vae: ['108', 3]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '131': {
    inputs: {
      filename_prefix: '296572015_690627559',
      images: ['119', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '132': {
    inputs: {
      filename_prefix: '296572015_326221992',
      images: ['105', 2]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '133': {
    inputs: {
      filename_prefix: '296572015_140667721',
      images: ['105', 1]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '134': {
    inputs: {
      filename_prefix: '296572015_590820583',
      images: ['113', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '135': {
    inputs: {
      input_id: 'width',
      default_value: 832
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '136': {
    inputs: {
      input_id: 'height',
      default_value: 1216
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '137': {
    inputs: {
      input_id: 'seed',
      default_value: 296572015
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '139': {
    inputs: {
      input_id: 'prompt',
      default_value:
        "Subject: Caucasian TOK woman wearing glasses. TOK is posed with a neutral and contemplative expression, conveying a sense of calm introspection. TOK seems to be thoughtful and in a reserved mood for the portrait. TOK is wearing Brown knit sweater paired with relaxed-fit jeans and brown boots., Background: The background is a Minimalist indoor setting with clean, beige walls, creating a cool and luxurious contrast that enhances the presence of the subject in the image. This setting adds a serene and sophisticated atmosphere to the portrait., Lighting: The scene is illuminated with soft, diffused lighting, evenly illuminating the subject's face and minimizing harsh shadows. The lighting is designed to highlight the texture of the clothing while maintaining a cool, flattering tone throughout the image., Depth of Field: A moderate depth of field is employed, ensuring the subject remains in sharp focus while the background is slightly blurred. This approach keeps attention on the subject, adding to the calm and introspective mood of the portrait., Color Grading: The color palette emphasizes cool tones throughout the image, with natural skin tones that have a slight cool tint. The overall effect is sophisticated and luxurious, enhancing the serene and elegant quality of the portrait., Camera Used: The portrait is captured with a high-resolution digital camera, such as a mirrorless or DSLR, ensuring sharpness and accurate color reproduction to capture fine details of the subject's hair, face, and clothing., Overall Impression: The portrait exudes a sense of calm and luxury, with a focus on introspection and subtle emotion. The combination of cool lighting, a luxurious background, and a relaxed pose creates a refined and balanced image that draws the viewer into the subject's contemplative world."
    },
    class_type: 'ExternalTextPicstudio',
    _meta: {
      title: 'ExternalTextPicstudio'
    }
  },
  '140': {
    inputs: {
      sharpen_radius: 1,
      sigma: 0.5,
      alpha: 1,
      image: ['105', 0]
    },
    class_type: 'ImageSharpen',
    _meta: {
      title: 'ImageSharpen'
    }
  },
  '141': {
    inputs: {
      images: ['140', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '142': {
    inputs: {
      upscale_by: 2,
      seed: 872127617799876,
      steps: 20,
      cfg: 1,
      sampler_name: 'euler',
      scheduler: 'beta',
      denoise: 0.2,
      mode_type: 'Linear',
      tile_width: ['135', 0],
      tile_height: ['136', 0],
      mask_blur: 8,
      tile_padding: 32,
      seam_fix_mode: 'None',
      seam_fix_denoise: 1,
      seam_fix_width: 64,
      seam_fix_mask_blur: 8,
      seam_fix_padding: 16,
      force_uniform_tiles: true,
      tiled_decode: false,
      image: ['140', 0],
      model: ['108', 1],
      positive: ['108', 4],
      negative: ['108', 5],
      vae: ['108', 3],
      upscale_model: ['143', 0]
    },
    class_type: 'UltimateSDUpscale',
    _meta: {
      title: 'Ultimate SD Upscale'
    }
  },
  '143': {
    inputs: {
      model_name: '4x-UltraSharp.pth'
    },
    class_type: 'UpscaleModelLoader',
    _meta: {
      title: 'Load Upscale Model'
    }
  },
  '144': {
    inputs: {
      filename_prefix: '296572015_218301459',
      images: ['142', 0]
    },
    class_type: 'SaveImage',
    _meta: {
      title: 'Save Image'
    }
  },
  '145': {
    inputs: {
      filename_prefix: '296572015_487514378',
      images: ['105', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '146': {
    inputs: {
      guidance: 3.5,
      conditioning: ['6', 0]
    },
    class_type: 'FluxGuidance',
    _meta: {
      title: 'FluxGuidance'
    }
  },
  '148': {
    inputs: {
      lora_name: 'araminta_k_flux_koda.safetensors',
      strength_model: 0.25,
      model: ['74', 0]
    },
    class_type: 'LoraLoaderModelOnly',
    _meta: {
      title: 'LoraLoaderModelOnly'
    }
  },
  '149': {
    inputs: {
      image: 'pasted/image.png',
      upload: 'image'
    },
    class_type: 'LoadImage',
    _meta: {
      title: 'Load Image'
    }
  }
};
