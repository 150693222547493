import { Gender_Association, tag_category_type, Modifier, Prisma } from '@acme/db';
import { TrainingMeta } from './types/trainingMeta';
import { logger } from './logger';
import { processPrompt } from './processPrompt';
import { generatePrompts } from './generatePrompts';

export interface ProcessedPrompts {
  prompts: string[];
  negativePrompts: string[];
}

export const processAndGeneratePrompt = async (
  _prompt: string,
  _negativePrompt: string,
  trainingMeta: TrainingMeta,
  count: number,
  modifiers?: { [key: string]: string } | undefined,
  enabledAge?: boolean
): Promise<ProcessedPrompts> => {

  const { prompt, negativePrompt, categories } = await processPrompt(_prompt, _negativePrompt, trainingMeta, count, modifiers, enabledAge);

  return generatePrompts(prompt, negativePrompt, count, categories, modifiers);
};

