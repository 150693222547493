import { getBaseAPIHost } from '@/_data/getBaseAPIHost';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Modifier } from '@lib/getModifiers';

export const fetchAllStyles = createAsyncThunk('styles/fetch', async (): Promise<Modifier[] | null> => {
  try {
    const url = `${getBaseAPIHost()}/style`;
    const headers: HeadersInit = { 'Content-Type': 'application/json' };
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: headers
    });

    const styles = (await response.json()) as Modifier[];
    return styles;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export const createStyle = createAsyncThunk('styles/create', async (name: string): Promise<Modifier | null> => {
  try {
    const url = `${getBaseAPIHost()}/style`;
    const headers: HeadersInit = { 'Content-Type': 'application/json' };
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ name })
    });

    const modifier = (await response.json()) as Modifier;
    return modifier;
  } catch (error) {
    console.error(error);
    return null;
  }
});
