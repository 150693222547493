import { useInfiniteQuery } from '@tanstack/react-query';
import { getBaseAPIHost } from '@/_data/getBaseAPIHost';
import { GetFeedResponse } from '@pages/api/feed';
import { PromptResult } from '@store/prompt/types';
import { FeedType, DEFAULT_FEED_TYPE, FEED_TYPES } from '@components/Feed/FeedTypeSelector';
import { FeedItem, FEED_ITEM_TYPES } from '@components/Feed/useFeed';
import { queryClient } from '@/_data/queryClient';
import { setCommentsForPost } from '@/_data/comment/useComments';

const headers: HeadersInit = { 'Content-Type': 'application/json' };

export const getFeedQueryKey = ['feed'];

export async function getFeed(
  feedType: FeedType | null = DEFAULT_FEED_TYPE,
  nextPage?: string,
  isServer?: boolean,
  additionalHeaders?: Record<string, string>
): Promise<GetFeedResponse> {
  const constructedFeedType = `feedType=${feedType}`;
  const constructedIsServer = isServer ? `isServer=${isServer}` : '';
  const queryParams = '?' + [constructedFeedType, constructedIsServer].filter(Boolean).join('&');

  const url = `${getBaseAPIHost()}/feed${nextPage ? nextPage : queryParams}`;

  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      ...headers,
      ...additionalHeaders
    }
  });

  const result = await response.json();

  // Cache comments in separate query
  for (let post of result.feedItems) {
    if (post.post.data.comments) {
      setCommentsForPost(post.post.data.id, post.post.data.comments);
    }
    if (post.collapsedItems) {
      for (let collapsedPost of post.collapsedItems) {
        if (collapsedPost.post.data.comments) {
          setCommentsForPost(collapsedPost.post.data.id, collapsedPost.post.data.comments);
        }
      }
    }
  }

  return result;
}

export const useGetFeed = (feedType: FeedType | null = DEFAULT_FEED_TYPE) => {
  return useInfiniteQuery<GetFeedResponse, Error>({
    queryKey: [...getFeedQueryKey, feedType],
    queryFn: ({ pageParam }) => getFeed(feedType, pageParam ? String(pageParam) : undefined),
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextPage || null,
    staleTime: Infinity
  });
};

export const updateFeedItem = (itemData: Partial<PromptResult>) => {
  const queries = queryClient.getQueriesData<{ pages: GetFeedResponse[] }>({
    predicate: ({ queryKey }) =>
      queryKey[0] === getFeedQueryKey[0] &&
      (queryKey.includes(FEED_TYPES.everyone.value) ||
        queryKey.includes(FEED_TYPES.following.value) ||
        queryKey.includes(FEED_TYPES.byMe.value) ||
        queryKey.includes(FEED_TYPES.adminMode.value))
  });

  if (queries && queries.length > 0) {
    queries.forEach(query => {
      const key = query[0];
      const data = query[1];

      queryClient.setQueryData(key, {
        ...data,
        pages: data?.pages?.map(page => ({
          ...page,
          feedItems: page.feedItems.map(feedItem => {
            //if this is a prompt, and the id matches, update the data
            if (feedItem.post.type === FEED_ITEM_TYPES.prompt && feedItem.post.data.id === itemData.id) {
              const newFeedItem = {
                ...feedItem,
                post: {
                  ...feedItem.post,
                  data: {
                    ...feedItem.post.data,
                    ...itemData
                  }
                }
              };

              return newFeedItem;
            }

            return feedItem;
          })
        }))
      });
    });
  }
};

export const removeFeedItem = (id: string) => {
  const queries = queryClient.getQueriesData<{ pages: GetFeedResponse[] }>({
    predicate: ({ queryKey }) =>
      queryKey[0] === getFeedQueryKey[0] &&
      (queryKey.includes(FEED_TYPES.everyone.value) ||
        queryKey.includes(FEED_TYPES.following.value) ||
        queryKey.includes(FEED_TYPES.byMe.value) ||
        queryKey.includes(FEED_TYPES.adminMode.value))
  });

  if (queries && queries.length > 0) {
    queries.forEach(query => {
      const key = query[0];
      const data = query[1];

      queryClient.setQueryData(key, {
        ...data,
        pages: data?.pages?.map(page => ({
          ...page,
          feedItems: page.feedItems.filter(feedItem => {
            if (feedItem.post.type === FEED_ITEM_TYPES.prompt && feedItem.post.data.id === id) {
              return false;
            }

            return true;
          })
        }))
      });
    });
  }
};

// Add to beginning of each feed type
export const addFeedItem = (feedItem: FeedItem) => {
  // DO it after 100ms for animation
  setTimeout(() => {
    const queries = queryClient.getQueriesData<{ pages: GetFeedResponse[] }>({
      predicate: ({ queryKey }) =>
        queryKey[0] === getFeedQueryKey[0] &&
        (queryKey.includes(FEED_TYPES.everyone.value) ||
          queryKey.includes(FEED_TYPES.following.value) ||
          queryKey.includes(FEED_TYPES.byMe.value) ||
          queryKey.includes(FEED_TYPES.adminMode.value))
    });

    if (queries && queries.length > 0) {
      queries.forEach(query => {
        const key = query[0];
        const data = query[1];

        queryClient.setQueryData(key, {
          ...data,
          pages: data?.pages?.map(page => ({
            ...page,
            feedItems: [feedItem, ...page.feedItems]
          }))
        });
      });
    }
  }, 100);
};
