import ButtonWrapper from '../ButtonWrapper/ButtonWrapper';
import { ComponentProps } from 'react';
import { Absolute, Text, Flex } from '..';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import icons from '../Icon/icons.json';
import { getColor, getBackgroundColor, getPadding, getStates } from './utils';
import { Variant, Size } from './types/types';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';

const StyledButton = styled(ButtonWrapper)<{ variant: Variant; size: Size }>`
  cursor: pointer;
  ${({ variant, size }) => getStates(variant, size)}
`;

const Button = <T extends ComponentProps<typeof ButtonWrapper>>({
  variant = 'accent',
  size = 'small',
  isLoading,
  text,
  icon,
  ...props
}: {
  variant?: Variant;
  size?: Size;
  isLoading?: boolean;
  text: string;
  icon?: keyof typeof icons;
} & T) => {
  const backgroundColor = getBackgroundColor(variant);
  const padding = getPadding(size);
  const color = getColor(variant);

  return (
    <StyledButton
      bg={backgroundColor}
      borderRadius={8}
      size={size}
      variant={variant}
      {...padding}
      {...props}
      as="button"
      position={isLoading ? 'relative' : null}
    >
      {/* @ts-ignore */}
      <Flex alignItems="center" justifyContent="center" gap={8} opacity={isLoading ? 0 : 1}>
        {icon && <Icon name={icon} iconSize="lg" color={color} />}
        {/* @ts-ignore */}
        <Text color={color} fontSize={2} fontWeight={700} letterSpacing="0.02em">
          {text}
        </Text>
      </Flex>
      {isLoading && (
        // @ts-ignore
        <Absolute top={0} left={0} bottom={0} right={0} display="flex">
          {/* @ts-ignore */}
          <Flex alignItems="center" justifyContent="center" flex={1}>
            <ActivityIndicator color={color} />
          </Flex>
        </Absolute>
      )}
    </StyledButton>
  );
};

export default Button;

Button.defaultProps = {
  variant: 'accent',
  size: 'small',
  isLoading: false
};
