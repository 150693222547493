import { Svg } from '../../_design_system';
import { ComponentProps } from 'react';
import classNames from 'classnames';

interface PicStudioLogoProps extends ComponentProps<typeof Svg> {
  className?: string;
}

const PicStudioLogo = <T extends PicStudioLogoProps>({ className, ...props }: T) => {
  const { height, width } = props;

  const logoClasses = classNames('fill-primary', 'w-36', className); // Add 'w-36' as the default width class

  const logoHeight = height || 19;
  const logoWidth = width || 105;

  return (
    <Svg viewBox={`0 0 ${logoWidth} ${logoHeight}`} fill="" stroke="none" {...props} className={logoClasses}>
      {/* Make className="fill-primary" */}
      <path
        className=""
        d="m0.72168 16.036v-13.475h5.4163c2.8389 0 4.7346 1.8303 4.7346 4.6038v0.01867c0 2.7735-1.8957 4.6038-4.7346 4.6038h-2.5961v4.249h-2.8202zm4.7252-11.243h-1.905v4.7906h1.905c1.6155 0 2.568-0.85914 2.568-2.3906v-0.01868c0-1.5315-0.95251-2.3813-2.568-2.3813z"
      />
      <path d="m13.94 4.7361c-0.8218 0-1.4568-0.64435-1.4568-1.4194 0-0.77508 0.635-1.4194 1.4568-1.4194s1.4568 0.64435 1.4568 1.4194c0 0.77509-0.635 1.4194-1.4568 1.4194zm-1.3634 11.299v-10.011h2.7268v10.011h-2.7268z" />
      <path d="m21.92 16.241c-3.0536 0-4.94-1.989-4.94-5.2201v-0.0094c0-3.203 1.9144-5.1921 4.912-5.1921 2.7175 0 4.4451 1.6716 4.5478 3.8381v0.06537h-2.5307l-0.0187-0.08404c-0.1681-0.95252-0.8311-1.6809-1.961-1.6809-1.3354 0-2.1759 1.1486-2.1759 3.063v0.0093c0 1.9611 0.8405 3.0724 2.1852 3.0724 1.0926 0 1.7743-0.6724 1.9611-1.681l0.0093-0.084h2.54v0.056c-0.1307 2.1852-1.8583 3.8474-4.5291 3.8474z" />
      <path d="m33.131 16.269c-3.2031 0-5.2668-1.4662-5.4256-3.9502l-0.0093-0.1494h2.6614l0.0187 0.1027c0.1774 1.0459 1.2887 1.6996 2.8295 1.6996 1.5595 0 2.5401-0.6817 2.5401-1.7089v-0.0093c0-0.9152-0.6631-1.3728-2.3346-1.7183l-1.4195-0.2802c-2.6614-0.53225-4.0061-1.8023-4.0061-3.8287v-0.00934c0-2.4653 2.1478-4.0902 5.1361-4.0902 3.119 0 5.0334 1.5595 5.1641 3.9221l0.0093 0.17743h-2.6614l-0.0093-0.11206c-0.1401-1.0552-1.1113-1.6996-2.5027-1.6996-1.4475 0.00934-2.3159 0.67236-2.3159 1.6342v0.00934c0 0.8498 0.691 1.3447 2.2412 1.6529l1.4287 0.28949c2.8202 0.5603 4.0902 1.7089 4.0902 3.8287v0.0094c0 2.5867-2.0357 4.2303-5.4349 4.2303z" />
      <path d="m44.249 16.241c-2.2225 0-3.2684-0.8591-3.2684-3.0069v-5.1548h-1.3914v-2.0544h1.3914v-2.4466h2.7548v2.4466h1.8303v2.0544h-1.8303v4.856c0 0.9338 0.4202 1.2513 1.242 1.2513 0.2428 0 0.4202-0.028 0.5883-0.0467v1.9984c-0.3081 0.0467-0.747 0.1027-1.3167 0.1027z" />
      <path d="m50.688 16.241c-2.2039 0-3.4552-1.4101-3.4552-3.754v-6.4622h2.7268v5.8926c0 1.3073 0.6163 2.0638 1.8397 2.0638 1.2233 0 1.9984-0.8965 1.9984-2.2039v-5.7524h2.7268v10.011h-2.7268v-1.6529h-0.0561c-0.5136 1.1486-1.5408 1.8583-3.0536 1.8583z" />
      <path d="m62.216 16.241c-2.5214 0-4.0716-1.961-4.0716-5.2108v-0.0093c0-3.2591 1.5502-5.2015 4.0716-5.2015 1.4194 0 2.54 0.72839 3.0816 1.9424h0.0561v-5.2015h2.7268v13.475h-2.7268v-1.7089h-0.0561c-0.5322 1.1859-1.6342 1.9143-3.0816 1.9143zm0.9151-2.2598c1.3261 0 2.2319-1.1393 2.2319-2.951v-0.0093c0-1.8023-0.9151-2.9416-2.2319-2.9416-1.354 0-2.2132 1.1113-2.2132 2.9416v0.0093c0 1.821 0.8498 2.951 2.2132 2.951z" />
      <path d="m71.532 4.7361c-0.8218 0-1.4568-0.64435-1.4568-1.4194 0-0.77508 0.635-1.4194 1.4568-1.4194s1.4568 0.64435 1.4568 1.4194c0 0.77509-0.635 1.4194-1.4568 1.4194zm-1.3634 11.299v-10.011h2.7268v10.011h-2.7268z" />
      <path d="m79.53 16.241c-3.0443 0-4.9587-1.9517-4.9587-5.2108v-0.0187c0-3.2217 1.961-5.1921 4.9493-5.1921 2.9976 0 4.968 1.9517 4.968 5.1921v0.0187c0 3.2685-1.933 5.2108-4.9586 5.2108zm0.0093-2.1384c1.3261 0 2.1758-1.1207 2.1758-3.0724v-0.0187c0-1.933-0.8684-3.0536-2.1945-3.0536-1.3167 0-2.1759 1.1206-2.1759 3.0536v0.0187c0 1.9611 0.8498 3.0724 2.1945 3.0724z" />
      {/* Make className="fill-accent" */}
      <path
        d="m89.25 0.083073c-1.2426 0-2.25 1.0074-2.25 2.25v13.5c0 1.2426 1.0074 2.25 2.25 2.25h13.5c1.243 0 2.25-1.0074 2.25-2.25v-13.5c0-1.2426-1.007-2.25-2.25-2.25h-13.5zm10.934 6.352c0 0.397-0.3255 0.72703-0.7464 0.72703s-0.7461-0.33003-0.7461-0.72703c0-0.39699 0.3252-0.72703 0.7461-0.72703s0.7464 0.33004 0.7464 0.72703zm-0.048 6.5145h-1.3967v-5.1274h1.3967v5.1274zm-7.1198 0h-1.5162l2.4106-6.9019h1.698l2.4059 6.9019h-1.5162l-0.5214-1.674h-2.4393l-0.5214 1.674zm1.7554-5.5627h-0.0287l-0.8705 2.8077h1.7697l-0.8705-2.8077z"
        clipRule="evenodd"
        fillRule="evenodd"
        className="fill-accent"
      />
    </Svg>
  );
};

export default PicStudioLogo;
