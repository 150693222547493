import { Variant, Size } from '../types/types';
import { accent, primary, secondary, tertiary } from '../states';

const getStates = (variant: Variant, size: Size) => {
  switch (variant) {
    case 'primary':
      return primary(size);
    case 'secondary':
      return secondary(size);
    case 'tertiary':
      return tertiary(size);
    case 'accent':
      return accent(size);
  }
};

export default getStates;
