import { createSlice } from '@reduxjs/toolkit';

export type UpsellType = 'credits-sd' | 'credits-dalle' | 'isPrivate' | 'x4' | 'variation' | 'upscale';
export interface UIState {
  // Key is query + provider
  isLogInModalOpen: boolean;
  restrictButtonHoveredId: string | null;
  isUpsellModalOpen: boolean;
  upsellModalType: UpsellType;
  improvePromptModalOpen: boolean;
  selectSubjectModalOpen: boolean;
  excludedStylesModalOpen: boolean;
  showNsfw: boolean;
}

const initialState: UIState = {
  isLogInModalOpen: false,
  restrictButtonHoveredId: null,
  isUpsellModalOpen: false,
  upsellModalType: 'credits-sd',
  improvePromptModalOpen: false,
  selectSubjectModalOpen: false,
  excludedStylesModalOpen: false,
  showNsfw: false
};

export const uiSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setIsLogInModalOpen: (state, action) => {
      state.isLogInModalOpen = action.payload;
    },
    setRestrictButtonHoveredId: (state, action) => {
      state.restrictButtonHoveredId = action.payload;
    },
    setIsUpsellModalOpen: (state, action: { payload: { isOpen: boolean; type?: UpsellType } }) => {
      if (action.payload.type) {
        state.upsellModalType = action.payload.type;
      }
      state.isUpsellModalOpen = action.payload.isOpen;
    },
    setImprovePromptModalOpen: (state, action) => {
      state.improvePromptModalOpen = action.payload;
    },
    setSelectSubjectModalOpen: (state, action) => {
      state.selectSubjectModalOpen = action.payload;
    },
    setExcludedStylesModalOpen: (state, action) => {
      state.excludedStylesModalOpen = action.payload;
    },
    setShowNsFw: (state, action) => {
      state.showNsfw = action.payload;
    }
  }
});

export const {
  setShowNsFw,
  setSelectSubjectModalOpen,
  setIsLogInModalOpen,
  setRestrictButtonHoveredId,
  setIsUpsellModalOpen,
  setImprovePromptModalOpen,
  setExcludedStylesModalOpen
} = uiSlice.actions;

export default uiSlice.reducer;
