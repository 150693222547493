import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { IoCheckmarkOutline, IoChevronDown } from 'react-icons/io5';

type Option<T> = {
  disabled: boolean;
  label?: string;
  value: T;
  leftContent?: JSX.Element;
  icon?: JSX.Element;
};
type Props = {
  size?: 'small' | 'medium';
  label?: string;
  inline?: boolean;
  leftContent?: JSX.Element;
  listBoxClassName?: string;
  value?: string;
  onChange: (val: string) => void;
  options: Option<string | number>[];
};

export default function ListBox({
  value,
  onChange,
  options,
  label,
  inline = false,
  size = 'small',
  leftContent,
  listBoxClassName
}: Props) {
  const selectedOption = React.useMemo(() => {
    return options.find(option => option.value === value);
  }, [options, value]);

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={clsx('flex', inline ? 'justify-between items-center' : 'flex-col gap-y-2')}>
          {size === 'small' && label && (
            <>
              <Listbox.Label className="block text-xs font-semibold text-left text-primary">{label}</Listbox.Label>
            </>
          )}
          <div className="relative flex min-w-0">
            <Listbox.Button
              className={clsx(
                'min-w-0 relative w-full cursor-default text-primary group rounded-[4px] border border-border text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm hover:cursor-pointer',
                {
                  ['ring-blue-500 ring-1']: open,
                  ['py-1.5 px-3']: size === 'small',
                  ['py-3 px-4']: size === 'medium'
                }
              )}
            >
              {leftContent && (
                <span className="pointer-events-none absolute inset-y-0 left-4 flex items-center pr-4">
                  {leftContent}
                </span>
              )}
              {size === 'medium' && (
                <Listbox.Label
                  className={clsx(
                    'min-w-0 block text-xs font-normal text-studio-400 group-focus:text-blue-400',
                    leftContent && 'pl-8'
                  )}
                >
                  {label}
                </Listbox.Label>
              )}

              <span
                className={clsx(
                  'min-w-0 w-full flex truncate text-xs  font-semibold text-left text-primary overflow-hidden',
                  {
                    ['text-xs pr-5']: size === 'small',
                    ['text-base font-light ']: size === 'medium'
                  },
                  leftContent && 'pl-8'
                )}
              >
                <div className={clsx('pr-8 overflow-hidden truncate', selectedOption?.disabled && 'opacity-50')}>
                  {selectedOption?.label || value || 'Select...'}
                  {selectedOption?.icon || null}
                </div>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <IoChevronDown
                  className={clsx('h-4 w-4 group-focus:text-blue-400', open && 'text-blue-400')}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background py-1 text-sm shadow-lg ring-1 ring-ring ring-opacity-5 focus:outline-none sm:text-sm',
                  listBoxClassName // custom classes
                )}
              >
                {options.map(option => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(
                        active && !option.disabled ? 'text-primary bg-blue-500' : 'text-primary',
                        'relative cursor-default select-none py-2 pl-8 pr-4 box-border rounded-md text-sm disabled:text-gray-500',
                        !inline && 'mx-2',
                        option.disabled && 'text-gray-500'
                      )
                    }
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {({ selected, active }) => (
                      <div className="flex gap-x-1">
                        {option.leftContent && (
                          <span className="pointer-events-none  inset-y-0 flex items-center ">
                            {option.leftContent}
                          </span>
                        )}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                            option.disabled && 'opacity-75'
                          )}
                        >
                          {option.label || option.value}
                        </span>

                        {option.icon && <span className="mt-0.5">{option.icon}</span>}

                        {selected ? (
                          <span
                            className={clsx(
                              active ? 'text-primary' : 'text-primary',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <IoCheckmarkOutline className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
