// @ts-nocheck
// TODO: Fix this file ?
import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import { trackEvent } from '@analytics/trackEvent';
import { ThemeResult } from '@components/Create/themes';
import { FeedItem } from '@components/Feed/useFeed';
import { CUSTOM_SD_MODELS, CUSTOM_SD_MODEL_SETTINGS } from '@/_data/constants';
import { addFeedItem } from '@/_data/feed/useGetFeed';
import { getParrotPrompt } from '@/_data/prompts/parrot';
import { useHasAccess } from '@hooks/features/useHasAccess';
import { useNotify } from '@hooks/index';
import { useSetQueryParam } from '@hooks/useSetQueryParam';
import { StableDiffusionEngine } from '@pages/api/prompts/create';
import { createPrompt } from '@/_service/create';
import { ClipGuidanceTypes, SamplerType } from '@/_service/stableDiffusion';
import { PromptResult } from '@store/prompt/types';
import { useCreateStyle, useGetStylesFromSubject } from '@store/styles/hooks';
import { setImprovePromptModalOpen, setIsUpsellModalOpen, setSelectSubjectModalOpen } from '@store/ui/reducer';
import { Modifier } from '@lib/getModifiers';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import * as actions from './reducer';
import getThemeById from '@/_data/themes/themes';

import {
  ActiveStylesItem,
  CanvasSize,
  ControlNetItem,
  CreateState,
  CustomModel,
  Effects,
  MidjourneyCreationStatus,
  NegativeStylesItem,
  OutputSize,
  Service
} from './types';
import { getRandomId } from '@lib/getRandomId';

export const useIsArchived = () => {
  const appDispatch = useAppDispatch();
  const isArchived = useAppSelector(state => state.create.isArchived);

  const setIsArchived = React.useCallback(
    (isArchived: boolean) => {
      appDispatch(actions.setIsArchived(isArchived));
    },
    [appDispatch]
  );

  return { isArchived, setIsArchived };
};

export const useIsPrivate = () => {
  const appDispatch = useAppDispatch();
  const isPrivate = useAppSelector(state => state.create.isPrivate);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const isPrivate = localStorage.getItem('isPrivate');
      if (isPrivate) {
        appDispatch(actions.setIsPrivate(isPrivate === 'true'));
      }
    }
  }, [appDispatch]);

  const setIsPrivate = React.useCallback(
    (isPrivate: boolean) => {
      appDispatch(actions.setIsPrivate(isPrivate));
      // Set in local storage TODO: Move this logic to redux persist
      if (typeof window !== 'undefined') {
        localStorage.setItem('isPrivate', isPrivate.toString());
      }
    },
    [appDispatch]
  );

  return { isPrivate, setIsPrivate };
};

export const usePrompt = () => {
  const appDispatch = useAppDispatch();
  const prompt = useAppSelector(state => state.create.prompt);

  const setPrompt = React.useCallback(
    (prompt: string) => {
      appDispatch(actions.setPrompt(prompt));
    },
    [appDispatch]
  );

  return { prompt, setPrompt };
};

export const useCanvas = () => {
  const appDispatch = useAppDispatch();
  const canvas = useAppSelector(state => state.create.canvas);

  const setCanvas = React.useCallback(
    (canvas: CanvasSize) => {
      appDispatch(actions.setCanvas(canvas));
    },
    [appDispatch]
  );

  return { canvas, setCanvas };
};

export const useImageCount = () => {
  const appDispatch = useAppDispatch();
  const imageCount = useAppSelector(state => state.create.imageCount);

  const canUsex4 = useHasAccess('x4');

  // read from local storage
  React.useEffect(() => {
    const imageCount = localStorage.getItem('imageCount');
    const imageCountNumber = Number(imageCount);
    if (imageCountNumber && (imageCountNumber === 1 || imageCountNumber === 4)) {
      appDispatch(actions.setImageCount(imageCountNumber));
    }
  }, [appDispatch]);

  const setImageCount = React.useCallback(
    (imageCount: number) => {
      if (imageCount > 1 && !canUsex4) {
        return appDispatch(setIsUpsellModalOpen({ isOpen: true, type: 'x4' }));
      }
      appDispatch(actions.setImageCount(imageCount));
      localStorage.setItem('imageCount', imageCount.toString());
    },
    [appDispatch, canUsex4]
  );

  return { imageCount, setImageCount };
};

const getTargetDimensions = (canvas: CanvasSize, output: OutputSize) => {
  const sizes = {
    square: { standard: [512, 512], large: [768, 768] },
    tall: { standard: [512, 768], large: [768, 1024] },
    wide: { standard: [768, 512], large: [1024, 768] },
    '1:1': { standard: [512, 512], large: [768, 768] },
    '4:5': { standard: [512, 640], large: [768, 912] },
    '3:4': { standard: [512, 684], large: [768, 1024] },
    '2:3': { standard: [512, 768], large: [768, 1024] },
    '9:16': { standard: [512, 912], large: [768, 1280] },
    '5:4': { standard: [640, 512], large: [912, 768] },
    '4:3': { standard: [684, 512], large: [1024, 768] },
    '3:2': { standard: [768, 512], large: [1024, 768] },
    '16:9': { standard: [912, 512], large: [1280, 768] }
  };

  return sizes[canvas][output];
};

export const useOutput = () => {
  const appDispatch = useAppDispatch();
  const output = useAppSelector(state => state.create.output);

  const setOutput = React.useCallback(
    (output: OutputSize) => {
      appDispatch(actions.setOutput(output));
    },
    [appDispatch]
  );

  return { output, setOutput };
};

export const getImageData = (imageSrc: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(imageSrc)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          return res.blob();
        }
        throw res;
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onload = function () {
          const dataUrl = reader.result as string;
          return resolve(dataUrl);
        };
        reader.readAsDataURL(blob);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const useReferenceImage = () => {
  const referenceImage = useAppSelector(state => state.create.controlNetItems?.[0]?.image);
  const { controlNetItems, setControlNetItems } = useControlNetItems();

  const applyReferenceImage = async (imageSrc: string): Promise<string> => {
    const imageData = await getImageData(imageSrc);
    await setReferenceImage(imageData);
    return imageData;
  };

  const setReferenceImage = React.useCallback(
    async (referenceImage?: string) => {
      if (!referenceImage) {
        const controlNetItem = controlNetItems.find(item => item.model === 'none');
        if (controlNetItem) {
          const nextControlNetItems = controlNetItems.map(item =>
            item.id === controlNetItem.id ? { ...item, image: undefined, maskImage: undefined } : item
          );
          setControlNetItems(nextControlNetItems);
        }
        return;
      }
      let newControlNetItems: ControlNetItem[] = [];
      if (controlNetItems.length === 0) {
        newControlNetItems = [
          {
            id: getRandomId(),
            model: 'none',
            enabled: true,
            image: referenceImage
          }
        ];
      } else {
        newControlNetItems = controlNetItems.map(item => {
          if (item.model === 'none') {
            return {
              ...item,
              image: referenceImage,
              maskImage: undefined
            };
          }
          return item;
        });
      }
      setControlNetItems(newControlNetItems);
    },
    [setControlNetItems, controlNetItems]
  );

  return { referenceImage, setReferenceImage, applyReferenceImage };
};

const resizeReferenceImage = async (imageUrl: string, canvas: CanvasSize, output: OutputSize) => {
  const [targetWidth, targetHeight] = getTargetDimensions(canvas, output);

  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvasElement = document.createElement('canvas');
      const ctx = canvasElement.getContext('2d');

      const width = img.width;
      const height = img.height;
      const aspectRatio = width / height;

      const targetAspectRatio = targetWidth! / targetHeight!;

      let cropWidth, cropHeight, xOffset, yOffset;

      if (aspectRatio < targetAspectRatio) {
        cropWidth = width;
        cropHeight = cropWidth / targetAspectRatio;
        xOffset = 0;
        yOffset = (height - cropHeight) / 2;
      } else {
        cropHeight = height;
        cropWidth = cropHeight * targetAspectRatio;
        xOffset = (width - cropWidth) / 2;
        yOffset = 0;
      }

      canvasElement.width = targetWidth!;
      canvasElement.height = targetHeight!;

      ctx?.drawImage(img, xOffset, yOffset, cropWidth, cropHeight, 0, 0, targetWidth!, targetHeight!);

      canvasElement.toBlob(
        blob => {
          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
          }
        },
        'image/png',
        0.8
      );
    };
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = imageUrl;
  });
};

export const useMaskImage = () => {
  const appDispatch = useAppDispatch();
  const maskImage = useAppSelector(state => state.create.maskImage);

  const setMaskImage = React.useCallback(
    (maskImage?: string) => {
      appDispatch(actions.setMaskImage(maskImage));
    },
    [appDispatch]
  );

  return { maskImage, setMaskImage };
};

export const useReferencePercentage = () => {
  const appDispatch = useAppDispatch();
  const referencePercentage = useAppSelector(state => state.create.referencePercentage);

  const setReferencePercentage = React.useCallback(
    (referencePercentage: number) => {
      appDispatch(actions.setReferencePercentage(referencePercentage));
    },
    [appDispatch]
  );

  return { referencePercentage, setReferencePercentage };
};

export const useService = () => {
  const appDispatch = useAppDispatch();
  const service = useAppSelector(state => state.create.service);

  const setService = React.useCallback(
    (service: Service) => {
      appDispatch(actions.setService(service));
    },
    [appDispatch]
  );

  return { service, setService };
};

export const useCfgScale = () => {
  const appDispatch = useAppDispatch();
  const cfgScale = useAppSelector(state => state.create.cfgScale);

  const setCfgScale = React.useCallback(
    (cfgScale: number) => {
      appDispatch(actions.setCfgScale(cfgScale));
    },
    [appDispatch]
  );

  return { cfgScale, setCfgScale };
};

export const useSteps = () => {
  const appDispatch = useAppDispatch();
  const steps = useAppSelector(state => state.create.steps);

  const setSteps = React.useCallback(
    (steps: number) => {
      appDispatch(actions.setSteps(steps));
    },
    [appDispatch]
  );

  return { steps, setSteps };
};

export const useSeed = () => {
  const appDispatch = useAppDispatch();
  const seed = useAppSelector(state => state.create.seed);

  const setSeed = React.useCallback(
    (seed?: number) => {
      appDispatch(actions.setSeed(seed));
    },
    [appDispatch]
  );

  return { seed, setSeed };
};

export const useSampler = () => {
  const appDispatch = useAppDispatch();
  const sampler = useAppSelector(state => state.create.sampler);

  const setSampler = React.useCallback(
    (sampler: SamplerType) => {
      appDispatch(actions.setSampler(sampler));
    },
    [appDispatch]
  );

  return { sampler, setSampler };
};

export const useClipGuidance = () => {
  const appDispatch = useAppDispatch();
  const clipGuidance = useAppSelector(state => state.create.clipGuidance);

  const setClipGuidance = React.useCallback(
    (clipGuidance: ClipGuidanceTypes) => {
      appDispatch(actions.setClipGuidance(clipGuidance));
    },
    [appDispatch]
  );

  return { clipGuidance, setClipGuidance };
};

export const useActiveStyles = () => {
  const appDispatch = useAppDispatch();
  const activeStyles = useAppSelector(state => state.create.activeStyles);
  const createStyle = useCreateStyle();
  const { applyReferenceImage, setReferenceImage } = useReferenceImage();
  const { customModel } = useCustomModel();

  const isTrainedModel = React.useMemo(() => {
    return customModel && customModel.includes('/');
  }, [customModel]);

  const setTheme = React.useCallback(
    async (theme?: ThemeResult, maintainAspect?: boolean) => {
      appDispatch(actions.setTheme(theme));
      const service = get(theme, 'meta.service');
      const model = get(theme, 'meta.model');
      const effects = get(theme, 'meta.effects');
      const stableDiffusionVersion = get(theme, 'meta.stableDiffusionVersion');
      const negativeModifiers = get(theme, 'negativeModifiers') as Modifier[];
      const cfgScale = get(theme, 'meta.cfgScale');
      const steps = get(theme, 'meta.steps');
      const canvas = get(theme, 'meta.canvas');
      const output = get(theme, 'meta.output');
      const sampler = get(theme, 'meta.sampler');
      const seed = get(theme, 'meta.seed');
      const referencePercentage = get(theme, 'meta.referencePercentage');
      const referenceImageUrl = get(theme, 'meta.referenceImageUrl');
      const controlNetModel = get(theme, 'meta.controlNetModel');

      let referenceImageData;

      if (referenceImageUrl) {
        console.log('CALLED FROM HERE 3');
        referenceImageData = await applyReferenceImage(referenceImageUrl);
      } else {
        setReferenceImage(undefined);
      }

      if (controlNetModel) {
        appDispatch(
          actions.setControlNetItems([
            {
              model: controlNetModel,
              isControlNetOutput: false,
              image: referenceImageData,
              settings: {
                controlnet_conditioning_scale: 1,
                detection_resolution: 512,
                canny_low_threshold: 100,
                canny_high_threshold: 200,
                mlsd_thr_v: 0.1,
                mlsd_thr_d: 0.1
              },
              enabled: true,
              id: getRandomId()
            }
          ])
        );
      }

      if (negativeModifiers && negativeModifiers.length) {
        appDispatch(
          actions.setNegativeStyles(
            negativeModifiers.map(modifier => ({
              type: 'modifier',
              id: modifier.id,
              data: modifier,
              enabled: !!modifier.enabled
            }))
          )
        );
      } else {
        appDispatch(actions.setNegativeStyles([]));
      }

      if (!isTrainedModel) {
        // @ts-ignore
        appDispatch(actions.setService(service));

        if (stableDiffusionVersion) {
          appDispatch(actions.setStableDiffusionVersion(stableDiffusionVersion));
        } else {
          appDispatch(actions.setStableDiffusionVersion('stable-diffusion-v1-5'));
        }
      }
      if (model && CUSTOM_SD_MODELS.includes(model) && !isTrainedModel) {
        appDispatch(actions.setCustomModel(model as CustomModel));
        const settings = CUSTOM_SD_MODEL_SETTINGS[model as CustomModel];
        if (settings.sampler) {
          appDispatch(actions.setSampler(settings.sampler));
        }
        if (settings?.sizeOptions?.output) {
          appDispatch(actions.setOutput(settings.sizeOptions.output));
        }
      }
      if (effects) {
        appDispatch(actions.setEffects(effects));
      }
      if (cfgScale) {
        appDispatch(actions.setCfgScale(cfgScale));
      }
      if (steps) {
        appDispatch(actions.setSteps(steps));
      }
      if (canvas && !maintainAspect) {
        appDispatch(actions.setCanvas(canvas));
      }
      if (output && !maintainAspect) {
        appDispatch(actions.setOutput(output));
      }
      if (sampler) {
        appDispatch(actions.setSampler(sampler));
      }
      if (seed) {
        appDispatch(actions.setSeed(seed));
      }
      if (referencePercentage) {
        appDispatch(actions.setReferencePercentage(referencePercentage));
      }
    },
    [appDispatch]
  );

  const setThemeByThemeId = React.useCallback(
    async (themeId: string, maintainAspect?: boolean) => {
      // Get theem
      const theme = await getThemeById(themeId);
      setTheme(theme, maintainAspect);
    },
    [setTheme]
  );

  const theme = React.useMemo(() => {
    return activeStyles.find(style => style.type === 'theme' && style.enabled)?.data as ThemeResult;
  }, [activeStyles]);

  const toggleStyle = React.useCallback(
    (style: Modifier) => {
      if (style.id.startsWith('newStyle')) {
        createStyle(style.name);
        appDispatch(actions.toggleStyle(style));
      } else {
        appDispatch(actions.toggleStyle(style));
      }
    },
    [appDispatch, createStyle]
  );

  const setActiveStyles = React.useCallback(
    (activeStyles: ActiveStylesItem[]) => {
      appDispatch(actions.setActiveStyles(activeStyles));
    },
    [appDispatch]
  );

  return { activeStyles, setActiveStyles, toggleStyle, setTheme, theme, setThemeByThemeId };
};

export const useEditingThemeStyles = () => {
  const appDispatch = useAppDispatch();
  const editingTheme = useAppSelector(state => state.create.editingTheme);

  const editingThemeModifiers = React.useMemo(() => {
    return (editingTheme?.modifiers as Modifier[]) ?? [];
  }, [editingTheme?.modifiers]);

  const theme = React.useMemo(() => {
    return editingThemeModifiers.find(style => style.type === 'theme' && style.enabled)?.data as ThemeResult;
  }, [editingThemeModifiers]);

  const editingThemeActiveStyles: ActiveStylesItem[] = editingThemeModifiers.map(modifier => {
    if (modifier.type === 'modifier') {
      return {
        type: 'modifier',
        data: modifier,
        id: modifier.id,
        enabled: !!modifier.enabled
      };
    } else if (modifier.type === 'theme') {
      return {
        type: 'theme',
        data: modifier.data,
        id: modifier.id,
        enabled: !!modifier.enabled
      };
    } else {
      return {
        type: 'subject',
        data: 'subject',
        id: 'subject',
        enabled: true,
        weight: modifier.weight || '100%'
      };
    }
  });

  const toggleStyle = React.useCallback(
    (style: Modifier) => {
      appDispatch(actions.toggleEditingThemeStyle(style));
    },
    [appDispatch]
  );

  const setActiveStyles = React.useCallback(
    (activeStyles: ActiveStylesItem[]) => {
      appDispatch(actions.setEditingThemeStyles(activeStyles));
    },
    [appDispatch]
  );

  return { activeStyles: editingThemeActiveStyles, setActiveStyles, toggleStyle, theme };
};

export const useEditingThemeNegativeStyles = () => {
  const appDispatch = useAppDispatch();
  const editingTheme = useAppSelector(state => state.create.editingTheme);

  const editingThemeNegativeModifiers = React.useMemo(() => {
    return (editingTheme?.negativeModifiers as Modifier[]) ?? [];
  }, [editingTheme?.negativeModifiers]);

  const editingThemeActiveNegativeStyles: NegativeStylesItem[] = editingThemeNegativeModifiers.map(modifier => {
    return {
      type: 'modifier',
      data: modifier,
      id: modifier.id,
      enabled: !!modifier.enabled
    };
  });

  const toggleNegativeStyle = React.useCallback(
    (style: Modifier) => {
      appDispatch(actions.toggleEditingThemeNegativeStyle(style));
    },
    [appDispatch]
  );

  const setActiveNegativeStyles = React.useCallback(
    (activeStyles: NegativeStylesItem[]) => {
      appDispatch(actions.setEditingThemeNegativeStyles(activeStyles));
    },
    [appDispatch]
  );

  return {
    activeNegativeStyles: editingThemeActiveNegativeStyles,
    setActiveNegativeStyles,
    toggleNegativeStyle
  };
};

export const useEditingThemeEffects = () => {
  const appDispatch = useAppDispatch();
  const editingTheme = useAppSelector(state => state.create.editingTheme);

  const effects = React.useMemo(() => {
    return get(editingTheme, 'meta.effects', undefined) as Effects | undefined;
  }, [editingTheme]);

  const meta = React.useMemo(() => {
    return get(editingTheme, 'meta', {}) as { [key: string]: any };
  }, [editingTheme]);

  const setEffects = React.useCallback(
    (effects?: Effects) => {
      const nextMeta = { ...meta, effects };
      appDispatch(actions.setEditingThemeParams({ meta: nextMeta as any }));
    },
    [appDispatch, meta]
  );

  return { effects, setEffects };
};

export const useCreateState = () => {
  const appDispatch = useAppDispatch();
  const createState = useAppSelector(state => state.create);

  const setCreateState = React.useCallback(
    (createState: Partial<CreateState>) => {
      appDispatch(actions.setState(createState));
    },
    [appDispatch]
  );

  return { createState, setCreateState };
};

export const useEditingTheme = () => {
  const appDispatch = useAppDispatch();
  const editingTheme = useAppSelector(state => state.create.editingTheme);
  const setEditingThemeId = useSetQueryParam('editingThemeId');
  const { applyReferenceImage, setReferenceImage } = useReferenceImage();
  const { setControlNetItems } = useControlNetItems();
  const { setReferencePercentage } = useReferencePercentage();
  const { setSteps } = useSteps();

  const setEditingTheme = React.useCallback(
    async (editingTheme?: ThemeResult) => {
      appDispatch(actions.setEditingTheme(editingTheme));
      if (editingTheme?.id) {
        setEditingThemeId(editingTheme?.id);
      }
      // @ts-ignore
      const refUrl = editingTheme?.meta?.referenceImageUrl;
      if (refUrl) {
        const controlNetModel = get(editingTheme, 'meta.controlNetModel');
        if (controlNetModel && controlNetModel !== 'none') {
          if (controlNetModel) {
            const referenceImageData = await getImageData(refUrl);
            appDispatch(
              actions.setControlNetItems([
                {
                  model: controlNetModel as any,
                  isControlNetOutput: false,
                  image: referenceImageData,
                  settings: {
                    controlnet_conditioning_scale: 1,
                    detection_resolution: 512,
                    canny_low_threshold: 100,
                    canny_high_threshold: 200,
                    mlsd_thr_v: 0.1,
                    mlsd_thr_d: 0.1
                  },
                  enabled: true,
                  id: getRandomId()
                }
              ])
            );
          }
        } else {
          applyReferenceImage(refUrl);
          // @ts-ignore
          setReferencePercentage(editingTheme?.meta?.referencePercentage || 15);
        }
      } else {
        setReferenceImage(undefined);
        setControlNetItems([]);
      }
      // @ts-ignore
      if (typeof editingTheme?.meta?.steps === 'number') {
        // @ts-ignore
        setSteps(editingTheme?.meta?.steps);
      }
    },
    [
      appDispatch,
      applyReferenceImage,
      setControlNetItems,
      setEditingThemeId,
      setReferenceImage,
      setReferencePercentage,
      setSteps
    ]
  );

  const setEditingThemeParams = React.useCallback(
    (editingThemeParams: Partial<ThemeResult>) => {
      appDispatch(actions.setEditingThemeParams(editingThemeParams));
    },
    [appDispatch]
  );

  return { editingTheme, setEditingTheme, setEditingThemeParams };
};

export function getOutputResolution(canvas: CanvasSize, outOption: OutputSize, service?: Service) {
  if (service !== 'DALL·E') {
    switch (canvas) {
      case 'square':
        return outOption === 'standard' ? { width: 512, height: 512 } : { width: 768, height: 768 };
      case 'tall':
        return outOption === 'standard' ? { width: 512, height: 768 } : { width: 768, height: 1024 };
      case 'wide':
        return outOption === 'standard' ? { width: 768, height: 512 } : { width: 1024, height: 768 };
    }
  } else {
    return outOption === 'standard' ? { width: 512, height: 512 } : { width: 1024, height: 1024 };
  }
}

export const useCreate = () => {
  const { createState } = useCreateState();
  const { activeStyles: editingActiveStyles } = useEditingThemeStyles();
  const appDispatch = useAppDispatch();
  const { editingTheme } = useEditingTheme();
  const notify = useNotify();
  const { effects } = useEffects();
  const router = useRouter();

  const effectsToUse = React.useMemo(() => {
    // @ts-ignore
    if (editingTheme?.meta) return editingTheme.meta.effects as Effects | undefined;

    return effects;
  }, [editingTheme.meta, effects]);

  const activeStyles = React.useMemo(() => {
    if (createState.editingTheme.id) return editingActiveStyles;
    return createState.activeStyles;
  }, [createState.activeStyles, createState.editingTheme.id, editingActiveStyles]);

  const enabledStyles = React.useMemo(() => {
    return activeStyles.filter(style => style.enabled);
  }, [activeStyles]);

  const getThemeModifiers = (theme: ThemeResult) => {
    const themeModifiers = theme.modifiers as Modifier[];
    const enabledThemeModifiers = themeModifiers.filter(modifier => modifier.enabled || modifier.type === 'subject');
    return enabledThemeModifiers;
  };

  const theme = React.useMemo(() => {
    return enabledStyles.find(style => style.type === 'theme')?.data as ThemeResult | undefined;
  }, [enabledStyles]);

  const pixelArt = React.useMemo(() => {
    // @ts-ignore
    if (editingTheme?.meta?.pixelArt) return editingTheme.meta.pixelArt;
    // @ts-ignore
    return theme?.meta?.pixelArt;
  }, [theme?.meta, editingTheme?.meta]);

  const subTheme = React.useMemo(() => {
    if (!theme) return undefined;
    const modifiers = getThemeModifiers(theme);
    const subTheme = modifiers.find(modifier => modifier.type === 'theme')?.data as ThemeResult;
    return subTheme;
  }, [theme]);

  const allActiveStyles = React.useMemo(() => {
    return enabledStyles
      .map(item => {
        switch (item.type) {
          case 'modifier':
            const modifier = item.data as Modifier;
            return modifier;

          case 'theme':
            let modifiers = getThemeModifiers(item.data as ThemeResult);

            if (subTheme) {
              modifiers = modifiers.filter(modifier => modifier.type !== 'subject');
              const subThemeIndex = modifiers.findIndex(modifier => modifier.type === 'theme');
              const subTheme = modifiers[subThemeIndex]!.data as ThemeResult;
              const subThemeModifiers = getThemeModifiers(subTheme);
              modifiers.splice(subThemeIndex, 1, ...subThemeModifiers);
            }
            return modifiers;

          case 'subject':
            if (!theme)
              return {
                type: 'subject',
                data: 'subject',
                id: 'subject',
                enabled: true,
                weight: item.weight
              };

          default:
            return undefined;
        }
      })
      .filter(m => m)
      .flat() as Modifier[];
  }, [enabledStyles, subTheme, theme]);

  const prompt = React.useMemo(() => {
    const promptItems: string[] = [];

    allActiveStyles.forEach(style => {
      if (!style) return;
      if (style.type === 'subject') {
        if (createState.prompt.length > 0) {
          promptItems.push(createState.prompt);
        }
      } else {
        promptItems.push(style.name);
      }
    });

    return promptItems.join(', ');
  }, [allActiveStyles, createState.prompt]);

  const subjectWeight = React.useMemo(() => {
    const subjectModifier = allActiveStyles.find(modifier => modifier.type === 'subject');
    if (subjectModifier) {
      return subjectModifier.weight;
    }
    return '100%';
  }, [allActiveStyles]);

  const setIsCreating = React.useCallback(
    (isCreating: boolean) => {
      appDispatch(actions.setIsCreating(isCreating));
    },
    [appDispatch]
  );

  const handleError = React.useCallback(
    (error: any) => {
      setIsCreating(false);

      if (error.message === 'Request too large.') {
        notify('Request too large.', 'error');
      } else if (error.message === 'Request timed out. Please try again.') {
        trackEvent(AnalyticsEvents.party);
        notify('Our servers are partying! Try again shortly.', 'error');
      } else if (error.message === 'access denied') {
        notify('access denied, please contact support', 'error');
      } else if (error.message === 'Plaese keep NSFW creations in private.') {
        notify('Plaese keep NSFW creations in private.', 'error');
      } else if (error.message === 'requests exceeds daily max limit') {
        appDispatch(
          setIsUpsellModalOpen({
            isOpen: true,
            type: createState.service === 'Stable Diffusion' ? 'credits-sd' : 'credits-dalle'
          })
        );
      } else {
        notify(error.message, 'error');
      }
    },
    [setIsCreating, notify, appDispatch, createState.service]
  );

  const reusePromptId = router.query.promptId;

  const create = React.useCallback(
    async (dontCheckPrompt?: boolean, beta?: boolean) => {
      // If no styles and no commas in prompt, trigger improve prompt modal
      if (!dontCheckPrompt) {
        const numStyles = allActiveStyles.filter(style => style.type !== 'subject').length;
        const numCommas = (createState.prompt.match(/,/g) || []).length;
        const hasTheme = !!theme;
        // Has more then one string when splitted by comma and at least 2 strings are not empty
        const hasTwoStyles = createState.prompt.split(',').filter(s => s.trim().length > 0).length > 1;
        if (numStyles === 0 && numCommas === 0 && !hasTheme) {
          appDispatch(setImprovePromptModalOpen(true));
          return;
        }
        if (numStyles === 0 && hasTwoStyles && !hasTheme) {
          appDispatch(setSelectSubjectModalOpen(true));
          return;
        }
      }

      const controlNetItems = createState.controlNetItems || [];
      // Resize images, keep original order
      const resizedControlNetItems = await Promise.all(
        controlNetItems.map(async item => {
          if (item.image) {
            const resizedImage = await resizeReferenceImage(item.image, createState.canvas, createState.output);
            // remove isControlNetOutput from item
            const { isControlNetOutput, ...rest } = item;
            return {
              ...rest,
              image: resizedImage
            };
          } else {
            return item;
          }
        })
      );

      // resize reference image
      let resizedReferenceImage;
      if (createState.referenceImage) {
        resizedReferenceImage = await resizeReferenceImage(
          createState.referenceImage,
          createState.canvas,
          createState.output
        );
      }

      // resize mask image
      let resizedMaskImage;
      if (createState.maskImage) {
        resizedMaskImage = await resizeReferenceImage(createState.maskImage, createState.canvas, createState.output);
      }

      // promptId from current url
      try {
        setIsCreating(true);
        const result: FeedItem = await createPrompt({
          providerName: createState.service,
          prompt,
          subject: createState.prompt,
          subjectWeight: subjectWeight,
          width: getOutputResolution(createState.canvas, createState.output)?.width,
          height: getOutputResolution(createState.canvas, createState.output)?.height,
          seed: createState.seed,
          outputCount: createState.imageCount,
          inferenceSteps: createState.steps,
          cfgScale: createState.cfgScale,
          initImageData: resizedReferenceImage,
          maskImageData: resizedMaskImage,
          sampler: createState.sampler,
          isArchived: createState.isArchived,
          referencePercentage: createState.referencePercentage,
          themeId: editingTheme?.id && editingTheme?.id !== 'new' ? editingTheme.id : theme?.id,
          modifiers: allActiveStyles.filter(style => style.type !== 'subject'),
          engine: createState.stableDiffusionVersion,
          clipGuidance: createState.clipGuidance,
          negativeStyles: createState.negativeStyles,
          model: createState.customModel,
          effects: effectsToUse,
          pixelArt,
          isPrivate: createState.isPrivate,
          reusePromptId,
          controlNetItems: resizedControlNetItems,
          beta
        });

        appDispatch(actions.setQueuePosition({ position: 0, totalTimeEstimate: 0 }));

        // Add item to feed
        addFeedItem(result);
        // Increment visible items
        appDispatch(actions.incrementVisibleItems());
        setIsCreating(false);
        return result;
      } catch (e) {
        handleError(e);
      }
    },
    [
      allActiveStyles,
      createState.prompt,
      createState.service,
      createState.canvas,
      createState.output,
      createState.seed,
      createState.imageCount,
      createState.steps,
      createState.cfgScale,
      createState.referenceImage,
      createState.maskImage,
      createState.sampler,
      createState.isArchived,
      createState.referencePercentage,
      createState.stableDiffusionVersion,
      createState.clipGuidance,
      createState.negativeStyles,
      createState.customModel,
      createState.isPrivate,
      createState.controlNetItems,
      theme,
      appDispatch,
      setIsCreating,
      prompt,
      subjectWeight,
      editingTheme.id,
      effectsToUse,
      pixelArt,
      reusePromptId,
      handleError
    ]
  );

  return { create, isCreating: createState.isCreating, setIsCreating };
};

export const useIsCreating = () => {
  const { createState } = useCreateState();
  return createState.isCreating;
};

export const useStableDiffusionVersion = () => {
  const appDispatch = useAppDispatch();
  const stableDiffusionVersion = useAppSelector(state => state.create.stableDiffusionVersion);

  const setStableDiffusionVersion = React.useCallback(
    (stableDiffusionVersion: StableDiffusionEngine) => {
      appDispatch(actions.setStableDiffusionVersion(stableDiffusionVersion));
      // Set default size
      if (stableDiffusionVersion === 'stable-diffusion-v1-5') {
        appDispatch(actions.setOutput('standard'));
      } else {
        appDispatch(actions.setOutput('large'));
      }
    },
    [appDispatch]
  );

  return { stableDiffusionVersion, setStableDiffusionVersion };
};

export const useCustomModel = () => {
  const appDispatch = useAppDispatch();
  const customModel = useAppSelector(state => state.create.customModel);

  const setCustomModel = React.useCallback(
    (customModel: CustomModel) => {
      appDispatch(actions.setCustomModel(customModel));
      let modelSettings = CUSTOM_SD_MODEL_SETTINGS[customModel];

      if (!modelSettings) {
        // Its trained model
        modelSettings = {
          checkpoint: customModel,
          stableDiffusionVersion: '1.5'
        };
      }

      if (modelSettings.sampler) {
        appDispatch(actions.setSampler(modelSettings.sampler));
      }
      if (modelSettings.sizeOptions?.output) {
        appDispatch(actions.setOutput(modelSettings.sizeOptions.output));
      }
    },
    [appDispatch]
  );

  return { customModel, setCustomModel };
};

export const useEffects = () => {
  const appDispatch = useAppDispatch();
  const effects = useAppSelector(state => state.create.effects);

  const setEffects = React.useCallback(
    (effects: Effects) => {
      appDispatch(actions.setEffects(effects));
    },
    [appDispatch]
  );

  return { effects, setEffects };
};

export const useResetState = () => {
  const appDispatch = useAppDispatch();

  const resetState = React.useCallback(() => {
    appDispatch(actions.resetState());
  }, [appDispatch]);

  return resetState;
};

export const usePrompts = () => {
  const appDispatch = useAppDispatch();

  const prompts = useAppSelector(state => state.create.prompts);

  const setPrompts = React.useCallback(
    (prompts: PromptResult[]) => {
      appDispatch(actions.setPrompts(prompts));
    },
    [appDispatch]
  );

  const unShiftPrompt = React.useCallback(
    (prompt: PromptResult) => {
      appDispatch(actions.unShiftPrompt(prompt));
    },
    [appDispatch]
  );

  return { prompts, setPrompts, unShiftPrompt };
};

export const useMidjourneyCreationStatus = () => {
  const appDispatch = useAppDispatch();

  const midjourneyCreationStatus = useAppSelector(state => state.create.midjourneyCreationStatus);

  const setMidjourneyCreationStatus = React.useCallback(
    (midjourneyCreationStatus: MidjourneyCreationStatus) => {
      appDispatch(actions.setMidjourneyCreationStatus(midjourneyCreationStatus));
    },
    [appDispatch]
  );

  return { midjourneyCreationStatus, setMidjourneyCreationStatus };
};

export const useNegativeStyles = () => {
  const appDispatch = useAppDispatch();

  const negativeStyles = useAppSelector(state => state.create.negativeStyles);

  const setNegativeStyles = React.useCallback(
    (negativeStyles: NegativeStylesItem[]) => {
      appDispatch(actions.setNegativeStyles(negativeStyles));
    },
    [appDispatch]
  );

  return { negativeStyles, setNegativeStyles };
};

export const useEnhanceCreation = () => {
  // Split prompt with a comma
  // Use first as prompt and add rest as styles

  const { prompt } = usePrompt();
  const { activeStyles, setActiveStyles } = useActiveStyles();
  const getStylesFromSubject = useGetStylesFromSubject();

  const enhanceCreation = React.useCallback(async () => {
    const parrotPrompt = await getParrotPrompt(prompt);
    const stylesFromParrot = getStylesFromSubject(parrotPrompt) as Modifier[];
    const nextActiveStyles: ActiveStylesItem[] = [];
    stylesFromParrot.slice(1).forEach(style => {
      nextActiveStyles.push({
        type: 'modifier',
        data: style,
        id: style.id,
        enabled: true
      });
    });

    setActiveStyles([...activeStyles, ...nextActiveStyles]);
  }, [prompt, getStylesFromSubject, setActiveStyles, activeStyles]);

  return enhanceCreation;
};

export const useVisibleItems = () => {
  const visibleItems = useAppSelector(state => state.create.visibleItems);
  const appDispatch = useAppDispatch();

  const setVisibleItems = React.useCallback(
    (visibleItems: number) => {
      appDispatch(actions.setVisibleItems(visibleItems));
    },
    [appDispatch]
  );

  const incrementVisibleItems = React.useCallback(() => {
    appDispatch(actions.incrementVisibleItems());
  }, [appDispatch]);

  return { visibleItems, setVisibleItems, incrementVisibleItems };
};

export const useRemoveTheme = (themeId: string) => {
  const { activeStyles, setActiveStyles } = useActiveStyles();

  return React.useCallback(() => {
    const nextActiveStyles = activeStyles.filter(style => style.id !== themeId);
    setActiveStyles(nextActiveStyles);
  }, [activeStyles, setActiveStyles, themeId]);
};

export const useIsLoadingSubject = () => {
  const isLoadingSubject = useAppSelector(state => state.create.isLoadingSubject);
  const appDispatch = useAppDispatch();

  const setIsLoadingSubject = React.useCallback(
    (isLoadingSubject: boolean) => {
      appDispatch(actions.setIsLoadingSubject(isLoadingSubject));
    },
    [appDispatch]
  );

  return { isLoadingSubject, setIsLoadingSubject };
};

export const useControlNetItems = () => {
  const appDispatch = useAppDispatch();
  const controlNetItems = useAppSelector(state => state.create.controlNetItems);

  const hasReferenceImage = React.useMemo(() => {
    return controlNetItems?.some(item => item.model === 'none');
  }, [controlNetItems]);

  const setControlNetItems = React.useCallback(
    (controlNetItems: ControlNetItem[]) => {
      appDispatch(actions.setControlNetItems(controlNetItems));
    },
    [appDispatch]
  );

  return { controlNetItems: controlNetItems || [], setControlNetItems, hasReferenceImage };
};

export const useQueuePosition = () => {
  const appDispatch = useAppDispatch();
  const queuePosition = useAppSelector(state => state.create.queuePosition);

  type QueuePositionType = typeof queuePosition;

  const setQueuePosition = React.useCallback(
    (queuePosition: QueuePositionType) => {
      appDispatch(actions.setQueuePosition(queuePosition));
    },
    [appDispatch]
  );

  return { queuePosition, setQueuePosition };
};
