export const FLUX_LORA_FACE_IMG2IMG_FACE_DETAILER_UPSCALE = {
  '5': {
    inputs: {
      width: ['135', 0],
      height: ['136', 0],
      batch_size: 1
    },
    class_type: 'EmptyLatentImage',
    _meta: {
      title: 'Empty Latent Image'
    }
  },
  '6': {
    inputs: {
      text: ['139', 0],
      clip: ['11', 0]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '10': {
    inputs: {
      vae_name: 'ae.safetensors'
    },
    class_type: 'VAELoader',
    _meta: {
      title: 'Load VAE'
    }
  },
  '11': {
    inputs: {
      clip_name1: 't5xxl_fp16.safetensors',
      clip_name2: 'clip_l.safetensors',
      type: 'flux'
    },
    class_type: 'DualCLIPLoader',
    _meta: {
      title: 'DualCLIPLoader'
    }
  },
  '12': {
    inputs: {
      unet_name: 'flux1-dev.safetensors',
      weight_dtype: 'fp8_e4m3fn'
    },
    class_type: 'UNETLoader',
    _meta: {
      title: 'Load Diffusion Model'
    }
  },
  '61': {
    inputs: {
      max_shift: 1.1500000000000001,
      base_shift: 0.5,
      width: ['135', 0],
      height: ['136', 0],
      model: ['156', 0]
    },
    class_type: 'ModelSamplingFlux',
    _meta: {
      title: 'ModelSamplingFlux'
    }
  },
  '74': {
    inputs: {
      lora_id: 'clzuvzjbo0001lmrq74idnvob',
      lora_name: 'geopx-08f72858-b1e9-4f04-83fe-d22757920526.safetensors',
      strength_model: 1,
      model: ['12', 0]
    },
    class_type: 'LoraLoaderModelOnlyFromVolumePicstudio',
    _meta: {
      title: 'LoraLoaderModelOnlyFromVolumePicstudio'
    }
  },
  '102': {
    inputs: {
      text: '',
      clip: ['11', 0]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '108': {
    inputs: {
      model: ['74', 0],
      clip: ['11', 0],
      vae: ['10', 0],
      positive: ['6', 0],
      negative: ['118', 0]
    },
    class_type: 'Bus Node',
    _meta: {
      title: 'Bus Node'
    }
  },
  '117': {
    inputs: {
      seed: ['137', 0],
      steps: 25,
      cfg: 1,
      sampler_name: 'euler',
      scheduler: 'simple',
      denoise: 1,
      model: ['108', 1],
      positive: ['108', 4],
      negative: ['108', 5],
      latent_image: ['5', 0]
    },
    class_type: 'KSampler',
    _meta: {
      title: 'KSampler'
    }
  },
  '118': {
    inputs: {
      conditioning: ['102', 0]
    },
    class_type: 'ConditioningZeroOut',
    _meta: {
      title: 'ConditioningZeroOut'
    }
  },
  '119': {
    inputs: {
      samples: ['117', 0],
      vae: ['108', 3]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '131': {
    inputs: {
      filename_prefix: '329867352_811645293',
      images: ['119', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '135': {
    inputs: {
      input_id: 'width',
      default_value: 832
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '136': {
    inputs: {
      input_id: 'height',
      default_value: 1216
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '137': {
    inputs: {
      input_id: 'seed',
      default_value: 229344148
    },
    class_type: 'ExternalNumberIntPicstudio',
    _meta: {
      title: 'ExternalNumberIntPicstudio'
    }
  },
  '139': {
    inputs: {
      input_id: 'prompt',
      default_value:
        "Cinematic wide angle IMAX film still of a movie by Wes Craven. In the shot is a dimly lit room with neon lighting. In the foreground is TOK woman . They're wearing a light-colored shirt, looking intensely at the camera with a somewhat troubled expression. The lighting creates a stark contrast between cool blue/green tones and warm orange/red hues, giving the scene a moody, atmospheric quality. This appears to be a still from a film or TV show, possibly in the thriller or drama genre., "
    },
    class_type: 'ExternalTextPicstudio',
    _meta: {
      title: 'ExternalTextPicstudio'
    }
  },
  '143': {
    inputs: {
      model_name: '4x-UltraSharp.pth'
    },
    class_type: 'UpscaleModelLoader',
    _meta: {
      title: 'Load Upscale Model'
    }
  },
  '146': {
    inputs: {
      noise: ['149', 0],
      guider: ['150', 0],
      sampler: ['152', 0],
      sigmas: ['153', 0],
      latent_image: ['148', 0]
    },
    class_type: 'SamplerCustomAdvanced',
    _meta: {
      title: 'SamplerCustomAdvanced'
    }
  },
  '148': {
    inputs: {
      pixels: ['119', 0],
      vae: ['10', 0]
    },
    class_type: 'VAEEncode',
    _meta: {
      title: 'VAE Encode'
    }
  },
  '149': {
    inputs: {
      noise_seed: 804210966951871
    },
    class_type: 'RandomNoise',
    _meta: {
      title: 'RandomNoise'
    }
  },
  '150': {
    inputs: {
      model: ['61', 0],
      conditioning: ['151', 0]
    },
    class_type: 'BasicGuider',
    _meta: {
      title: 'BasicGuider'
    }
  },
  '151': {
    inputs: {
      guidance: 3.5,
      conditioning: ['6', 0]
    },
    class_type: 'FluxGuidance',
    _meta: {
      title: 'FluxGuidance'
    }
  },
  '152': {
    inputs: {
      sampler_name: 'euler'
    },
    class_type: 'KSamplerSelect',
    _meta: {
      title: 'KSamplerSelect'
    }
  },
  '153': {
    inputs: {
      scheduler: 'simple',
      steps: 25,
      denoise: 0.65,
      model: ['61', 0]
    },
    class_type: 'BasicScheduler',
    _meta: {
      title: 'BasicScheduler'
    }
  },
  '154': {
    inputs: {
      samples: ['146', 0],
      vae: ['10', 0]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '156': {
    inputs: {
      lora_id: 'clzuvzjbo0001lmrq74idnvob',
      lora_name: 'geopx-08f72858-b1e9-4f04-83fe-d22757920526.safetensors',
      strength_model: 1,
      model: ['12', 0]
    },
    class_type: 'LoraLoaderModelOnlyFromVolumePicstudio',
    _meta: {
      title: 'LoraLoaderModelOnlyFromVolumePicstudio'
    }
  },
  '157': {
    inputs: {
      filename_prefix: 'ComfyUI',
      images: ['154', 0]
    },
    class_type: 'SaveImage',
    _meta: {
      title: 'Save Image'
    }
  }
};
