import { compose, style } from 'styled-system';

export const stroke = style({
  // React prop name
  prop: 'stroke',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'stroke',
  // key for theme values
  key: 'colors'
});

export const fill = style({
  prop: 'fill',
  cssProperty: 'fill',
  key: 'colors'
});

const iconHeight = style({
  prop: 'iconSize',
  cssProperty: 'width',
  key: 'iconSizes'
});

const iconWidth = style({
  prop: 'iconSize',
  cssProperty: 'height',
  key: 'iconSizes'
});

export const iconSize = compose(iconHeight, iconWidth);
