export const CONTENTFUL_THEME_LIST = [
  {
    title: 'Featured',
    id: '5IAsd9wKxEltGfHVLe143A'
  },
  {
    title: 'Simple',
    id: '1WDJCydfm89DUS3VBjNg27'
  },
  {
    title: 'Professions',
    id: '6Q1L6JJSWhbok8EDrHoVbz'
  },
  {
    title: 'Locations',
    id: '2Mm4Dwv0XeBNoIhxXFi61l'
  },
  {
    title: 'Fashion',
    id: '5VOk6sQbfdqy6CB0ssAmvY'
  },
  {
    title: 'Travel',
    id: '2RzUoOkr8RB2Kg593sBYGL'
  },
  {
    title: 'Creative and fun',
    id: '4LN2bbpUkI7bJbW8XuUblB'
  }
  // {
  //   title: 'Locations',
  //   id: '9ebuBX6Y0N6BgtN8r1hd3'
  // }
];
