import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';

export type TButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-active' | 'accent' | 'href' | 'ghost';
  size?: 'small' | 'medium' | 'large' | 'icon';
  state?: 'default' | 'lg:hover' | 'focus' | 'disabled' | 'loading' | 'active';
  resize?: 'hug' | 'fill';
  icon?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
  className?: string;
  noBackground?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  as?: 'div' | 'button';
};

function getBorder(variant: TButtonProps['variant']) {
  switch (variant) {
    case 'primary':
      return 'border border-transparent';
    case 'secondary':
      return 'border border-transparent';
    case 'tertiary':
      return 'border border-border hover:border-primary/20 focus:border-accent';

    case 'tertiary-active':
      return 'border border-border lg:hover:border-accent';

    case 'href':
      return 'ring-inset border border-transparent focus:ring-none focus:outline-none focus:border-transparent';
    case 'accent':
      return 'border border-transparent';
    case 'ghost':
      return 'border border-primary/0 border-opacity-0';
  }
}

function getBgColor(variant: TButtonProps['variant'], noBackground?: boolean) {
  if (noBackground) {
    return '';
  }
  switch (variant) {
    case 'primary':
      return 'bg-primary lg:hover:bg-primary/80 focus:bg-primary/80';
    case 'secondary':
      return 'bg-background  lg:hover:bg-muted';
    case 'tertiary':
      return 'bg-transparent lg:hover:bg-muted focus:lg:hover:bg-studio-700/20';
    case 'tertiary-active':
      return 'bg-blue-500/20 lg:hover:bg-blue-500/20';
    case 'href':
      return 'lg:hover:underline focus:underline';
    case 'accent':
      return 'bg-accent text-accent-foreground disabled:lg:hover:bg-accent lg:hover:opacity-90 disabled:bg-accent focus:ring focus:ring-ring focus:ring-offset-4 focus:ring-offset-background';
    case 'ghost':
      return 'bg-transparent lg:hover:bg-muted focus:lg:hover:bg-muted';
  }
}

function getSizeClasses(size: TButtonProps['size']) {
  switch (size) {
    case 'small':
      return 'h-[32px] text-xs font-semibold px-3 py-1.5';
    case 'medium':
      return 'h-[32px] text-sm font-semibold px-4 py-2';
    case 'large':
      return 'h-[50px] text-base font-semibold px-4 py-3';
    case 'icon':
      return 'h-[32px] text-xs font-semibold aspect-square';
  }
}

function getResizeClasses(resize: TButtonProps['resize']) {
  switch (resize) {
    case 'hug':
      return 'flex';
    case 'fill':
      return 'flex grow shrink';
  }
}

function getState(state: TButtonProps['state']) {
  switch (state) {
    case 'default':
      // IF VARIANT=PRIMARY
      return '';
      // IF VARIANT=SECONDARY
      return '';
    case 'lg:hover':
      return '';
    case 'focus':
      return '';
    case 'disabled':
      return '';
    case 'loading':
      return '';
    case 'active':
      return 'ring-2 ring-inset ring-blue-500 bg-blue-500/20';
  }
}

function getActivityIndicatorSize(size: TButtonProps['size']) {
  switch (size) {
    case 'small':
      return { width: 16, height: 16 };
    case 'medium':
      return { width: 18, height: 18 };
    case 'large':
      return { width: 20, height: 20 };
    case 'icon':
      return { width: 22, height: 22 };
    default:
      return { width: 18, height: 18 };
  }
}

const DivContainer = ({ children, ...rest }: any) => <div {...rest}>{children}</div>;
const ButtonContainer = ({ children, ...rest }: any) => <button {...rest}>{children}</button>;

export default function TButton({
  variant = 'primary',
  size = 'medium',
  resize = 'hug',
  state = 'default',
  children,
  icon,
  className,
  isLoading = false,
  noBackground = false,
  rounded = false,
  as = 'button',
  ...rest
}: TButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) {
  const ContainerElement = React.useMemo(() => {
    if (as === 'div') {
      return DivContainer;
    } else {
      return ButtonContainer;
    }
  }, [as]);

  return (
    <ContainerElement
      type="button"
      className={clsx(
        'focus:ring-accent flex gap-2 disabled:text-opacity-30 justify-center items-center group sentence-case outline-none focus:outline-0',
        variant !== 'primary' && !isLoading && 'text-primary',
        variant == 'primary' && !isLoading && 'text-primary-foreground',
        isLoading && 'text-red-500/0', // Set the text opacity to 0 when isLoading is true
        getBgColor(variant, noBackground),
        getSizeClasses(size),
        getResizeClasses(resize),
        getBorder(variant),
        getState(state),
        `${
          rounded
            ? 'rounded-full'
            : size === 'small' || size === 'icon'
            ? 'rounded-[4px]'
            : size === 'medium'
            ? 'rounded-md'
            : size === 'large'
            ? 'rounded-lg'
            : ''
        }`,
        className
      )}
      {...rest}
    >
      {icon} {children}
      {isLoading && (
        <div className="absolute flex justify-center items-center">
          <ActivityIndicator
            className={variant !== 'primary' ? 'text-accent-foreground' : 'text-muted-foreground'}
            width={getActivityIndicatorSize(size).width}
            height={getActivityIndicatorSize(size).height}
          />
        </div>
      )}
    </ContainerElement>
  );
}
