import * as React from 'react';
import { useSession } from './auth/useSession';

export const useIsOwnPrompt = ({
  promptUserId,
  skipAdminCheck
}: {
  promptUserId?: string;
  skipAdminCheck?: boolean;
}) => {
  const { user } = useSession();

  return React.useMemo(() => {
    return (!skipAdminCheck && user?.isAdmin) || ((user?.id && promptUserId === user?.id) as boolean);
  }, [promptUserId, skipAdminCheck, user?.id, user?.isAdmin]);
};
