import styled from 'styled-components';
import { gridColumn, gridRow, gridArea, GridColumnProps, GridRowProps, GridAreaProps } from 'styled-system';
import { Box } from '..';

export type ItemProps = React.ComponentProps<typeof Box> & GridColumnProps & GridRowProps & GridAreaProps;

export const GridItem = styled(Box)<ItemProps>`
  ${gridColumn};
  ${gridRow};
  ${gridArea};
`;
