export const PHOTO_STATUS = {
  queued: 'queued',
  generating: 'generating',
  ready: 'ready',
  retrying: 'retrying',
  failed: 'failed',
  upscaling: 'upscaling',
  upscaled: 'upscaled'
} as const;

export type PhotoStatus = 'queued' | 'generating' | 'ready' | 'retrying' | 'failed' | 'upscaling' | 'upscaled' | (string & {});
