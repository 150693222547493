import { getBaseAPIHost } from '@/_data/getBaseAPIHost';
import { CreditsUsedResponse } from '@pages/api/user/creditsUsed';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCreditsUsed = createAsyncThunk(
  'user/fetchCreditsUsed',
  async (): Promise<CreditsUsedResponse | null> => {
    try {
      const url = `${getBaseAPIHost()}/user/creditsUsed`;
      const headers: HeadersInit = { 'Content-Type': 'application/json' };
      const response = await fetch(url, {
        credentials: 'include',
        method: 'GET',
        headers: headers
      });

      const creditsUsedResponse = (await response.json()) as CreditsUsedResponse;
      return creditsUsedResponse;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);
