import { base_workflow } from './basic_workflow';
import { FaceswapModel_FastFaceSwap_UpscaleWithModel } from './FaceswapModel_FastFaceSwap_UpscaleWithModel';
import { Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel } from './Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel';
import { Grain_Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel } from './Grain_Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel';
import { HIGH_RESOLUTION_FACESWAP } from './High_Resolution_Face_Swap';
import { UltimateUpscale } from './UltimateUpscale';
import { FLUX_LORA } from './Flux_Lora';
import { FLUX_LORA_FACE_DETAILER } from './Flux_Lora_FaceDetailer';
import { FLUX_LORA_FACE_DETAILER_UPSCALE } from './Flux_Lora_FaceDetailer_Upscale';
import { FLUX_LORA_FACE_IMG2IMG } from './Flux_Lora_Img2Img';
import { FLUX_LORA_FACE_IMG2IMG_FACE_DETAILER_UPSCALE } from './Flux_Lora_Img2ImgFacedetailerUpscale';
import { FLUX_LORA_IMG_FACE_DETAILER_UPSCALE_FP8 } from './Flux_Lora_Img_Face_Detail_Up_FP8';
import { FLUX_REALISM_LORA_FACE_DETAILER_UPSCALE } from './Flux_Realism_Lora_FaceDetailer_Upscale';
import { FLUX_DOUBLE_LORA_FACE_DETAILER_UPSCALE } from './Flux_Double_Lora_FaceDetailer_Upscale';
import { REMOVE_BACKGROUND } from './Remove_Background';
function parseWorkflowNameToHumanReadable(workflowName: string) {
  return workflowName.replace(/_/g, ' ');
}

const SD_1_5_WORKFLOWS = {
  base_workflow: base_workflow,
  FaceswapModel_FastFaceSwap_UpscaleWithModel: FaceswapModel_FastFaceSwap_UpscaleWithModel,
  Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel: Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel,
  UltimateUpscale: UltimateUpscale,
  Grain_Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel:
    Grain_Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel,
  HIGH_RESOLUTION_FACESWAP
};

const SD_FLUX_1_DEV_WORKFLOWS = {
  FLUX_LORA,
  FLUX_LORA_FACE_DETAILER,
  FLUX_LORA_FACE_DETAILER_UPSCALE,
  FLUX_LORA_FACE_IMG2IMG,
  FLUX_LORA_FACE_IMG2IMG_FACE_DETAILER_UPSCALE,
  FLUX_LORA_IMG_FACE_DETAILER_UPSCALE_FP8,
  FLUX_REALISM_LORA_FACE_DETAILER_UPSCALE,
  FLUX_DOUBLE_LORA_FACE_DETAILER_UPSCALE,
  REMOVE_BACKGROUND
};

export const COMFY_WORKFLOWS = {
  '1.5': SD_1_5_WORKFLOWS,
  'flux.1-dev': SD_FLUX_1_DEV_WORKFLOWS
};

export function filterOutAvailableWorkflows(filter?: '1.5' | 'flux.1-dev') {
  const allWorkflows = Object.entries(COMFY_WORKFLOWS).flatMap(([version, workflows]) =>
    Object.keys(workflows).map(workflowName => ({
      value: workflowName,
      label: parseWorkflowNameToHumanReadable(workflowName),
      version
    }))
  );

  if (filter) {
    return allWorkflows.filter(workflow => workflow.version === filter);
  }

  // by default only return flux.1-dev
  return allWorkflows.filter(workflow => workflow.version === 'flux.1-dev');
}

export function getAllWorkflows() {
  return Object.entries(COMFY_WORKFLOWS).flatMap(([version, workflows]) =>
    Object.keys(workflows).map(workflowName => ({
      value: workflowName,
      label: parseWorkflowNameToHumanReadable(workflowName),
      version
    }))
  );
}

export function getAvailableWorkflowFromName(workflowName: WorkflowsName) {
  for (const version in COMFY_WORKFLOWS) {
    const typedVersion = version as keyof typeof COMFY_WORKFLOWS;
    const workflows = COMFY_WORKFLOWS[typedVersion];
    if (workflowName in workflows) {
      return workflows[workflowName as keyof typeof workflows];
    }
  }
  return undefined;
}

export type WorkflowsName = keyof (typeof COMFY_WORKFLOWS)['1.5'] | keyof (typeof COMFY_WORKFLOWS)['flux.1-dev'];

export const DEFAULT_WORKFLOW_NAME_VERSION_1_5 = 'Grain_Wildcards_FaceswapModel_FastFaceSwap_UpscaleWithModel';
export const DEFAULT_WORKFLOW_NAME_FLUX_1_DEV: WorkflowsName = 'FLUX_DOUBLE_LORA_FACE_DETAILER_UPSCALE';
export const DEFAULT_TRAINING_STEPS = 133;
export const DEFAULT_TRAINING_RESOLUTION = 1024;

export const getDefaultWorkflowName = (modelVersion?: '1.5' | 'flux.1-dev') => {
  if (modelVersion === '1.5') {
    return DEFAULT_WORKFLOW_NAME_VERSION_1_5;
  } else if (modelVersion === 'flux.1-dev') {
    return DEFAULT_WORKFLOW_NAME_FLUX_1_DEV;
  }
  return DEFAULT_WORKFLOW_NAME_FLUX_1_DEV;
};

export const getModelVersionFromWorkflowName = (workflowName: string) => {
  if (workflowName in SD_1_5_WORKFLOWS) {
    return '1.5';
  } else if (workflowName in SD_FLUX_1_DEV_WORKFLOWS) {
    return 'flux.1-dev';
  }
  return '1.5';
};
