import * as React from 'react';
import { useGetFeed } from '@/_data/feed/useGetFeed';
import { FeedType } from './FeedTypeSelector';
import { PromptPost } from '@/_data/prompts/prompts';
import { FeedThemeResult } from '@store/create/types';

interface UseFeedOptions {
  feedType: FeedType | null;
}

export const FEED_ITEM_TYPES = {
  prompt: 'prompt',
  theme: 'theme'
} as const;
export type FeedItemType = (typeof FEED_ITEM_TYPES)[keyof typeof FEED_ITEM_TYPES];

type PromptFeedItem = {
  type: typeof FEED_ITEM_TYPES.prompt;
  data: PromptPost;
};

type ThemeFeedItem = {
  type: typeof FEED_ITEM_TYPES.theme;
  data: FeedThemeResult;
};

export type FeedItem = {
  post: PromptFeedItem | ThemeFeedItem;
  collapsedItems: FeedItem[];
};

export const useFeed = ({ feedType }: UseFeedOptions) => {
  const {
    data: feed,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    isRefetching,
    isStale,
    refetch
  } = useGetFeed(feedType);

  const hasNextPage = React.useMemo(() => {
    return feed?.pages?.[feed?.pages.length - 1]?.hasNextPage;
  }, [feed]);

  const feedItems: FeedItem[] = React.useMemo(() => {
    if (!feed?.pages || feed.pages.length === 0) {
      return [];
    }

    return feed.pages
      .map(page => page.feedItems)
      .flat()
      .filter(Boolean);
  }, [feed]);

  return {
    feedItems,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    isRefetching,
    isStale,
    refetch
  };
};
