export const Ethnicity = [
  'White',
  'Black',
  'East Asian (Chinese, Japanese, Korean)',
  'South East Asian (Thai, Indonesian)',
  'South Asian (Indian, Pakistani)',
  'Middle Eastern (Arabic)',
  'Pacific (Polynesian)',
  'Hispanic',
  'None'
];

/**
 * Descriptions for different ethnicities.
 * These descriptions are used to replace the {ethnicity} placeholder in prompts.
 * 
 * @example
 * ethnicityDescriptions['White'] // Returns: "Caucasian features with fair skin"
 * ethnicityDescriptions['Black'] // Returns: "African features with dark skin"
 */
const ethnicityDescriptions: Record<string, string> = {
  'White': 'Caucasian',
  'Black': '',
  'East Asian (Chinese, Japanese, Korean)': 'East Asian',
  'South East Asian (Thai, Indonesian)': 'South East Asian',
  'South Asian (Indian, Pakistani)': 'South Asian',
  'Middle Eastern (Arabic)': '',
  'Pacific (Polynesian)': '',
  'Hispanic': '',
  'None': ''
};

/**
 * Generates an ethnicity description based on the provided ethnicity.
 * If isDisabled is true or the ethnicity is 'None' or not provided, it returns an empty string.
 *
 * @param ethnicity - The ethnicity to use for the description
 * @param isDisabled - Optional flag to disable the ethnicity description
 * @returns A string with the ethnicity description or an empty string
 *
 * @example
 * const result = parseEthnicity("East Asian (Chinese, Japanese, Korean)");
 * // Result: ", East Asian (Chinese, Japanese, Korean),"
 *
 * @example
 * const result = parseEthnicity("White");
 * // Result: ", Caucasian,"
 *
 * @example
 * const result = parseEthnicity("None");
 * // Result: ""
 */
export const parseEthnicity = (ethnicity: string, isDisabled?: boolean): string => {
  if (isDisabled) {
    return ''
  }

  if (ethnicity === 'None' || !ethnicity) {
    return ''
  }

  const description = ethnicityDescriptions[ethnicity] || '';
  return `${description}`;
};
