import ListBox from '@components/ListBox/ListBox';
import { useQueryParam, useSession } from '@hooks/index';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const FEED_TYPES = {
  everyone: {
    value: 'everyone',
    label: 'Everyone',
    isSafe: true
  },
  following: {
    value: 'following',
    label: 'Following',
    isSafe: true
  },
  byMe: {
    value: 'by_me',
    label: 'By me',
    isSafe: true
  },
  adminMode: {
    value: 'admin-mode',
    label: 'Admin Mode',
    isSafe: true
  }
} as const;
export const DEFAULT_FEED_TYPE = FEED_TYPES.everyone.value;
export type FeedType = typeof FEED_TYPES[keyof typeof FEED_TYPES]['value'];
export const URL_ALLOWED_FEED_TYPES = Object.values(FEED_TYPES).filter(type => type.isSafe);

export const FeedTypeSelector = () => {
  const { user } = useSession();
  const router = useRouter();

  const feedType: FeedType | undefined = useQueryParam('feedType');

  const handleFeedTypeChange = (feedType: FeedType) => {
    //if the feed type is not allowed, return default
    if (!isAdmin && !URL_ALLOWED_FEED_TYPES.find(type => type.value === feedType)) {
      return;
    }

    router.push({
      pathname: '/feed',
      query: {
        ...router.query,
        feedType
      }
    });
  };

  const isAdmin = useMemo(() => {
    return user?.isAdmin;
  }, [user?.isAdmin]);

  const formattedValue = useMemo(() => {
    //remove hyphens and capitalize the first letter of each word
    return (feedType || 'everyone').replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  }, [feedType]);

  return (
    <div className="w-32">
      <ListBox
        value={formattedValue}
        size="small"
        options={[
          { disabled: false, value: FEED_TYPES.everyone.label },
          { disabled: false, value: FEED_TYPES.following.label },
          ...(isAdmin ? [{ disabled: false, value: FEED_TYPES.adminMode.label }] : [])
        ]}
        onChange={(value: string) => {
          //   spaces with hyphens and lowercase everything
          const feedType = value.replace(/\s/g, '-').toLowerCase() as FeedType;
          handleFeedTypeChange?.(feedType);
        }}
        listBoxClassName="w-48 left-[-4rem] sm:left-0"
      />
    </div>
  );
};
