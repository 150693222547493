export const HIGH_RESOLUTION_FACESWAP = {
  '424': {
    inputs: {
      text: 'PLACEHOLDER_BASIC_PROMPT',
      clip: ['722', 2]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'Positive Prompt'
    }
  },
  '464': {
    inputs: {
      detect_hand: 'enable',
      detect_body: 'enable',
      detect_face: 'enable',
      resolution: 512,
      bbox_detector: 'yolox_l.torchscript.pt',
      pose_estimator: 'dw-ll_ucoco_384_bs5.torchscript.pt',
      image: ['507', 0]
    },
    class_type: 'DWPreprocessor',
    _meta: {
      title: 'DWPose Estimator'
    }
  },
  '477': {
    inputs: {
      positive: ['424', 0],
      negative: ['688', 0],
      cnet_stack: ['528', 0]
    },
    class_type: 'Apply ControlNet Stack',
    _meta: {
      title: 'Apply ControlNet Stack'
    }
  },
  '478': {
    inputs: {
      images: ['507', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '479': {
    inputs: {
      padding: 16,
      constraints: 'keep_ratio',
      constraint_x: 512,
      constraint_y: 768,
      min_width: 0,
      min_height: 0,
      batch_behavior: 'match_ratio',
      mask: ['647', 0]
    },
    class_type: 'Mask To Region',
    _meta: {
      title: 'Mask To Region'
    }
  },
  '480': {
    inputs: {
      images: ['506', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Mask'
    }
  },
  '491': {
    inputs: {
      pixels: ['508', 0],
      vae: ['722', 3]
    },
    class_type: 'VAEEncode',
    _meta: {
      title: 'VAE Encode'
    }
  },
  '492': {
    inputs: {
      samples: ['491', 0],
      mask: ['493', 0]
    },
    class_type: 'SetLatentNoiseMask',
    _meta: {
      title: 'Latent Mask'
    }
  },
  '493': {
    inputs: {
      method: 'intensity',
      image: ['506', 0]
    },
    class_type: 'Image To Mask',
    _meta: {
      title: 'Image To Mask'
    }
  },
  '495': {
    inputs: {
      image: ['508', 0],
      mask: ['493', 0]
    },
    class_type: 'InpaintPreprocessor',
    _meta: {
      title: 'Inpaint Preprocessor'
    }
  },
  '497': {
    inputs: {
      control_net_name: 'control_v11p_sd15_inpaint_fp16.safetensors'
    },
    class_type: 'ControlNetLoader',
    _meta: {
      title: 'Load ControlNet Model'
    }
  },
  '499': {
    inputs: {
      resize_behavior: 'resize',
      image_base: ['705', 0],
      image_to_paste: ['501', 0],
      mask: ['502', 0]
    },
    class_type: 'Paste By Mask',
    _meta: {
      title: 'Paste By Mask'
    }
  },
  '501': {
    inputs: {
      force_resize_width: 0,
      force_resize_height: 0,
      image: ['621', 5],
      mask: ['506', 0]
    },
    class_type: 'Cut By Mask',
    _meta: {
      title: 'Cut By Mask'
    }
  },
  '502': {
    inputs: {
      radius: 4,
      sigma_factor: 1,
      image: ['647', 0]
    },
    class_type: 'Blur',
    _meta: {
      title: 'Blur'
    }
  },
  '506': {
    inputs: {
      force_resize_width: 512,
      force_resize_height: 768,
      image: ['647', 0],
      mask: ['479', 0]
    },
    class_type: 'Cut By Mask',
    _meta: {
      title: 'Cut By Mask'
    }
  },
  '507': {
    inputs: {
      force_resize_width: 512,
      force_resize_height: 768,
      image: ['705', 0],
      mask: ['479', 0]
    },
    class_type: 'Cut By Mask',
    _meta: {
      title: 'Cut By Mask'
    }
  },
  '508': {
    inputs: {
      images: ['581', 0]
    },
    class_type: 'Images to RGB',
    _meta: {
      title: 'Images to RGB'
    }
  },
  '509': {
    inputs: {
      images: ['501', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '518': {
    inputs: {
      strength: 1,
      start_percent: 0,
      end_percent: 1,
      positive: ['477', 0],
      negative: ['477', 1],
      control_net: ['497', 0],
      image: ['495', 0]
    },
    class_type: 'ControlNetApplyAdvanced',
    _meta: {
      title: 'ControlNet'
    }
  },
  '528': {
    inputs: {
      strength: 0.5,
      start_percent: 0,
      end_percent: 0.5,
      control_net: ['529', 0],
      image: ['507', 0]
    },
    class_type: 'Control Net Stacker',
    _meta: {
      title: 'Control Net Stacker'
    }
  },
  '529': {
    inputs: {
      control_net_name: 'control_v11p_sd15_openpose_fp16.safetensors'
    },
    class_type: 'ControlNetLoader',
    _meta: {
      title: 'Load ControlNet Model'
    }
  },
  '572': {
    inputs: {
      face_mask: true,
      background_mask: false,
      hair_mask: true,
      body_mask: false,
      clothes_mask: false,
      confidence: 0.4,
      images: ['705', 0]
    },
    class_type: 'APersonMaskGenerator',
    _meta: {
      title: 'A Person Mask Generator'
    }
  },
  '576': {
    inputs: {
      images: ['479', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '580': {
    inputs: {
      images: ['647', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '581': {
    inputs: {
      enabled: true,
      swap_model: 'inswapper_128.onnx',
      facedetection: 'retinaface_resnet50',
      face_restore_model: 'GFPGANv1.4.pth',
      face_restore_visibility: 1,
      codeformer_weight: 0.5,
      detect_gender_input: 'no',
      detect_gender_source: 'no',
      input_faces_index: '0',
      source_faces_index: '0',
      console_log_level: 1,
      input_image: ['507', 0],
      source_image: ['690', 0]
    },
    class_type: 'ReActorFaceSwap',
    _meta: {
      title: 'ReActor 🌌 Fast Face Swap'
    }
  },
  '583': {
    inputs: {
      images: ['581', 0]
    },
    class_type: 'PreviewImage',
    _meta: {
      title: 'Preview Image'
    }
  },
  '586': {
    inputs: {
      ipadapter_file: 'ip-adapter-faceid-plusv2_sd15.bin'
    },
    class_type: 'IPAdapterModelLoader',
    _meta: {
      title: 'IPAdapter Model Loader'
    }
  },
  '587': {
    inputs: {
      clip_name: 'CLIP-ViT-H-14-laion2B-s32B-b79K.safetensors'
    },
    class_type: 'CLIPVisionLoader',
    _meta: {
      title: 'Load CLIP Vision'
    }
  },
  '590': {
    inputs: {
      weight: 1,
      weight_faceidv2: 0,
      weight_type: 'linear',
      combine_embeds: 'concat',
      start_at: 1,
      end_at: 1,
      embeds_scaling: 'V only',
      ipadapter: ['586', 0],
      clip_vision: ['587', 0],
      insightface: ['591', 0],
      image: ['690', 0],
      model: ['722', 1]
    },
    class_type: 'IPAdapterFaceID',
    _meta: {
      title: 'IPAdapter FaceID'
    }
  },
  '591': {
    inputs: {
      provider: 'CPU',
      model_name: 'buffalo_l'
    },
    class_type: 'IPAdapterInsightFaceLoader',
    _meta: {
      title: 'IPAdapter InsightFace Loader'
    }
  },
  '621': {
    inputs: {
      seed: 157272161539983,
      steps: 40,
      cfg: 3,
      sampler_name: 'dpmpp_2m_sde_gpu',
      scheduler: 'karras',
      denoise: 0.5,
      preview_method: 'auto',
      vae_decode: 'true',
      model: ['590', 0],
      positive: ['518', 0],
      negative: ['518', 1],
      latent_image: ['492', 0],
      optional_vae: ['722', 3],
      script: ['652', 0]
    },
    class_type: 'KSampler (Efficient)',
    _meta: {
      title: 'KSampler (Efficient)'
    }
  },
  '647': {
    inputs: {
      distance: 15,
      op: 'dilate',
      image: ['648', 0]
    },
    class_type: 'Mask Morphology',
    _meta: {
      title: 'Mask Morphology'
    }
  },
  '648': {
    inputs: {
      mask: ['572', 0]
    },
    class_type: 'MaskToImage',
    _meta: {
      title: 'Mask to Image'
    }
  },
  '652': {
    inputs: {
      upscale_type: 'latent',
      hires_ckpt_name: '(use same)',
      latent_upscaler: 'ttl_nn.SD 1.x',
      pixel_upscaler: '4x_NMKD-Siax_200k.pth',
      upscale_by: 1.25,
      use_same_seed: true,
      seed: -1,
      hires_steps: 12,
      denoise: 0.5,
      iterations: 1,
      use_controlnet: false,
      control_net_name: 'OpenPoseXL2.safetensors',
      strength: 1,
      preprocessor: 'none',
      preprocessor_imgs: false
    },
    class_type: 'HighRes-Fix Script',
    _meta: {
      title: 'HighRes-Fix Script'
    }
  },
  '686': {
    inputs: {
      url_or_path:
        'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a23fc28e-2aa1-4ee6-8827-b85dc9e44a00/512?width=512&height=768&fit=cover&background=black'
    },
    class_type: 'LoadPoseImageFromUrlOrPathPicstudio',
    _meta: {
      title: 'LoadPoseImageFromUrlOrPathPicstudio'
    }
  },
  '687': {
    inputs: {
      seed: 127583497,
      steps: 40,
      cfg: 6,
      sampler_name: 'dpmpp_sde',
      scheduler: 'karras',
      denoise: 1,
      model: ['689', 0],
      positive: ['714', 0],
      negative: ['714', 1],
      latent_image: ['693', 0]
    },
    class_type: 'KSamplerPicstudio',
    _meta: {
      title: 'KSamplerPicstudio'
    }
  },
  '688': {
    inputs: {
      text: 'asian, multiple heads, 2 heads, elongated body, double image, 2 faces, multiple people, double head, topless, (nsfw)+, naked, nude, porn, open mouth, teeth, (sunglasses), black and white, monochrome, greyscale, grayscale, bw, CyberRealistic_Negative, CyberRealistic_Negative_v3, badhandv4, negative_hand-neg, BadDream, portrait, close up, sunglasses, sunglasses, bindi, pottu, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, illustration, cartoon, text, cropped, out of frame, worst quality, low quality, jpeg artifacts, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, cellulite, pubic hair, armpit hair, body hair, nipples',
      clip: ['689', 1]
    },
    class_type: 'CLIPTextEncodeNegativePicstudio',
    _meta: {
      title: 'CLIPTextEncodeNegativePicstudio'
    }
  },
  '689': {
    inputs: {
      ckpt_name: 'geo-ca4f7270-1f0b-4723-8b9e-cc686d8f4faa-converted.safetensors'
    },
    class_type: 'CheckpointLoaderSimplePicstudio',
    _meta: {
      title: 'CheckpointLoaderSimplePicstudio'
    }
  },
  '690': {
    inputs: {
      url_or_path: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/92a273b7-4059-4c50-2767-64f1c0b82e00/512'
    },
    class_type: 'LoadFaceSwapImageFromUrlOrPathPicstudio',
    _meta: {
      title: 'LoadFaceSwapImageFromUrlOrPathPicstudio'
    }
  },
  '691': {
    inputs: {
      url_or_path: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/92a273b7-4059-4c50-2767-64f1c0b82e00/512'
    },
    class_type: 'LoadReferenceFromUrlOrPathPicstudio',
    _meta: {
      title: 'LoadReferenceFromUrlOrPathPicstudio'
    }
  },
  '692': {
    inputs: {
      upscale_method: 'bilinear',
      width: 1024,
      height: 1536,
      crop: 'disabled',
      image: ['713', 0]
    },
    class_type: 'ImageScalePicstudio',
    _meta: {
      title: 'ImageScalePicstudio'
    }
  },
  '693': {
    inputs: {
      pixels: ['694', 0],
      vae: ['689', 2]
    },
    class_type: 'VAEEncode',
    _meta: {
      title: 'VAE Encode'
    }
  },
  '694': {
    inputs: {
      upscale_method: 'nearest-exact',
      width: 512,
      height: 768,
      crop: 'disabled',
      image: ['691', 0]
    },
    class_type: 'ReferenceImageScalePicStudio',
    _meta: {
      title: 'ReferenceImageScalePicStudio'
    }
  },
  '695': {
    inputs: {
      prompt:
        '((wide shot) of Headshot of  25 y o (cjw+ woman)+ , wearing ({dailyClothing}), ) masterpiece, cinematic light, cinematic lighting, ultrarealistic, photorealistic, 8k, raw photo, realistic, sharp focus on eyes, symmetrical eyes, intact eyes, hyperrealistic, highest quality, best quality, highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow, dramatic lighting, highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition, award winning photo,',
      seed: 127583498
    },
    class_type: 'Wildcard Processor',
    _meta: {
      title: 'Wildcard Processor (Mikey)'
    }
  },
  '696': {
    inputs: {
      text: ['695', 0],
      clip: ['689', 1]
    },
    class_type: 'CLIPTextEncode',
    _meta: {
      title: 'CLIP Text Encode (Prompt)'
    }
  },
  '698': {
    inputs: {
      samples: ['687', 0]
    },
    class_type: 'gcLatentTunnelPicstudio',
    _meta: {
      title: 'gcLatentTunnelPicstudio'
    }
  },
  '699': {
    inputs: {
      samples: ['704', 0]
    },
    class_type: 'gcLatentTunnelPicstudio',
    _meta: {
      title: 'gcLatentTunnelPicstudio'
    }
  },
  '701': {
    inputs: {
      filename_prefix: '127583497_811252169',
      images: ['706', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '702': {
    inputs: {
      filename_prefix: '127583497_554455817',
      images: ['709', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '704': {
    inputs: {
      seed: 295324106938066,
      steps: 30,
      cfg: 3,
      sampler_name: 'uni_pc_bh2',
      scheduler: 'normal',
      denoise: 0.25,
      model: ['689', 0],
      positive: ['714', 0],
      negative: ['714', 1],
      latent_image: ['708', 0]
    },
    class_type: 'KSampler',
    _meta: {
      title: 'KSampler'
    }
  },
  '705': {
    inputs: {
      samples: ['699', 0],
      vae: ['689', 2]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '706': {
    inputs: {
      samples: ['698', 0],
      vae: ['689', 2]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '707': {
    inputs: {
      samples: ['698', 0],
      vae: ['689', 2]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  },
  '708': {
    inputs: {
      pixels: ['692', 0],
      vae: ['689', 2]
    },
    class_type: 'VAEEncode',
    _meta: {
      title: 'VAE Encode'
    }
  },
  '709': {
    inputs: {
      enabled: true,
      swap_model: 'inswapper_128.onnx',
      facedetection: 'retinaface_resnet50',
      face_restore_model: 'GFPGANv1.4.pth',
      face_restore_visibility: 1,
      codeformer_weight: 0.5,
      detect_gender_input: 'no',
      detect_gender_source: 'no',
      input_faces_index: '0',
      source_faces_index: '0',
      console_log_level: 1,
      input_image: ['706', 0],
      source_image: ['690', 0]
    },
    class_type: 'ReActorFaceSwap',
    _meta: {
      title: 'ReActor 🌌 Fast Face Swap'
    }
  },
  '710': {
    inputs: {
      control_net_name: 'control_v11p_sd15_openpose_fp16.safetensors'
    },
    class_type: 'ControlNetLoader',
    _meta: {
      title: 'Load ControlNet Model'
    }
  },
  '711': {
    inputs: {
      strength: 0.65,
      start_percent: 0,
      end_percent: 0.8,
      control_net: ['710', 0],
      image: ['686', 0]
    },
    class_type: 'Control Net Stacker',
    _meta: {
      title: 'Control Net Stacker'
    }
  },
  '712': {
    inputs: {
      model_name: '8xNMKDFaces160000G_v10.pt'
    },
    class_type: 'UpscaleModelLoader',
    _meta: {
      title: 'Load Upscale Model'
    }
  },
  '713': {
    inputs: {
      upscale_model: ['712', 0],
      image: ['709', 0]
    },
    class_type: 'ImageUpscaleWithModel',
    _meta: {
      title: 'Upscale Image'
    }
  },
  '714': {
    inputs: {
      positive: ['696', 0],
      negative: ['688', 0],
      cnet_stack: ['711', 0]
    },
    class_type: 'Apply ControlNet Stack',
    _meta: {
      title: 'Apply ControlNet Stack'
    }
  },
  '715': {
    inputs: {
      interpolation: 'LANCZOS',
      crop_position: 'top',
      sharpening: 0,
      image: ['690', 0]
    },
    class_type: 'PrepImageForClipVision',
    _meta: {
      title: 'Prep Training Face For Model'
    }
  },
  '716': {
    inputs: {
      filename_prefix: 'ComfyUI',
      images: ['705', 0]
    },
    class_type: 'SaveImageAssetPicstudio',
    _meta: {
      title: 'SaveImageAssetPicstudio'
    }
  },
  '722': {
    inputs: {
      model: ['689', 0],
      clip: ['689', 1],
      vae: ['689', 2]
    },
    class_type: 'Bus Node',
    _meta: {
      title: 'Bus Node'
    }
  },
  '723': {
    inputs: {
      filename_prefix: 'ComfyUI',
      images: ['499', 0]
    },
    class_type: 'SaveImage',
    _meta: {
      title: 'Save Image'
    }
  }
};
