import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { createStyle, fetchAllStyles } from '@store/styles/actions';
import { Modifier } from '@lib/getModifiers';
import { SortableListItem } from '@components/Create/SortableStyles';

export const useFetchAllStyles = () => {
  const appDispatch = useAppDispatch();
  const { styles } = useAppSelector(state => state.styles);

  return React.useCallback(() => {
    if (styles.length === 0) {
      appDispatch(fetchAllStyles());
    }
  }, [appDispatch, styles.length]);
};

export const useSearchStyles = () => {
  const { styles } = useAppSelector(state => state.styles);

  return React.useCallback(
    (search: string) => {
      const foundStyles: Modifier[] = [];
      styles.forEach(style => {
        if (style.name.toLowerCase().includes(search.toLowerCase())) {
          foundStyles.push(style);
        }
      });
      // Sort so that exact match is at top
      // If it starts with string it shoul be above the ones that dont start with string
      foundStyles.sort((a, b) => {
        if (a.name.toLowerCase() === search.toLowerCase()) {
          return -1;
        }
        if (b.name.toLowerCase() === search.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase().startsWith(search.toLowerCase())) {
          return -1;
        }
        if (b.name.toLowerCase().startsWith(search.toLowerCase())) {
          return 1;
        }
        return 0;
      });

      return foundStyles;
    },
    [styles]
  );
};

export const useGetStylesFromSubject = () => {
  const { styles } = useAppSelector(state => state.styles);

  return React.useCallback(
    (subject: string, listItems?: boolean) => {
      // Split by comma
      let styleNames = subject.split(',');
      // Also split by dot
      styleNames.forEach(styleName => {
        const split = styleName.split('.');
        // If split has at least two non-empty parts
        if (split.length > 1 && split[0] && split[1]) {
          // Replace the old style name with the split ones
          const index = styleNames.indexOf(styleName);
          if (index !== -1) {
            styleNames.splice(index, 1, ...split.filter(Boolean));
          }
        }
      });
      styleNames = styleNames.map(s => s.trim().toLowerCase());

      const foundStyles: Modifier[] = [];
      styleNames.forEach(styleName => {
        const foundStyle = styles.find(style => style.name.toLowerCase() === styleName);
        if (foundStyle) {
          foundStyles.push(foundStyle);
        } else {
          foundStyles.push({
            categories: [],
            id: Math.random().toString(),
            name: styleName,
            strength: 0,
            verified: false,
            type: 'modifier',
            enabled: true
          });
        }
      });
      if (!listItems) {
        return foundStyles;
      } else {
        const listItems: SortableListItem[] = foundStyles.map(style => ({
          type: 'modifier',
          data: style,
          id: style.id,
          enabled: true
        }));
        return listItems;
      }
    },
    [styles]
  );
};

export const useStyles = () => {
  const { styles, categories } = useAppSelector(state => state.styles);

  return { styles, categories };
};

export const useCreateStyle = () => {
  const appDispatch = useAppDispatch();
  return React.useCallback(
    (name: string) => {
      appDispatch(createStyle(name));
    },
    [appDispatch]
  );
};

export const useDefaultNegativeStyles = () => {
  const defaultNegativeStyles = useAppSelector(state => state.styles.defaultNegativeStyles);

  return React.useMemo(() => {
    return defaultNegativeStyles;
  }, [defaultNegativeStyles]);
};
