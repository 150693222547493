import styled from 'styled-components';
import { style, layout, LayoutProps, color, ColorProps, space, SpaceProps, flex, FlexProps } from 'styled-system';

const stroke = style({
  prop: 'stroke',
  cssProperty: 'stroke',
  key: 'colors'
});

const fill = style({
  prop: 'fill',
  cssProperty: 'fill',
  key: 'colors'
});

const Svg = styled.svg.attrs(p => ({
  // @ts-ignore
  fill: p.fill,
  // @ts-ignore
  strokeWidth: p.strokeWidth,
  // @ts-ignore
  strokeLinecap: p.strokeLinecap,
  // @ts-ignore
  strokeLinejoin: p.strokeLinejoin,
  // @ts-ignore
  viewBox: p.viewBox
}))<LayoutProps & ColorProps & SpaceProps & FlexProps>`
  height: auto;

  ${color};
  ${fill};
  ${layout}
  ${space};
  ${stroke};
  ${flex};
`;

Svg.defaultProps = {
  // @ts-ignore
  stroke: 'none',
  fill: 'none',
  display: 'flex'
};

export default Svg;
