import type { AppProps } from 'next/app';
import { CookiesProvider } from 'react-cookie';
import NextNProgress from 'nextjs-progressbar';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { GoogleTagManager } from '@next/third-parties/google';
import { Provider } from 'react-redux';
import { store } from '../store';
import '/styles/global.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { HydrationBoundary } from '@tanstack/react-query';
import * as React from 'react';
// import { queryClient as defaultQueryClient } from '../_data/queryClient';
import Head from 'next/head';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AnalyticsProvider } from '@analytics/AnalyticsProvider';
import { usePreserveScroll } from '@hooks/usePreserveScroll';
import { ThemeProvider } from '@components/ThemeProvider';
import '@styles/reactGridStyles.css';
// import { appRouter } from '@acme/api';
// import { useGlobalWebsocketListener } from '@hooks/globalWebsocketListener';
import { Toaster as SonnerToaster } from 'sonner';
import { SessionProvider } from 'next-auth/react';
import { TRPCReactProvider } from '@/trpc/react';
import { AnalyticsStatsigProvider } from '@analytics/AnalyticsStatsigProvider';
import { Toaster } from '@components/ui/toaster';

// tRPC api endpoints
// vanilla api endpoints

// function WebsocketProvider({ children }: { children: React.ReactNode }) {
//   useGlobalWebsocketListener();

//   return <>{children}</>;
// }

function PromptHunt({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  usePreserveScroll();

  return (
    <Provider store={store}>
      <TRPCReactProvider>
        <ErrorBoundary>
          <script async></script>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <SkeletonTheme baseColor="#1c1c1c" highlightColor="#222222" duration={3}>
              <SessionProvider session={session}>
                <AnalyticsProvider>
                  <AnalyticsStatsigProvider>
                    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
                      <CookiesProvider>
                        <GoogleTagManager gtmId="GTM-MR928KJX" />
                        <NextNProgress color="#29D" options={{ showSpinner: false }} />
                        <Head>
                          <meta
                            name="viewport"
                            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                          />
                        </Head>

                        {/* <WebsocketProvider> */}
                        <Component {...pageProps} />
                        <SonnerToaster />
                        <Toaster />
                        {/* </WebsocketProvider> */}
                      </CookiesProvider>
                    </ThemeProvider>
                  </AnalyticsStatsigProvider>
                </AnalyticsProvider>
              </SessionProvider>
            </SkeletonTheme>
          </HydrationBoundary>
        </ErrorBoundary>
      </TRPCReactProvider>
    </Provider>
  );
}

export default PromptHunt;
