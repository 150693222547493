// Fetch parrot

export const getParrotPrompt = async (prompt: string): Promise<string> => {
  const res = await fetch('/api/prompts/parrot', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ prompt })
  });

  const { prompt: parrotPrompt } = await res.json();

  return parrotPrompt;
};
