import { Size } from '../types/types';

const getPadding = (size: Size) => {
  switch (size) {
    case 'small':
      return { px: 4, py: 2 };
    case 'medium':
      return { px: 4, py: 3 };
    case 'large':
      return { px: 4, py: 4 };
  }
};

export default getPadding;
