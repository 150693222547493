import { queryClient } from '@/_data/queryClient';
import { PromptComment } from '@store/prompt/types';

export const setCommentsForPost = (postId: string, comments: PromptComment[]) => {
  queryClient.setQueryData(['comments', postId], comments);
};

export const getComments = (postId: string) => {
  return queryClient.getQueryData<PromptComment[]>(['comments', postId]);
};

export const addComment = (postId: string, comment: PromptComment) => {
  const comments = queryClient.getQueryData(['comments', postId]) as PromptComment[] | undefined;
  if (comments) {
    queryClient.setQueryData(['comments', postId], [...comments, comment]);
  }
};

export const removeComment = (postId: string, commentId: string) => {
  const comments = queryClient.getQueryData(['comments', postId]) as PromptComment[] | undefined;
  if (comments) {
    queryClient.setQueryData(
      ['comments', postId],
      comments.filter((comment: PromptComment) => comment.id !== commentId)
    );
  }
};
