import { deletePromptPageAsset, unDeletePromptPageAsset } from '@/_data/prompts/usePromptPage';
import * as React from 'react';
import { useNotify } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../hooks';
import { deleteAsset, SearchProps, unDeleteAsset } from './actions';
import {
  deletePrompt,
  editPrompt,
  EditPromptProps,
  fetchPrompt,
  likePrompt,
  searchCreatedPrompts,
  searchLikedPrompts,
  searchPrompts,
  unLikePrompt
} from './actions';
import {
  clearPromptResultsForKey,
  resetStore,
  setIsSelectionActive,
  setSelectedPrompts,
  togglePrompt
} from './reducer';
import { FeedOrder, PromptAsset, PromptResult } from './types';

interface SearchPromptsProps extends SearchProps {
  userId?: string;
  creations?: boolean;
  likes?: boolean;
  filter?: string;
  order?: FeedOrder;
}

export const useSearchPrompts = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(
    ({
      userId,
      query = '',
      provider,
      page,
      nextPage,
      creations,
      likes,
      filter,
      themeId,
      order
    }: SearchPromptsProps) => {
      if (userId && creations) {
        return appDispatch(searchCreatedPrompts({ userId, page, nextPage, filter }));
      } else if (userId && likes) {
        return appDispatch(searchLikedPrompts({ userId, provider, page, nextPage }));
      } else {
        return appDispatch(searchPrompts({ query, provider, page, nextPage, themeId, order }));
      }
    },

    [appDispatch]
  );
};

export const usePromptResults = (
  query?: string, // query or user id
  excludePrompt?: string, // id of prompt to exclude from results
  provider?: string, // provider filter or private filter
  creations?: boolean, // show creations only
  likes?: boolean, // show likes only,
  themeId?: string, // show prompts from a theme
  order?: string // order by
) => {
  return useAppSelector(state => {
    if (typeof query == 'undefined')
      return {
        isLoading: true,
        prompts: [] as PromptResult[]
      };

    let promptsKey =
      creations || likes ? query + (creations ? 'creations' : 'likes') + provider : query + provider + order;
    if (themeId) promptsKey = themeId;
    const prompts = state.prompts.promptResults[promptsKey] || [];
    const promptsWithoutMainPrompt = prompts.filter(p => p.id !== excludePrompt);
    const isLoading =
      typeof state.prompts.isLoading[promptsKey] === 'boolean' ? state.prompts.isLoading[promptsKey] : true;
    const noMoreResults =
      typeof state.prompts.noMoreResults[promptsKey] === 'boolean' ? state.prompts.noMoreResults[promptsKey] : false;
    const nextPage = state.prompts.nextPage[promptsKey];

    return {
      prompts: promptsWithoutMainPrompt,
      isLoading,
      noMoreResults,
      nextPage
    };
  });
};

export const useCachedPrompt = (id: string) => {
  const appDispatch = useAppDispatch();
  const cachedPrompt = useAppSelector(state => state.prompts.promptMap[id]);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    if (!cachedPrompt && id && !isFetching) {
      setIsFetching(true);
      appDispatch(fetchPrompt(id));
    }
  }, [appDispatch, cachedPrompt, id, isFetching]);

  return cachedPrompt as PromptResult | undefined;
};

export const useClearResults = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(
    (query: string, provider?: string) => {
      let key = query;
      if (provider) key += provider;
      appDispatch(clearPromptResultsForKey(key));
    },

    [appDispatch]
  );
};

export const useLikePropmt = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(
    (promptId: string) => {
      appDispatch(likePrompt(promptId));
    },

    [appDispatch]
  );
};

export const useUnLikePropmt = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(
    (promptId: string) => {
      appDispatch(unLikePrompt(promptId));
    },

    [appDispatch]
  );
};

export const useHasCreations = (userId?: string, filter?: string) => {
  const appDispatch = useAppDispatch();

  React.useEffect(() => {
    if (userId) {
      appDispatch(searchCreatedPrompts({ userId }));
    }
  }, [userId, appDispatch]);

  const hasCreations = useAppSelector(
    state =>
      userId &&
      state.prompts.promptResults[userId + 'creations' + filter] !== undefined &&
      state.prompts.promptResults[userId + 'creations' + filter]!.length > 0
  );

  return hasCreations;
};

export const useDeletePrompt = () => {
  const appDispatch = useAppDispatch();
  const notify = useNotify();

  return React.useCallback(
    (id: string) => {
      appDispatch(deletePrompt(id));
      notify('Prompt deleted successfully');
    },
    [appDispatch, notify]
  );
};

export const useClearPromptCache = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(() => {
    appDispatch(resetStore());
  }, [appDispatch]);
};

export const useEditPrompt = () => {
  const appDispatch = useAppDispatch();
  const notify = useNotify();

  return React.useCallback(
    ({ id, isArchived, isPrivate }: EditPromptProps) => {
      appDispatch(editPrompt({ id, isArchived, isPrivate }));
      notify(isArchived ? 'Creation archived' : 'Creation unarchived');
    },
    [appDispatch, notify]
  );
};

export const useDeleteAsset = () => {
  const appDispatch = useAppDispatch();
  const notify = useNotify();
  const unDeleteAsset = useUnDeleteAsset();

  return React.useCallback(
    (asset: PromptAsset, onUndo: (asset: PromptAsset) => void) => {
      appDispatch(deleteAsset(asset.id));
      deletePromptPageAsset(asset.promptId, asset.id);
      notify('Creation deleted', 'blank', 5000, {
        text: 'Undo',
        onClick: () => {
          unDeleteAsset(asset);
          onUndo(asset);
          unDeletePromptPageAsset(asset.promptId, asset);
        }
      });
    },
    [appDispatch, notify, unDeleteAsset]
  );
};

export const useUnDeleteAsset = () => {
  const appDispatch = useAppDispatch();

  return React.useCallback(
    (asset: PromptAsset) => {
      appDispatch(unDeleteAsset(asset.id));
    },
    [appDispatch]
  );
};

export const useSelectedPrompts = () => {
  const appDispatch = useAppDispatch();

  const selectedPrompts = useAppSelector(state => state.prompts.selectedPrompts);
  const isSelectionActive = useAppSelector(state => state.prompts.isSelectionActive);

  const _setIsSelectionActive = React.useCallback(
    (isActive: boolean) => {
      appDispatch(setIsSelectionActive(isActive));
    },
    [appDispatch]
  );

  const toggle = React.useCallback(
    (promptId: string) => {
      appDispatch(togglePrompt(promptId));
    },
    [appDispatch]
  );

  const setPrompts = React.useCallback(
    (prompts: string[]) => {
      appDispatch(setSelectedPrompts(prompts));
    },
    [appDispatch]
  );

  return {
    selectedPrompts,
    setSelectedPrompts: setPrompts,
    togglePrompt: toggle,
    isSelectionActive,
    setIsSelectionActive: _setIsSelectionActive
  };
};
