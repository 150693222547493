import { Variant } from '../types/types';

const getBackgroundColor = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return 'background.contrast';
    case 'secondary':
      return 'background.tertiary';
    case 'tertiary':
      return 'rgba(255, 255, 255, 0.05)';
    case 'accent':
      return 'background.accent';
  }
};

export default getBackgroundColor;
