import { useRouter } from 'next/router';
import * as React from 'react';
import { objectToQueryString } from '../lib/objectToQueryString';
import { queryStringToObject } from '../lib/queryStringToObject';

export const useSetQueryParam = (queryKey: string | string[]) => {
  const router = useRouter();

  return React.useCallback(
    (value: any, clearParams?: boolean) => {
      const currentUrl = router.asPath.split('?')[0];
      const currentQueryString = clearParams ? '' : router.asPath.split('?')[1];
      const queryObject = queryStringToObject(currentQueryString);
      if (typeof queryKey === 'string') {
        if (!value) {
          delete queryObject[queryKey];
        } else {
          queryObject[queryKey] = value;
        }
      } else {
        queryKey.forEach((key, idx) => {
          if (!value[idx]) {
            delete queryObject[key];
          } else {
            queryObject[key] = value[idx];
          }
        });
      }

      const newQueryString = objectToQueryString(queryObject);
      if (newQueryString) {
        router.push(`${currentUrl}?${newQueryString}`, undefined, { shallow: true });
      } else {
        router.push(`${currentUrl}`, undefined, { shallow: true });
      }
    },
    [router, queryKey]
  );
};
