import { useSession } from '@hooks/index';
import { useEffect } from 'react';

export const AnalyticsProvider = ({ ...props }) => {
  const { identifyUser } = useSession();

  useEffect(() => {
    identifyUser();
  }, [identifyUser]);

  return <>{props.children}</>;
};
