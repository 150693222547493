import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  background,
  BackgroundProps,
  color,
  ColorProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  GridRowGapProps
} from 'styled-system';

const Input = styled.input<
  ShadowProps &
    LayoutProps &
    BorderProps &
    FlexboxProps &
    PositionProps &
    BackgroundProps &
    ColorProps &
    GridRowGapProps &
    SpaceProps & { ref?: any }
>`
  ${layout};
  ${border};
  ${flexbox};
  ${position};
  ${background};
  ${color};
  ${shadow};
  ${space};

  outline: none;
`;

Input.defaultProps = {
  display: 'flex',
  boxShadow: 'none',
  border: 0
};

export default Input;
