export const MODEL_STATUS = {
  queued: 'queued',
  training: 'training',
  ready: 'ready',
  failed: 'failed',
  cancelled: 'cancelled',
  expired: 'expired'
} as const;

export type ModelStatus = 'training' | 'ready' | 'failed' | 'cancelled' | 'queued' | 'expired' | (string & {});
