import styled from 'styled-components';
import { Grid } from '..';
import { GridColumn as Col } from '..';

const GridRowComponent = styled(Grid)`
  grid-template-columns: repeat(12, 1fr);
`;

export const GridRow = Object.assign(GridRowComponent, {
  Col
});
