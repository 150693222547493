import { ExtendedInput } from '../constructWorkflowV2';
import { getCleanUpScheduler } from '../getCleanUpScheduler';

function generate4RandomNumbers() {
  return Math.floor(Math.random() * (900000000 - 100000000) + 100000000);
}

interface Node {
  inputs: Record<string, any>;
  class_type: string;
  _meta: Record<string, any>;
}

function parseKSamplerPicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      seed: input.seed || 0,
      control_after_generate: 'fixed',
      steps: input.num_inference_steps,
      cfg: input.guidance_scale,
      sampler_name: 'dpmpp_sde',
      scheduler: getCleanUpScheduler(input.scheduler || 'karras'),
      denoise: input.strength || 1
    }
  };
}

function parseWildCardProcessor(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      prompt: input.prompt,
      seed: input.seed ? input.seed + 1 : Math.floor(Math.random() * (900000000 - 100000000) + 100000000),
      control_after_generate: 'fixed'
    }
  };
}

function parseCLIPTextEncodePositivePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      text: input.prompt
    }
  };
}
function parseCLIPTextEncodePicstudio(input: ExtendedInput, node: Node) {
  if (node.inputs.text === 'PLACEHOLDER_BASIC_PROMPT') {
    return {
      ...node,
      inputs: {
        ...node.inputs,
        text: `headshot of (cjw+ ${
          input.prompt.includes('male') ? 'man' : 'woman'
        }), masterpiece, cinematic light, cinematic lighting, ultrarealistic, photorealistic, 8k, raw photo, realistic, sharp focus on eyes, symmetrical eyes, intact eyes, hyperrealistic, highest quality, best quality, highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow, dramatic lighting, highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition, award winning photo,`
      }
    };
  } else {
    return node;
  }
}

function parseCLIPTextEncodeNegativePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      text: input.negative_prompt
    }
  };
}

function parseLoadPoseImageFromUrlOrPathPicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      url_or_path: input.pose_image
    }
  };
}

function parseLoadReferenceFromUrlOrPathPicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      url_or_path: input.image || node.inputs.url_or_path
    }
  };
}

function parseCheckpointLoaderSimplePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      ckpt_name: input.ckpt_name || ''
    }
  };
}

function parseLatentUpscalePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      width: input.width,
      height: input.height
    }
  };
}

function parseLoadFaceSwapImageFromUrlOrPathPicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      url_or_path: input.training_image_url
    }
  };
}

function parseReferenceImageScalePicStudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      width: input.width,
      height: input.height
    }
  };
}

function parseImageScalePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      width: input.width * 2,
      height: input.height * 2
    }
  };
}

function parseSaveImage(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      filename_prefix: input.seed + '_' + generate4RandomNumbers()
    }
  };
}

function parseSaveImageAssetPicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      filename_prefix: input.seed + '_' + generate4RandomNumbers()
    }
  };
}

function parseLoraLoaderModelOnlyFromVolumePicstudio(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      lora_id: input.picstudio_model_id,
      lora_name: input.ckpt_name
    }
  };
}

function parseRandomNoise(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      noise_seed: input.seed
    }
  };
}

function parseExternalNumberIntPicstudio(input: ExtendedInput, node: Node) {
  const inputId = node.inputs.input_id;
  let value = node.inputs.default_value;

  if (inputId in input) {
    value = input[inputId as keyof ExtendedInput];
  }

  return {
    ...node,
    inputs: {
      ...node.inputs,
      default_value: value
    }
  };
}

function parseExternalNumberFloatPicstudio(input: ExtendedInput, node: Node) {
  const inputId = node.inputs.input_id;
  let value = node.inputs.default_value;

  if (inputId in input) {
    value = input[inputId as keyof ExtendedInput];
  }

  return {
    ...node,
    inputs: {
      ...node.inputs,
      default_value: value
    }
  };
}

function parseExternalTextPicstudio(input: ExtendedInput, node: Node) {
  const inputId = node.inputs.input_id;
  let value = node.inputs.default_value;

  if (inputId in input) {
    value = input[inputId as keyof ExtendedInput];
  }

  return {
    ...node,
    inputs: {
      ...node.inputs,
      default_value: value
    }
  };
}

function parseLoraLoaderModelOnly(input: ExtendedInput, node: Node) {
  return {
    ...node,
    inputs: {
      ...node.inputs,
      lora_name: input.lora_name,
      strength_model: input.lora_strength
    }
  };
}

export const parsePicstudioNodesMap = {
  'Wildcard Processor': parseWildCardProcessor,
  KSamplerPicstudio: parseKSamplerPicstudio,
  CLIPTextEncodePositivePicstudio: parseCLIPTextEncodePositivePicstudio,
  CLIPTextEncodeNegativePicstudio: parseCLIPTextEncodeNegativePicstudio,
  LoadPoseImageFromUrlOrPathPicstudio: parseLoadPoseImageFromUrlOrPathPicstudio,
  LoadReferenceFromUrlOrPathPicstudio: parseLoadReferenceFromUrlOrPathPicstudio,
  CheckpointLoaderSimplePicstudio: parseCheckpointLoaderSimplePicstudio,
  LatentUpscalePicstudio: parseLatentUpscalePicstudio,
  LoadFaceSwapImageFromUrlOrPathPicstudio: parseLoadFaceSwapImageFromUrlOrPathPicstudio,
  ReferenceImageScalePicStudio: parseReferenceImageScalePicStudio,
  ImageScalePicstudio: parseImageScalePicstudio,
  SaveImage: parseSaveImage,
  SaveImageAssetPicstudio: parseSaveImageAssetPicstudio,
  CLIPTextEncodePicstudio: parseCLIPTextEncodePicstudio,
  LoraLoaderModelOnlyFromVolumePicstudio: parseLoraLoaderModelOnlyFromVolumePicstudio,
  RandomNoise: parseRandomNoise,
  ExternalNumberIntPicstudio: parseExternalNumberIntPicstudio,
  ExternalNumberFloatPicstudio: parseExternalNumberFloatPicstudio,
  ExternalTextPicstudio: parseExternalTextPicstudio,
  LoraLoaderModelOnly: parseLoraLoaderModelOnly
};

export function parsePicstudioNodes(input: ExtendedInput, workflow: Record<string, Node>) {
  const nodes = workflow;

  for (const nodeName in nodes) {
    const node = nodes[nodeName];
    if (parsePicstudioNodesMap[node!.class_type as keyof typeof parsePicstudioNodesMap]) {
      nodes[nodeName] = parsePicstudioNodesMap[node!.class_type as keyof typeof parsePicstudioNodesMap](input, node!);
    }
  }

  return nodes;
}
