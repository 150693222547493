import { matchQuery, MutationCache, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20
    }
  },
  mutationCache: new MutationCache({
    onSuccess: (_data, _variables, _context, mutation) => {
      queryClient.invalidateQueries({
        predicate: query =>
          // invalidate all matching tags at once
          // or everything if no meta is provided
          // https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations
          // @ts-ignore
          mutation.meta?.invalidates?.some(queryKey => matchQuery({ queryKey }, query)) ?? true
      });
    }
  })
});

export { queryClient };
