import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { fetchCreditsUsed } from './actions';

import { UserState } from './types';

export const extraReducers = (builder: ActionReducerMapBuilder<UserState>) => {
  builder.addCase(fetchCreditsUsed.fulfilled, (state, action) => {
    if (action.payload !== null) {
      state.creditsUsed = action.payload.used;
      state.creditsAvailable = action.payload.available;
      state.isLoading = false;
      state.isLoaded = true;
    }
  });
  builder.addCase(fetchCreditsUsed.rejected, state => {
    state.isLoading = false;
    state.isLoaded = true;
  });
};
