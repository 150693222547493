import * as React from 'react';
import toast, { ToastType } from 'react-hot-toast';
import { Toast } from '@components/Toast/Toast';

type Action = {
  text: string;
  onClick: () => void;
};

export const useNotify = () => {
  const notify = React.useCallback(
    (text: string, toastType: ToastType = 'success', duration?: number, action?: Action) =>
      toast.custom(
        t => <Toast visible={t.visible} id={t.id} action={action} text={text} type={toastType} message={t} />,
        { duration: duration || 5000 }
      ),
    []
  );

  return notify;
};
