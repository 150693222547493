import { AspectRatio, PhotoSize as SizeType } from './types/photo';

interface PhotoSize {
  width: number;
  height: number;
  aspectRatio: AspectRatio;
  size: SizeType;
}

export const SIZES_SD_1_5 = {
  '1:1': {
    small: { width: 512, height: 512 },
    medium: { width: 640, height: 640 },
    large: { width: 768, height: 768 }
  },
  '4:5': {
    small: { width: 512, height: 640 },
    medium: { width: 640, height: 800 },
    large: { width: 768, height: 960 }
  },
  '3:4': {
    small: { width: 512, height: 684 },
    medium: { width: 640, height: 852 },
    large: { width: 768, height: 1024 }
  },
  '2:3': {
    small: { width: 512, height: 768 },
    medium: { width: 640, height: 960 },
    large: { width: 768, height: 1136 }
  },
  '9:16': {
    small: { width: 512, height: 912 },
    medium: { width: 640, height: 1136 },
    large: { width: 768, height: 1368 }
  },
  '5:4': {
    small: { width: 640, height: 512 },
    medium: { width: 800, height: 640 },
    large: { width: 960, height: 768 }
  },
  '4:3': {
    small: { width: 684, height: 512 },
    medium: { width: 852, height: 640 },
    large: { width: 1024, height: 768 }
  },
  '3:2': {
    small: { width: 768, height: 512 },
    medium: { width: 960, height: 640 },
    large: { width: 1136, height: 768 }
  },
  '16:9': {
    small: { width: 912, height: 512 },
    medium: { width: 1136, height: 640 },
    large: { width: 1368, height: 768 }
  }
};

export const SIZES_SD_FLUX_1_DEV = {
  // '4:5': {
  //   small: { width: 832, height: 1024 },
  //   // small: { width: 1024, height: 1280 }, // height = (5/4) * 1024
  //   medium: { width: 1040, height: 1280 },
  //   large: { width: 1248, height: 1536 }
  // },
  // '3:4': {
  //   small: { width: 896, height: 1152 },
  //   // small: { width: 1024, height: 1365 }, // height = (4/3) * 1024
  //   medium: { width: 1120, height: 1440 },
  //   large: { width: 1344, height: 1728 }
  // },
  '2:3': {
    small: { width: 832, height: 1216 },
    // small: { width: 1024, height: 1536 }, // height = (3/2) * 1024
    medium: { width: 1040, height: 1520 },
    large: { width: 1248, height: 1824 }
  },
  '1:1': {
    small: { width: 1024, height: 1024 },
    medium: { width: 1280, height: 1280 },
    large: { width: 1536, height: 1536 }
  },
  // '9:16': {
  //   small: { width: 768, height: 1344 },
  //   // small: { width: 1024, height: 1820 }, // height = (16/9) * 1024
  //   medium: { width: 960, height: 1680 },
  //   large: { width: 1152, height: 2016 }
  // },
  // '16:9': {
  //   small: { width: 1344, height: 768 },
  //   // small: { width: 1820, height: 1024 }, // width = (16/9) * 1024
  //   medium: { width: 1680, height: 960 },
  //   large: { width: 2016, height: 1152 }
  // },
  // '3:2': {
  //   small: { width: 1216, height: 832 },
  //   // small: { width: 1536, height: 1024 }, // width = (3/2) * 1024
  //   medium: { width: 1520, height: 1040 },
  //   large: { width: 1824, height: 1248 }
  // },
  // '4:3': {
  //   small: { width: 1152, height: 896 },
  //   // small: { width: 1365, height: 1024 }, // width = (4/3) * 1024
  //   medium: { width: 1440, height: 1120 },
  //   large: { width: 1728, height: 1344 }
  // },
  '5:4': {
    small: { width: 1280, height: 1024 },
    // small: { width: 1280, height: 1024 }, // width = (5/4) * 1024
    medium: { width: 1600, height: 1280 },
    large: { width: 1920, height: 1536 }
  }
};

export const SIZES = {
  '1.5': SIZES_SD_1_5,
  'flux.1-dev': SIZES_SD_FLUX_1_DEV
};

export const getPhotoSize = (
  aspectRatio: AspectRatio,
  size: SizeType,
  modelVersion: '1.5' | 'flux.1-dev' = '1.5'
): PhotoSize => {
  const sizes = SIZES[modelVersion];
  const dimensions = sizes[aspectRatio as keyof typeof sizes][size];
  return { ...dimensions, aspectRatio, size };
};

export const getPhotoShape = (size: string): string => {
  const [width, height] = size.split(':').map(Number);
  if (width! > height!) {
    return 'wide';
  } else if (height! > width!) {
    return 'tall';
  } else {
    return 'square';
  }
};

export const DEFAULT_PHOTO_ASPECT_RATIO = '2:3';
