export const queryStringToObject = (queryString?: string) => {
  if (!queryString) return {};
  const queryObject: { [key: string]: string } = {};
  const queryArray = queryString.split('&');

  queryArray.forEach(query => {
    const [key, value] = query.split('=');
    queryObject[key!] = value || '';
  });

  return queryObject;
};
