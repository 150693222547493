import { useSession } from '@hooks/index';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { useEffect } from 'react';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useTrackEvent } from './trackEvent';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

function storeCampaignParamsInLocalStorage(params: Record<string, string>) {
  try {
    if (typeof window === 'undefined' || Object.keys(params).length === 0) return;

    const stored = window.localStorage.getItem('initial_campaign_params');
    const existingParams = stored ? JSON.parse(stored) : {};

    // Only add params that don't already exist
    const newParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!existingParams[key]) {
        acc[key] = value;
      }
      return acc;
    }, existingParams);

    window.localStorage.setItem('initial_campaign_params', JSON.stringify(newParams));
  } catch (error) {
    console.error('Error storing campaign params in localStorage:', error);
  }
}

export function getStoredCampaignParams(addPrefix: boolean = false): Record<string, string> {
  try {
    if (typeof window === 'undefined') return {};

    const stored = window.localStorage.getItem('initial_campaign_params');
    const params = stored ? JSON.parse(stored) : {};

    if (!addPrefix) return params;

    // Add 'initial_' prefix to keys
    return Object.entries(params).reduce((acc, [key, value]) => {
      const prefixedKey = key.startsWith('initial_') ? key : `initial_${key}`;
      acc[prefixedKey as string] = value as string;
      return acc;
    }, {} as Record<string, string>);
  } catch (error) {
    console.error('Error reading campaign params from localStorage:', error);
    return {};
  }
}

function getCampaignParams(
  query?: Record<string, string | string[] | undefined>,
  addPrefix: boolean = true
): Record<string, string> {
  if (!query || typeof query !== 'object') return {};

  const campaignParams: Record<string, string> = {};
  const commonParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'gclid',
    'gclsrc',
    'dclid',
    'fbclid',
    'msclkid',
    'mc_eid',
    'mc_cid',
    'twclid',
    'li_fat_id',
    'igshid',
    'utm_id',
    'ttc',
    'ttclid',
    'ttc_id'
  ];

  try {
    // Process common campaign parameters
    commonParams.forEach(param => {
      const value = query[param];
      if (value) {
        const paramKey = addPrefix ? `initial_${param}` : param;
        campaignParams[paramKey] = Array.isArray(value) ? value[0]! : value;
      }
    });

    return campaignParams;
  } catch (error) {
    console.error('Error processing campaign parameters:', error);
    return {};
  }
}

function AuthSuccessListener({ children }: { children: React.ReactNode }) {
  const { user } = useSession();
  const { query } = useRouter();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    try {
      if (query?.authSuccess && user?.id) {
        trackEvent('login', {
          value: user.id
        });
      }
    } catch (error) {
      console.error('Error in AuthSuccessListener:', error);
    }
  }, [query.authSuccess]);

  return children;
}

export const AnalyticsStatsigProvider = ({ ...props }) => {
  const { user, isLoggedIn, isLoading } = useSession();
  const router = useRouter();
  const clientKey = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY;
  const [cookies, setCookie] = useCookies(['stableId']);

  const { client } = useClientAsyncInit(clientKey || '', {
    userID: user?.id || '',
    email: user?.email || '',
    custom: {
      isAdmin: !!user?.isAdmin,
      ...getStoredCampaignParams(true)
    }
  });

  // Initialize Statsig features once we know the session state
  useEffect(() => {
    if (!isLoading && client) {
      console.log('Initializing Statsig auto-capture. Session loaded, user:', user?.id || 'anonymous');
      runStatsigAutoCapture(client);
      runStatsigSessionReplay(client);
    }
  }, [isLoading, client]);

  // Store campaign parameters in localStorage
  useEffect(() => {
    if (!router.isReady) return;

    try {
      const params = getCampaignParams(router.query, false);
      if (Object.keys(params).length > 0) {
        storeCampaignParamsInLocalStorage(params);
      }
    } catch (error) {
      console.error('Error storing campaign params:', error);
    }
  }, [router.isReady, router.query]);

  // Handle UTM parameters for Statsig user context
  useEffect(() => {
    try {
      if (!router.isReady || !client) return;

      const context = client.getContext();
      const contextUser = context?.user;

      if (!contextUser?.custom || !Object.keys(contextUser.custom).some(key => key.startsWith('initial_'))) {
        const campaignParams = getCampaignParams(router.query);

        if (Object.keys(campaignParams).length > 0) {
          console.log('Updating user with campaign params:', campaignParams);

          client.updateUserSync({
            userID: user?.id || '',
            email: user?.email || '',
            custom: {
              isAdmin: !!user?.isAdmin,
              ...contextUser?.custom,
              ...campaignParams
            }
          });
        }
      }
    } catch (error) {
      console.error('Error processing campaign params after router ready:', error);
    }
  }, [router.isReady, client, user]);

  // Handle user updates
  useEffect(() => {
    try {
      if (isLoggedIn && user?.id && client) {
        const context = client.getContext();
        const contextUser = context?.user;

        if (!contextUser?.email) {
          let existingCampaignParams = {};

          if (contextUser?.custom && typeof contextUser.custom === 'object') {
            existingCampaignParams = Object.entries(contextUser.custom)
              .filter(([key]) => key && typeof key === 'string' && key.startsWith('initial_'))
              .reduce<Record<string, string>>((acc, [key, value]) => {
                if (typeof value === 'string') {
                  acc[key] = value;
                }
                return acc;
              }, {});
          }

          console.log('existingCampaignParams', existingCampaignParams, Object.keys(existingCampaignParams).length > 0);

          client.updateUserSync({
            userID: user.id,
            email: user.email || '',
            custom: {
              isAdmin: !!user.isAdmin,
              ...existingCampaignParams,
              ...(Object.keys(existingCampaignParams).length === 0 && router.isReady
                ? getCampaignParams(router.query)
                : {})
            }
          });
        }
      }
    } catch (error) {
      console.error('Error updating user context:', error);
    }
  }, [isLoggedIn, user, client]);

  useEffect(() => {
    try {
      if (!client) return;

      const context = client.getContext();
      const stableId = context?.stableID;

      if (stableId && !cookies.stableId) {
        const domain =
          window.location.hostname === 'localhost'
            ? 'localhost'
            : `.${window.location.hostname.split('.').slice(-2).join('.')}`;

        setCookie('stableId', stableId, {
          path: '/',
          expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
          domain: domain,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'lax'
        });
      }
    } catch (error) {
      console.error('Error handling stableId:', error);
    }
  }, [client, cookies.stableId, setCookie]);

  return (
    <StatsigProvider
      client={client}
      options={{
        networkConfig: {
          preventAllNetworkTraffic: typeof window === 'undefined'
        },
        disableStorage: typeof window === 'undefined',
        environment: {
          tier: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'production' : 'development'
        }
      }}
    >
      <AuthSuccessListener>{props.children}</AuthSuccessListener>
    </StatsigProvider>
  );
};
