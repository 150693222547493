import { css, CSSObject } from 'styled-components';

export const breakpoints = [640, 768, 1024, 1280].map(n => `${n}px`);

export const iconSizes: any = [16, 24, 32, 48, 64];
iconSizes.sm = 16;
iconSizes.md = 24;
iconSizes.lg = 32;
iconSizes.xl = 48;
iconSizes.xxl = 64;

export const sizes: any = {
  xl: breakpoints[3],
  lg: breakpoints[2],
  md: breakpoints[1],
  sm: breakpoints[0]
};

export const media = Object.keys(sizes).reduce((acc: { [key: string]: Function }, curr: string) => {
  acc[curr] = (literals: CSSObject | TemplateStringsArray, ...args: any) => css`
    @media (min-width: ${sizes[curr]}) {
      ${css(literals, ...args)};
    }
  `;

  return acc;
}, {});

export const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 40, 64, 128, 160];

// font
export const fontDisplay = 'Inter';
export const fontText = 'Inter';
export const fontSizes = [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30];

export const fontWeights = {
  light: 400,
  roman: 500,
  medium: 600,
  bold: 700
};

export const lineHeights = ['16px', '20px', '24px', '28px', '32px', '36px', '40px', '52px'];
export const fonts = { text: fontText, display: fontDisplay };

// background color
export const backgroundColor = {
  primary: '#242424',
  secondary: '#2C2C2C',
  tertiary: '#414141',
  accent: '#0C8CE9',
  contrast: '#FFFFFF'
};

// stroke color
export const strokeColor = {
  primary: '#FFFFFF',
  secondary: '#444444',
  success: '#1DB753',
  accent: '#0C8CE9',
  error: '#FCA397'
};

// text color
export const textAndIconColor = {
  primary: '#FFFFFF',
  secondary: '#8F8F8F',
  accent: '#0C8CE9',
  success: '#1DB753',
  error: '#FCA397',
  disabled: '#787878',
  contrast: '#2C2C2C'
};

export const colors = {
  background: backgroundColor,
  text: textAndIconColor,
  icon: textAndIconColor,
  stroke: strokeColor
};

export const shadows = [
  '0 1px 4px 0 rgba(21,37,66,0.18)',
  '0 4px 8px 0 rgba(21,37,66,0.15)',
  '0 6px 16px 0 rgba(21,37,66,0.15)',
  '0 16px 32px 0 rgba(21,37,66,0.15)'
];

export const radii = [0, 1, 2, 3, 4, 5];

const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  iconSizes,
  media,
  radii,
  shadows,
  space
};

export default theme;
