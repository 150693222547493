export const convertObjectKeys = (object: Record<string, any> = {}, converterFn: (input: string) => string) => {
  if (object == null) return object;

  const newObject: Record<string, any> = {};

  Object.keys(object).forEach(key => {
    newObject[converterFn(key)] = object[key];
  });

  return newObject;
};
