import { DEFAULT_NEGATIVE_STYLES } from '@/_data/constants';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { capitaliseFirstLetter } from '@lib/capitaliseFirstLetter';
import { Modifier } from '@lib/getModifiers';
import { createStyle, fetchAllStyles } from './actions';

import { Category, StylesState } from './types';

export const extraReducers = (builder: ActionReducerMapBuilder<StylesState>) => {
  builder.addCase(fetchAllStyles.pending, state => {
    state.isLoading = true;
  });
  builder.addCase(fetchAllStyles.fulfilled, (state, action) => {
    if (action.payload !== null) {
      state.styles = action.payload;
      // add categories
      const categories: Category[] = [];
      action.payload.forEach(style => {
        const styleCategory = style.categories[0]?.category;
        if (!styleCategory) return;
        const category = categories.find(category => category.id === styleCategory.id);
        if (category) {
          category.styles.push(style);
        } else {
          categories.push({
            id: styleCategory.id,
            name: styleCategory.name,
            styles: [style]
          });
        }
      });
      state.categories = categories;
      // set default negative styles
      const defaultNegativeModifiers: Modifier[] = [];
      DEFAULT_NEGATIVE_STYLES.forEach(styleName => {
        if (!action.payload) return;
        const style = action.payload.find(style => style.name.toLowerCase() === styleName);
        if (style) {
          defaultNegativeModifiers.push({
            categories: style.categories || [],
            id: style.id,
            name: capitaliseFirstLetter(style.name),
            strength: 0,
            verified: style.verified,
            type: 'modifier',
            enabled: true
          });
        }
      });
      state.defaultNegativeStyles = defaultNegativeModifiers;
    }
  });
  builder.addCase(fetchAllStyles.rejected, state => {
    state.isLoading = false;
  });
  builder.addCase(createStyle.fulfilled, (state, action) => {
    if (action.payload !== null) {
      state.styles.push(action.payload);
      const category = action.payload.categories?.[0]?.category || state.categories.find(cat => cat.name === 'Unknown');
      if (!category) return;
      const stateCategory = state.categories.find(cat => cat.id === category.id);
      if (stateCategory) {
        stateCategory.styles.push(action.payload);
      }
    }
  });
};
