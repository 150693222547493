import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './extraReducers';
import { PromptResult } from './types';

export interface PromptState {
  // Key is query + provider
  promptResults: { [key: string]: PromptResult[] };
  noMoreResults: { [key: string]: boolean };
  nextPage: { [key: string]: string | undefined };
  isLoading: { [key: string]: boolean };
  promptMap: Record<string, PromptResult>;
  selectedPrompts: string[];
  isSelectionActive: boolean;
}

const initialState: PromptState = {
  promptResults: {},
  noMoreResults: {},
  nextPage: {},
  isLoading: {},
  promptMap: {},
  selectedPrompts: [],
  isSelectionActive: false
};

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setIsSelectionActive: (state, action) => {
      state.isSelectionActive = action.payload;
    },
    clearPromptResultsForKey: (state, action) => {
      const key = action.payload;
      delete state.promptResults[key];
      delete state.noMoreResults[key];
      delete state.nextPage[key];
      delete state.isLoading[key];
    },
    setSelectedPrompts: (state, action) => {
      state.selectedPrompts = action.payload;
    },
    togglePrompt: (state, action) => {
      const promptId = action.payload;
      if (state.selectedPrompts.includes(promptId)) {
        state.selectedPrompts = state.selectedPrompts.filter(id => id !== promptId);
      } else {
        state.selectedPrompts.push(promptId);
      }
    },
    resetStore: () => initialState
  },
  extraReducers
});

export const { setIsSelectionActive, clearPromptResultsForKey, resetStore, setSelectedPrompts, togglePrompt } =
  promptSlice.actions;

export default promptSlice.reducer;
