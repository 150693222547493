import styled from 'styled-components';

import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system';

const ButtonWrapper = styled.button<
  BackgroundProps &
    BorderProps &
    ColorProps &
    FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps &
    TypographyProps & {
      ref?: any;
    }
>`
  ${background};
  ${border};
  ${color};
  ${flexbox}
  ${layout};
  ${space};
  ${typography};
  ${position};

  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.8);
    outline-offset: 2px;
  }
`;

ButtonWrapper.defaultProps = {
  bg: 'inherit',
  border: 0,
  px: 0,
  // @ts-ignore
  type: 'button'
};

export default ButtonWrapper;
