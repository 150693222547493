
import { ModelImage, Model } from '@acme/db';
import { constructWorkflowV2 } from './constructWorkflowV2';
import { WorkflowsName } from './comfy/workflows';
import { CONTAINERS_MODAL_API_WORKFLOW } from './processAlbumsAndCallModal';

const WEBHOOKS_BASE_URL = process.env.WEBHOOKS_BASE_URL;

export const retryFailedImage = (modelImage: ModelImage, model: Model, containerKey: string) => {
  const meta = modelImage.meta as any;
  const input = meta.inputs?.inputs;

  if (!input) {
    console.error('Invalid input data for model image');
    return;
  }
  if (!modelImage.workflowName) return;
  const requestBody = {
    model_id: model.id,
    model_name: model.storageName,
    webhook_url: `${WEBHOOKS_BASE_URL}/api/model/${model.id}/album/${modelImage.albumId}/modal/webhook`,
    workflow_name: modelImage.workflowName,
    identifier: modelImage.id,
    input: {
      input_image_url: input.pose_image,
      reference_image: input.image,
      prompt: input.prompt,
      negative_prompt: input.negative_prompt,
      width: input.width,
      height: input.height,
      guidance_scale: input.guidance_scale,
      scheduler: 'karras',
      denoise: input.strength || 1,
      seed: input.seed,
      sampler: 'dpmpp_sde',
      steps: input.num_inference_steps
    },
    index: 0, // Since we're retrying a single image, index is not relevant
    workflow: constructWorkflowV2(modelImage.workflowName as WorkflowsName, {
      ...input,
      ckpt_name: model.storageName?.endsWith('.safetensors') ? model.storageName : `${model.storageName}.safetensors`,
      picstudio_model_id: model.id
    })
  };

  fetch(
    CONTAINERS_MODAL_API_WORKFLOW?.[containerKey as keyof typeof CONTAINERS_MODAL_API_WORKFLOW] ||
      CONTAINERS_MODAL_API_WORKFLOW.CONTAINER_A,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }
  );
};
