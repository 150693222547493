import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system
} from 'styled-system';

const Box = styled.div<
  ShadowProps &
    BackgroundProps &
    ColorProps &
    LayoutProps &
    PositionProps &
    SpaceProps &
    BorderProps & { ref?: any } & { gap?: string | number | string[] | number[] }
>`
  ${background};
  ${border};
  ${color};
  ${layout};
  ${position};
  ${shadow};
  ${space};

  ${system({
    gap: {
      property: 'gap'
    }
  })}
`;

export default Box;
