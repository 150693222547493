import * as React from 'react';
import { Transition } from '@headlessui/react';
import toast, { ToastIcon, ToastType } from 'react-hot-toast';
import TButton from '@components/Button/TButton';
import Icon from '@/_design_system/Icon/Icon';
import clsx from 'clsx';

type Action = {
  text: string;
  onClick: () => void;
};

interface ToastProps {
  visible: boolean;
  id: string;
  action?: Action;
  type: ToastType;
  text: string;
  message: any;
}

export const Toast = ({ visible, id, action, text, type, message }: ToastProps) => {
  React.useEffect(() => {
    setTimeout(() => toast.dismiss(id), 5000);
  }, []);
  return (
    <Transition
      onClick={() => {
        toast.dismiss(id);
      }}
      appear
      show={visible}
      className={clsx(
        'transform flex bg-background text-primary text-base font-semibold px-10 py-4 rounded-lg border border-white/20 drop-shadow-[0_16px_16px_rgba(0,0,0,.8)] items-center gap-x-2',
        action && 'px-4'
      )}
      enter="transition-all duration-150"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transition-all duration-150"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-75"
    >
      <ToastIcon toast={{ ...message, type }} />
      <p>{text}</p>
      {/* Action */}
      {action && (
        <>
          <TButton
            className="ml-8"
            size="small"
            variant="tertiary"
            onClick={() => {
              action.onClick();
              toast.dismiss(id);
            }}
          >
            {action.text}
          </TButton>
          <button
            className="flex-shrink-0 ml-auto text-primary opacity-50 hover:opacity-100 ml-2"
            onClick={() => toast.dismiss(id)}
          >
            <Icon name="close" size={16} />
          </button>
        </>
      )}
      {/* Close icon */}
    </Transition>
  );
};
