import styled from 'styled-components';

type DropZoneState = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
};

const getColor = ({ isDragAccept, isDragReject, isFocused }: DropZoneState) => {
  if (isDragAccept) {
    return '#00e676';
  }
  if (isDragReject) {
    return '#ff1744';
  }
  if (isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const DropZoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: DropZoneState) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default DropZoneContainer;
