import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedOrder, PromptAsset, PromptResult, SearchProviders } from './types';

function getBaseUrl() {
  return typeof window === 'undefined' ? `${process.env.HOST}` : ``;
}

export interface SearchProps {
  provider: SearchProviders;
  page?: number;
  query?: string;
  nextPage?: string;
  themeId?: string;
  order?: FeedOrder;
}

const getQueryString = (
  query?: string,
  page?: number,
  provider?: SearchProviders,
  filter?: string,
  themeId?: string,
  order?: FeedOrder
) => {
  const queryObj: { [key: string]: any } = {
    query,
    page,
    provider,
    filter,
    themeId,
    order
  };

  const queryStr = Object.keys(queryObj)
    .filter(key => queryObj[key])
    .map(key => `${key}=${queryObj[key]}`)
    .join('&');

  return '?' + queryStr;
};

interface SearchResult {
  prompts: PromptResult[];
  nextPage: string;
}

export const searchPrompts = createAsyncThunk(
  'prompt/search',
  async ({
    page,
    provider,
    query = '',
    nextPage,
    themeId,
    order
  }: SearchProps): Promise<{ [key: string]: SearchResult } | null> => {
    try {
      const headers: HeadersInit = { 'Content-Type': 'application/json' };
      const queryString = nextPage || getQueryString(query, page, provider, undefined, themeId, order);
      const response = await fetch(`${getBaseUrl()}/api/search${queryString}`, {
        credentials: 'include',
        method: 'GET',
        headers: headers
      });

      if (themeId) {
        return {
          [themeId]: await response.json()
        };
      }
      return {
        [query + provider + order]: await response.json()
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export interface SearchLikesProps {
  provider: SearchProviders;
  page?: number;
  userId: string;
  nextPage?: string;
}

export const searchLikedPrompts = createAsyncThunk(
  'prompt/searchLikedPrompts',
  async ({ page, provider, userId, nextPage }: SearchLikesProps): Promise<{ [key: string]: SearchResult } | null> => {
    try {
      const headers: HeadersInit = { 'Content-Type': 'application/json' };
      const queryString = nextPage || getQueryString(undefined, page, provider);
      const response = await fetch(`${getBaseUrl()}/api/user/${userId}/likes${queryString}`, {
        credentials: 'include',
        method: 'GET',
        headers: headers
      });

      return {
        [userId + 'likes' + provider]: await response.json()
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export interface SearchCreatedPromptsProps {
  page?: number;
  userId: string;
  nextPage?: string;
  filter?: string;
}

export const searchCreatedPrompts = createAsyncThunk(
  'prompt/searchCreatedPrompts',
  async ({
    page,
    userId,
    filter,
    nextPage
  }: SearchCreatedPromptsProps): Promise<{ [key: string]: SearchResult } | null> => {
    try {
      const headers: HeadersInit = { 'Content-Type': 'application/json' };
      const queryString = nextPage || getQueryString(undefined, page, undefined, filter);
      const response = await fetch(`${getBaseUrl()}/api/user/${userId}/prompts${queryString}`, {
        credentials: 'include',
        method: 'GET',
        headers: headers
      });

      return {
        [userId + 'creations' + filter]: await response.json()
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export const fetchPrompt = createAsyncThunk('prompt/fetch', async (id: string): Promise<PromptResult | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    const fetchResult = await fetch(`${getBaseUrl()}/api/prompt/${id}/thisisit`, {
      method: 'GET',
      headers
    });

    const promptResult = await fetchResult.json();

    return promptResult.prompts[0] as PromptResult;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export const likePrompt = createAsyncThunk('prompt/like', async (promptId: string): Promise<boolean | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    await fetch(`${getBaseUrl()}/api/like`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ promptId })
    });

    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export const unLikePrompt = createAsyncThunk('prompt/unLike', async (promptId: string): Promise<boolean | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    await fetch(`${getBaseUrl()}/api/unlike`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ promptId })
    });

    return false;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export const deletePrompt = createAsyncThunk('prompt/delete', async (id: string): Promise<PromptResult | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    const fetchResult = await fetch(`${getBaseUrl()}/api/prompt/${id}`, {
      method: 'DELETE',
      headers
    });

    const deleteResult = await fetchResult.json();

    return deleteResult[0] as PromptResult;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export interface EditPromptProps {
  id: string;
  isArchived?: boolean;
  isPrivate?: boolean;
}

export const editPrompt = createAsyncThunk(
  'prompt/edit',
  async ({ id, isArchived, isPrivate }: EditPromptProps): Promise<PromptResult | null> => {
    try {
      const headers: HeadersInit = { 'Content-Type': 'application/json' };

      const fetchResult = await fetch(`${getBaseUrl()}/api/prompt/${id}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({ isArchived, isPrivate })
      });

      const deleteResult = await fetchResult.json();

      return deleteResult[0] as PromptResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export interface DeleteAssetProps {
  id: string;
  isArchived: boolean;
}

export const deleteAsset = createAsyncThunk('asset/delete', async (id: string): Promise<PromptAsset | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    const fetchResult = await fetch(`${getBaseUrl()}/api/asset/${id}`, {
      method: 'DELETE',
      headers
    });

    const deleteResult = await fetchResult.json();

    return deleteResult as PromptAsset;
  } catch (error) {
    console.error(error);
    return null;
  }
});

export const unDeleteAsset = createAsyncThunk('asset/undelete', async (id: string): Promise<PromptAsset | null> => {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    const fetchResult = await fetch(`${getBaseUrl()}/api/asset/${id}/undelete`, {
      method: 'POST',
      headers
    });

    const deleteResult = await fetchResult.json();

    return deleteResult as PromptAsset;
  } catch (error) {
    console.error(error);
    return null;
  }
});
