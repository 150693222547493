import { PlanType } from '@pages/api/stripe/getCheckoutSession';

interface PlanSettings {
  name: string;
  id: PlanType;
  dallE: {
    enabled: boolean;
    creditsPerMonth: number;
  };
  stableDiffusion: {
    creditsPerDay: number;
  };
  features: {
    privateMode: boolean;
    x4: boolean;
    upscaleAndVariation: boolean;
  };
}

export const PLANS: Record<PlanType, PlanSettings> = {
  essential: {
    name: 'Essential',
    id: 'essential',
    dallE: {
      enabled: false,
      creditsPerMonth: 0
    },
    stableDiffusion: {
      creditsPerDay: 500
    },
    features: {
      privateMode: true,
      x4: true,
      upscaleAndVariation: true
    }
  },
  pro: {
    name: 'Pro',
    id: 'pro',
    dallE: {
      enabled: true,
      creditsPerMonth: 800
    },
    stableDiffusion: {
      creditsPerDay: 2000
    },
    features: {
      privateMode: true,
      x4: true,
      upscaleAndVariation: true
    }
  },
  free: {
    name: 'Free',
    id: 'free',
    dallE: {
      enabled: false,
      creditsPerMonth: 0
    },
    stableDiffusion: {
      creditsPerDay: 8
    },
    features: {
      privateMode: false,
      x4: false,
      upscaleAndVariation: false
    }
  },
  essential_2: {
    name: 'Essential',
    id: 'essential_2',
    dallE: {
      enabled: false,
      creditsPerMonth: 0
    },
    stableDiffusion: {
      creditsPerDay: 50
    },
    features: {
      privateMode: true,
      x4: true,
      upscaleAndVariation: true
    }
  },
  pro_2: {
    name: 'Pro',
    id: 'pro_2',
    dallE: {
      enabled: true,
      creditsPerMonth: 800
    },
    stableDiffusion: {
      creditsPerDay: 500
    },
    features: {
      privateMode: true,
      x4: true,
      upscaleAndVariation: true
    }
  },
  pro_plus: {
    name: 'Pro+',
    id: 'pro_plus',
    dallE: {
      enabled: true,
      creditsPerMonth: 800
    },
    stableDiffusion: {
      creditsPerDay: 1000
    },
    features: {
      privateMode: true,
      x4: true,
      upscaleAndVariation: true
    }
  }
};
