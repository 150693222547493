export const base_workflow = {
  '1': {
    inputs: {
      seed: 589358569,
      steps: 40,
      cfg: 6,
      sampler_name: 'dpmpp_sde',
      scheduler: 'karras',
      denoise: 1,
      model: ['_CheckpointLoaderSimplePicstudio', 0],
      positive: ['_ApplyControlNetStacker', 0],
      negative: ['_ApplyControlNetStacker', 1],
      latent_image: ['_LatentUpscale', 0]
    },
    class_type: 'KSamplerPicstudio',
    _meta: {
      title: 'KSamplerPicstudio'
    }
  },
  '2': {
    inputs: {
      text: '(headshot, analog style modelshoot style confident  25 yo (cjw+ woman)+ , __cyberdelia/female/cyber_dailyclothing__, __cyberdelia/locations/cyber_beautiful_backgrounds__, ) masterpiece, cinematic light, cinematic lighting, ultrarealistic, photorealistic, 8k, raw photo, realistic, sharp focus on eyes, symmetrical eyes, intact eyes, hyperrealistic, highest quality, best quality, highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow, dramatic lighting, highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition, award winning photo,',
      clip: ['_CheckpointLoaderSimplePicstudio', 1]
    },
    class_type: 'CLIPTextEncodePositivePicstudio',
    _meta: {
      title: 'CLIPTextEncodePositivePicstudio'
    }
  },
  '3': {
    inputs: {
      text: 'asian, multiple heads, 2 heads, elongated body, double image, 2 faces, multiple people, double head, topless, (nsfw)+, naked, nude, porn, open mouth, teeth, (sunglasses), black and white, monochrome, greyscale, grayscale, bw, CyberRealistic_Negative, CyberRealistic_Negative_v3, badhandv4, negative-hand, baddream, portrait, close up, sunglasses, sunglasses, bindi, pottu, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, illustration, cartoon, text, cropped, out of frame, worst quality, low quality, jpeg artifacts, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, cellulite, pubic hair, armpit hair, body hair, nipples',
      clip: ['_CheckpointLoaderSimplePicstudio', 1]
    },
    class_type: 'CLIPTextEncodeNegativePicstudio',
    _meta: {
      title: 'CLIPTextEncodeNegativePicstudio'
    }
  },
  '4': {
    inputs: {
      url_or_path:
        'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/81166a30-adfa-440a-b7b4-8d7bdaa5dd00/width=512,height=512,fit=cover,background=black'
    },
    class_type: 'LoadPoseImageFromUrlOrPathPicstudio',
    _meta: {
      title: 'LoadPoseImageFromUrlOrPathPicstudio'
    }
  },
  '5': {
    inputs: {
      url_or_path:
        'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/29059fd2-1dd5-4104-6adb-201bc81b9e00/width=512,height=512,fit=cover'
    },
    class_type: 'LoadReferenceFromUrlOrPathPicstudio',
    _meta: {
      title: 'LoadReferenceFromUrlOrPathPicstudio'
    }
  },
  '6': {
    inputs: {
      filename_prefix: 'ComfyUI',
      images: ['_VAEDecode', 0]
    },
    class_type: 'SaveImage',
    _meta: {
      title: 'Save Image'
    }
  },
  _ControlNetLoader: {
    inputs: {
      control_net_name: 'control_v11p_sd15_openpose_fp16.safetensors'
    },
    class_type: 'ControlNetLoader',
    _meta: {
      title: 'Load ControlNet Model'
    }
  },
  _CheckpointLoaderSimplePicstudio: {
    inputs: {
      ckpt_name: 'geo-84ffa6b6-8a27-43bc-876f-501ca0d76e3b.safetensors'
    },
    class_type: 'CheckpointLoaderSimplePicstudio',
    _meta: {
      title: 'Load Checkpoint'
    }
  },
  _ControlNetStacker: {
    inputs: {
      strength: 0.75,
      start_percent: 0,
      end_percent: 0.8,
      control_net: ['_ControlNetLoader', 0],
      image: ['4', 0]
    },
    class_type: 'Control Net Stacker',
    _meta: {
      title: 'Control Net Stacker'
    }
  },
  _VAEEncode: {
    inputs: {
      pixels: ['5', 0],
      vae: ['_CheckpointLoaderSimplePicstudio', 2]
    },
    class_type: 'VAEEncode',
    _meta: {
      title: 'VAE Encode'
    }
  },
  _ApplyControlNetStacker: {
    inputs: {
      positive: ['2', 0],
      negative: ['3', 0],
      cnet_stack: ['_ControlNetStacker', 0]
    },
    class_type: 'Apply ControlNet Stack',
    _meta: {
      title: 'Apply ControlNet Stack'
    }
  },
  _LatentUpscale: {
    inputs: {
      upscale_method: 'nearest-exact',
      width: 512,
      height: 512,
      crop: 'disabled',
      samples: ['_VAEEncode', 0]
    },
    class_type: 'LatentUpscalePicstudio',
    _meta: {
      title: 'Upscale Latent'
    }
  },
  _VAEDecode: {
    inputs: {
      samples: ['1', 0],
      vae: ['_CheckpointLoaderSimplePicstudio', 2]
    },
    class_type: 'VAEDecode',
    _meta: {
      title: 'VAE Decode'
    }
  }
};
