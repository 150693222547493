import { css } from 'styled-components';
import theme from '../../theme';
import getPadding from '../utils/getPadding';
import { Size } from '../types/types';
import { transparentize } from 'polished';

const secondary = (size: Size) => css`
  :hover {
    background-color: #525252;
  }

  :focus,
  :active {
    background-color: ${theme.colors.background.tertiary};
    border: 2px solid ${theme.colors.stroke.primary};

    ${(() => {
      const { px, py } = getPadding(size);
      return css`
        padding: ${theme.space[py] ? `${theme.space[py] - 2}px` : '0'}
          ${theme.space[px] ? `${theme.space[px] - 2}px` : '0'};
      `;
    })()}
  }

  :disabled {
    border: 0;
    background-color: ${transparentize(0.5, theme.colors.background.tertiary)};
    color: ${theme.colors.text.secondary};
  }
`;

export default secondary;
