import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './extraReducers';
import { StylesState } from './types';

export const initialState: StylesState = {
  isLoading: true,
  styles: [],
  categories: [],
  defaultNegativeStyles: []
};

export const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {},
  extraReducers
});

export const {} = stylesSlice.actions;

export default stylesSlice.reducer;
