import { css } from 'styled-components';
import theme from '../../theme';
import getPadding from '../utils/getPadding';
import { Size } from '../types/types';

const tertiary = (size: Size) => css`
  border: 1px solid ${theme.colors.stroke.secondary};

  ${(() => {
    const { px, py } = getPadding(size);
    return css`
      padding: ${theme.space[py] ? `${theme.space[py] - 1}px` : '0'}
        ${theme.space[px] ? `${theme.space[px] - 1}px` : '0'};
    `;
  })()}

  :hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  :focus,
  :active {
    background-color: rgba(255, 255, 255, 0.05);
    border: 2px solid ${theme.colors.stroke.primary};

    ${(() => {
      const { px, py } = getPadding(size);
      return css`
        padding: ${theme.space[py] ? `${theme.space[py] - 2}px` : '0'}
          ${theme.space[px] ? `${theme.space[px] - 2}px` : '0'};
      `;
    })()}
  }

  :disabled {
    border: thin solid ${theme.colors.stroke.secondary};
    opacity: 0.5;
  }
`;

export default tertiary;
