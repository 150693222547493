import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './extraReducers';
import { UserState } from './types';

export const initialState: UserState = {
  isLoaded: false,
  isLoading: true,
  creditsUsed: {
    stableDiffusion: 0,
    dallE: 0
  },
  creditsAvailable: {
    stableDiffusion: 500,
    dallE: 0
  },
  subscriptionType: 'free'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCreditsUsed: (state, action) => {
      state.creditsUsed = action.payload;
    }
  },
  extraReducers
});

export const { setCreditsUsed } = userSlice.actions;

export default userSlice.reducer;
