import { ThemeResult } from '@components/Create/themes';
import { Service } from '@store/create/types';

const getBaseAPIHost = () => {
  return typeof window === 'undefined' ? `${process.env.HOST}/api` : `/api`;
};

export default async function themes(themeId: string) {
  let url = `${getBaseAPIHost()}/theme/${themeId}`;
  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function themesByIds(themeIds: string[]) {
  const queryString = themeIds.join('&id=');
  const url = `${getBaseAPIHost()}/theme/getByIds?id=${queryString}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function themesByContentfulId(contentfulId: string) {
  const url = `${getBaseAPIHost()}/contentful/themes/${contentfulId}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function themesByContentfulIds(contentfulIds: string[]) {
  const url = `${getBaseAPIHost()}/contentful/themesByIds`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ contentfulIds })
  });

  return response.json();
}

export async function themesByCategory(category: string) {
  const url = `${getBaseAPIHost()}/theme/getByCategory?category=${category}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function themesByUserId(
  userId: string,
  filter: 'all' | 'private' | 'public',
  query?: string,
  pageParam?: number
) {
  let queryOptions = `?filter=${filter}`;
  if (query) {
    queryOptions += `&query=${query}`;
  }
  if (pageParam) {
    queryOptions += `&page=${pageParam}`;
  }

  const url = `${getBaseAPIHost()}/user/${userId}/themes${queryOptions}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function publicThemes(
  query?: string,
  pageParam?: number,
  onlyOwn?: boolean,
  service?: Service,
  category?: string,
  liked?: boolean
) {
  let queryOptions = `?page=${pageParam || 1}`;
  if (query) {
    queryOptions += `&query=${query}`;
  }
  if (onlyOwn) {
    queryOptions += `&onlyOwn=true`;
  }
  if (service) {
    queryOptions += `&service=${service}`;
  }
  if (category) {
    queryOptions += `&category=${category}`;
  }
  if (liked) {
    queryOptions += `&liked=true`;
  }

  const url = `${getBaseAPIHost()}/themes${queryOptions}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  return response.json();
}

export async function createTheme(theme: Partial<ThemeResult> & { referenceImage?: string }) {
  const url = `${getBaseAPIHost()}/theme`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: headers,
    body: JSON.stringify(theme)
  });

  return response.json();
}

export async function updateTheme(theme: Partial<ThemeResult>) {
  const url = `${getBaseAPIHost()}/theme/${theme.id}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(theme)
  });

  return response.json();
}

export async function reassignTheme(theme: Partial<ThemeResult> & { username: string }) {
  const url = `${getBaseAPIHost()}/theme/${theme.id}/reassign`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ username: theme.username })
  });

  return response.json();
}

export async function deleteTheme(themeId: string) {
  const url = `${getBaseAPIHost()}/theme/${themeId}`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'DELETE',
    headers: headers
  });

  return response.json();
}
