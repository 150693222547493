import styled from 'styled-components';
import {
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  background,
  BackgroundProps,
  shadow,
  ShadowProps
} from 'styled-system';

export const Position = styled.div<
  BorderProps &
    FlexboxProps &
    ColorProps &
    LayoutProps &
    SpaceProps &
    BackgroundProps &
    PositionProps &
    ShadowProps & { ref?: any }
>`
  ${border};
  ${color};
  ${flexbox};
  ${layout};
  ${space};
  ${background};
  ${position};
  ${shadow};
`;

// Absolute
export const Absolute = styled(Position)``;

Absolute.defaultProps = {
  position: 'absolute'
};

// Fixed
export const Fixed = styled(Position)``;

Fixed.defaultProps = {
  position: 'fixed'
};

// Relative
export const Relative = styled(Position)``;

Relative.defaultProps = {
  position: 'relative'
};

// Sticky
export const Sticky = styled(Position)``;

Sticky.defaultProps = {
  position: 'sticky',
  top: 0,
  zIndex: 1
};
