export const FLUX_LORA_FACE_DETAILER = {
  "5": {
    "inputs": {
      "width": [
        "135",
        0
      ],
      "height": [
        "136",
        0
      ],
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "6": {
    "inputs": {
      "text": [
        "139",
        0
      ],
      "clip": [
        "11",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "10": {
    "inputs": {
      "vae_name": "ae.safetensors"
    },
    "class_type": "VAELoader",
    "_meta": {
      "title": "Load VAE"
    }
  },
  "11": {
    "inputs": {
      "clip_name1": "t5xxl_fp16.safetensors",
      "clip_name2": "clip_l.safetensors",
      "type": "flux"
    },
    "class_type": "DualCLIPLoader",
    "_meta": {
      "title": "DualCLIPLoader"
    }
  },
  "12": {
    "inputs": {
      "unet_name": "flux1-dev.safetensors",
      "weight_dtype": "fp8_e4m3fn"
    },
    "class_type": "UNETLoader",
    "_meta": {
      "title": "Load Diffusion Model"
    }
  },
  "61": {
    "inputs": {
      "max_shift": 1.1500000000000001,
      "base_shift": 0.5,
      "width": [
        "135",
        0
      ],
      "height": [
        "136",
        0
      ],
      "model": [
        "74",
        0
      ]
    },
    "class_type": "ModelSamplingFlux",
    "_meta": {
      "title": "ModelSamplingFlux"
    }
  },
  "74": {
    "inputs": {
      "lora_id": "clzvg2wpy0001pihglpx0ja82",
      "lora_name": "klee-1984fe1a-ff03-484a-9553-7c0fa42ed187.safetensors",
      "strength_model": 1,
      "model": [
        "12",
        0
      ]
    },
    "class_type": "LoraLoaderModelOnlyFromVolumePicstudio",
    "_meta": {
      "title": "LoraLoaderModelOnlyFromVolumePicstudio"
    }
  },
  "102": {
    "inputs": {
      "text": "",
      "clip": [
        "11",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "105": {
    "inputs": {
      "guide_size": 512,
      "guide_size_for": true,
      "max_size": 1024,
      "seed": 452779869534923,
      "steps": 20,
      "cfg": 1,
      "sampler_name": "euler",
      "scheduler": "normal",
      "denoise": 0.5,
      "feather": 5,
      "noise_mask": true,
      "force_inpaint": true,
      "bbox_threshold": 0.5,
      "bbox_dilation": 10,
      "bbox_crop_factor": 3,
      "sam_detection_hint": "center-1",
      "sam_dilation": 0,
      "sam_threshold": 0.93,
      "sam_bbox_expansion": 0,
      "sam_mask_hint_threshold": 0.7000000000000001,
      "sam_mask_hint_use_negative": "False",
      "drop_size": 10,
      "wildcard": "",
      "cycle": 1,
      "inpaint_model": false,
      "noise_mask_feather": 20,
      "image": [
        "119",
        0
      ],
      "model": [
        "108",
        1
      ],
      "clip": [
        "108",
        2
      ],
      "vae": [
        "108",
        3
      ],
      "positive": [
        "108",
        4
      ],
      "negative": [
        "108",
        5
      ],
      "bbox_detector": [
        "112",
        0
      ]
    },
    "class_type": "FaceDetailer",
    "_meta": {
      "title": "FaceDetailer"
    }
  },
  "108": {
    "inputs": {
      "model": [
        "61",
        0
      ],
      "clip": [
        "11",
        0
      ],
      "vae": [
        "10",
        0
      ],
      "positive": [
        "6",
        0
      ],
      "negative": [
        "118",
        0
      ]
    },
    "class_type": "Bus Node",
    "_meta": {
      "title": "Bus Node"
    }
  },
  "109": {
    "inputs": {
      "filename_prefix": "178460774_562602177",
      "images": [
        "105",
        0
      ]
    },
    "class_type": "SaveImage",
    "_meta": {
      "title": "Save Image"
    }
  },
  "112": {
    "inputs": {
      "model_name": "bbox/face_yolov8m.pt"
    },
    "class_type": "UltralyticsDetectorProvider",
    "_meta": {
      "title": "UltralyticsDetectorProvider"
    }
  },
  "113": {
    "inputs": {
      "mask": [
        "105",
        3
      ]
    },
    "class_type": "MaskToImage",
    "_meta": {
      "title": "Convert Mask to Image"
    }
  },
  "117": {
    "inputs": {
      "seed": [
        "137",
        0
      ],
      "steps": 25,
      "cfg": 1,
      "sampler_name": "euler",
      "scheduler": "normal",
      "denoise": 1,
      "model": [
        "108",
        1
      ],
      "positive": [
        "108",
        4
      ],
      "negative": [
        "108",
        5
      ],
      "latent_image": [
        "5",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "118": {
    "inputs": {
      "conditioning": [
        "102",
        0
      ]
    },
    "class_type": "ConditioningZeroOut",
    "_meta": {
      "title": "ConditioningZeroOut"
    }
  },
  "119": {
    "inputs": {
      "samples": [
        "117",
        0
      ],
      "vae": [
        "108",
        3
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "131": {
    "inputs": {
      "filename_prefix": "178460774_150504773",
      "images": [
        "119",
        0
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "132": {
    "inputs": {
      "filename_prefix": "178460774_489678666",
      "images": [
        "105",
        2
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "133": {
    "inputs": {
      "filename_prefix": "178460774_388136691",
      "images": [
        "105",
        1
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "134": {
    "inputs": {
      "filename_prefix": "178460774_663027532",
      "images": [
        "113",
        0
      ]
    },
    "class_type": "SaveImageAssetPicstudio",
    "_meta": {
      "title": "SaveImageAssetPicstudio"
    }
  },
  "135": {
    "inputs": {
      "input_id": "width",
      "default_value": 832
    },
    "class_type": "ExternalNumberIntPicstudio",
    "_meta": {
      "title": "ExternalNumberIntPicstudio"
    }
  },
  "136": {
    "inputs": {
      "input_id": "height",
      "default_value": 1216
    },
    "class_type": "ExternalNumberIntPicstudio",
    "_meta": {
      "title": "ExternalNumberIntPicstudio"
    }
  },
  "137": {
    "inputs": {
      "input_id": "seed",
      "default_value": 178460774
    },
    "class_type": "ExternalNumberIntPicstudio",
    "_meta": {
      "title": "ExternalNumberIntPicstudio"
    }
  },
  "139": {
    "inputs": {
      "input_id": "prompt",
      "default_value": "headshot of TOK woman wearing glasses  wearing Off-the-Shoulder Jumpsuit set against a dark gray backdrop, soft directional lighting from their left side. professional portrait style., "
    },
    "class_type": "ExternalTextPicstudio",
    "_meta": {
      "title": "ExternalTextPicstudio"
    }
  }
};
