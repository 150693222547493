import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  background,
  BackgroundProps,
  color,
  ColorProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  GridRowGapProps,
  gridRowGap,
  system
} from 'styled-system';

const Flex = styled.div<
  ShadowProps &
    LayoutProps &
    BorderProps &
    FlexboxProps &
    PositionProps &
    BackgroundProps &
    ColorProps &
    GridRowGapProps &
    SpaceProps & { ref?: any; href?: string } & { gap?: string | number | string[] | number[] } & {
      hoverBackgroundColor?: string;
    }
>`
  ${layout};
  ${border};
  ${flexbox};
  ${position};
  ${background};
  ${color};
  ${shadow};
  ${space};
  ${gridRowGap}

  ${({ hoverBackgroundColor }) =>
    hoverBackgroundColor &&
    `transition:background-color 0.3s ease-out;
    &:hover{
        background-color: ${hoverBackgroundColor};
      }
`}

  ${system({
    gap: {
      property: 'gap'
    }
  })}
`;

Flex.defaultProps = {
  display: 'flex'
};

export default Flex;
