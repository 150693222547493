import styled from "styled-components";
import {
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
} from "styled-system";

const Row = styled.div<
  ColorProps & FlexboxProps & LayoutProps & ShadowProps & SpaceProps
>`
  ${color};
  ${flexbox};
  ${layout};
  ${shadow};
  ${space};
`;

Row.defaultProps = {
  display: "flex",
  flexWrap: "wrap",
  ml: -3,
  mr: -3,
  flex: 1,
};

export default Row;
