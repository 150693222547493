import { useState } from 'react';
import copy from 'copy-to-clipboard';

export const useCopy = (timeout?: number) => {
  const [copied, setCopied] = useState(false);

  const copyText = (text: string) => {
    copy(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, timeout || 1000);
  };

  return { copy: copyText, copied };
};
