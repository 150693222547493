import React, { ReactNode } from 'react';

export type Node = {
  type: string;
  category: string;
  properties: any;
  index: number;
  children: any[];
};

const renameKeys = (keysMap: any, obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

const createElement = (elements: Node[]): ReactNode => {
  let applySingleFill: boolean = true;
  let applySingleStroke: boolean = true;
  let lastFill: string;
  let lastStroke: string;

  elements.forEach((element: Node) => {
    const { properties } = element;

    if (properties.fill && properties.fill != 'none') {
      if (lastFill !== properties.fill) {
        if (lastFill) {
          applySingleFill = false;
        }
        lastFill = properties.fill;
      }
    }

    if (properties.stroke && properties.stroke != 'none') {
      if (lastStroke !== properties.stroke) {
        if (lastStroke) {
          applySingleStroke = false;
        }
        lastStroke = properties.stroke;
      }
    }
  });

  const newElement = elements.map((element: Node, index: number) => {
    const { type, properties, children } = element;
    const realKids: ReactNode = children && children.length ? createElement(children) : children;

    properties.key = index;

    if (properties.stroke && properties.stroke !== 'none' && applySingleStroke) {
      if ((lastStroke && lastFill && lastStroke == lastFill) || (lastStroke && !lastFill)) {
        properties.stroke = 'currentColor';
      }
    }

    if (properties.fill && properties.fill !== 'none' && applySingleFill) {
      if ((lastStroke && lastFill && lastStroke == lastFill) || (lastFill && !lastStroke)) {
        properties.fill = 'currentColor';
      }
    }

    if (
      properties['stroke-width'] ||
      properties['fill-rule'] ||
      properties['stroke-linecap'] ||
      properties['stroke-linejoin'] ||
      properties['clip-rule'] ||
      properties['clip-path']
    ) {
      return React.createElement(
        type,
        renameKeys(
          {
            'stroke-width': 'strokeWidth',
            'fill-rule': 'fillRule',
            'stroke-linecap': 'strokeLinecap',
            'stroke-linejoin': 'strokeLinejoin',
            'clip-rule': 'clipRule',
            'clip-path': 'clipPath'
          },
          properties
        ),
        realKids
      );
    }

    return React.createElement(type, properties, realKids);
  });

  return newElement;
};

export default createElement;
